import React, { useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import CryptoJS from "crypto-js"; // For encryption
import './GmailSignUp.css';

const GoogleSignIn = ({ showNotification, setIsLoading }) => {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Use environment variable for security
  const serverURL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    };

    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: CLIENT_ID,
	callback: handleCredentialResponse,login_uri: "https://beta.eeezo.com/",
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signUpButton"),
        { theme: "outline", size: "large" }
      );
    };

    const handleCredentialResponse = async (response) => {
      try {
        // console.log("Encoded JWT ID token:", response.credential);

        // Decode the JWT token to extract user info
        const decodedToken = jwtDecode(response.credential);
        // console.log("Decoded Token:", decodedToken);

        // Extract user details from the token
        const { name, email, picture } = decodedToken;

        // Prepare signup data object
        const signUpData = {
          name: name || "Google User",
          email,
          password: "GoogleAuth123!", 
          phone_no:9999999999, 
          image: picture || "", 
          notification: true, 
        };

        // Encrypt the signup data
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(signUpData),
          process.env.REACT_APP_SECRETKEY
        ).toString();

        const encryptjson = { data: encryptedData };
        console.log("Encrypted data to send:", encryptjson);

        // Send signup request to backend
        setIsLoading(true);
        const signUpResponse = await fetch(`${serverURL}/signUp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(encryptjson),
        });

        const signUpResult = await signUpResponse.json();
        console.log("SignUp Result:", signUpResult);

        if (!signUpResponse.ok) {
          throw new Error(signUpResult.error || "Signup failed");
        }

        // Success notification
        showNotification(
          "Account created. Please check your mail to activate your account.",
          "success"
        );
        window.location="/";
      } catch (error) {
        console.error("Error during Google signup:", error.message);
        showNotification(error.message || "Google signup failed", "error");
      } finally {
        setIsLoading(false); // Always stop loading
      }
    };

    loadGoogleScript();
  }, [CLIENT_ID, serverURL, showNotification, setIsLoading]);

  return (
    <div className="google-button flex" style={{ padding: '3px' }}>
      <div id="signUpButton"></div>
    </div>
  );
};

export default GoogleSignIn;
