import React,{useState,useEffect,useRef, useContext} from "react";
import Button from "../elements/Button/Button";
import Card from "../elements/Card/Card";
import Textinput from "../elements/TextInput/TextInput";
import RadioInput from "../elements/RadioInput/RadioInput";
import Selectbox from "../elements/SelectBox/SelectBox";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import ButtonSolid from "../elements/Button/ButtonSolid";
import CryptoJS from "crypto-js";
const ProjectDashboard = () =>{
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleJobLists = (jobId) =>{
    console.log("sending id : ",jobId);
    navigate('/Projects',{ state: { jobId } })
  }
  const [projects, setProjects] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const sortTypes=[
    {id:'0',label:'Last viewed by me'},
    {id:'desc',label:'Date posted: Newest to oldest'},
    {id:'asc',label:'Date posted: oldest to Newest'}
  ]
  const jobStatus=["Active","Closed","In Review"];
  const jobLocation=["Bangalore","Mumbai","Kerala","Kovilpatti","Chennai"];
  const dropdownRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [ companyId, setCompanyID ] = useState("");
  const [visibleCount, setVisibleCount] = useState(4);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedJobcount, setfetchedJobcount] = useState(0);
  useEffect(()=>{
    const encryptedMetadata = localStorage.getItem('metadata');
    const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
    setCompanyID(decryptedMetadata.cid);
      const loadProfile = async () => {
          try {
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/company/fetchCompanyUsers`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      token: token
                    },
                });
                const responsedata = await response.json();
        
                const decryptedData = JSON.parse(CryptoJS.AES.decrypt(responsedata.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                console.log(decryptedData);
                if (!response.ok) {
                    var errorMessage = "Something Went Wrong";
                    if(responsedata.error)
                        errorMessage = responsedata.error;
                    throw new Error(responsedata.error);
                }
                else{
                    console.log(decryptedData)
                    const data = Array.isArray(decryptedData) ? decryptedData : [];
                    setUserData(data);
                }
            } catch (error) {
                console.log('Error:', error);
                let errorMessage = "Something went wrong.";
                if (error.response && error.response.data && error.response.data.msg) {
                    errorMessage = error.response.data.msg;
                } 
                else if (error.message) {
                    errorMessage = error.message;
                }
            } finally {
              setIsLoading(false);
            }
        }
        if(isLoading){
          loadProfile();
        }

  },[isLoading])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalJobs, setTotalJobs] = useState(0);
  const fetchJobList = async (location = null, createdBy = null, jobStatus = null) => {
    let loc = location || "";
    let created_by = createdBy || "";
    let job_status = jobStatus || "";

    const page_start = (currentPage - 1) * 10; 
    const offset = 10;

    const JLData = {
      company_id: companyId,
      page_start: page_start,
      offset:offset, loc:loc, created_by:created_by, job_status:job_status
    };

    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(JLData),
      process.env.REACT_APP_SECRETKEY
    ).toString();
    const encryptjson = { data: encryptedData };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/job/fetchJobListing`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify(encryptjson),
        }
      );

      const status = await response.json();
      const JobListData = JSON.parse(CryptoJS.AES.decrypt(status.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
      const customizedProjects = JobListData.jobs.map((job) => {
        const matchedUser = users.find((user) => user.email === job.created_by);
        const createdByName = matchedUser ? matchedUser.name : "Unnamed User";
        const createdAtDate = new Date(job.created_at);
        return {
          id: job.job_id,
          title: job.title,
          location: job.location,
          WorkplaceType: job.workplace_type,
          JobType: job.job_type,
          Department: job.department,
          JobDescription: job.job_desc,
          Skills: job.skills.join(", "),
          pipeline_count: job.pipeline_count || 0,
          applicants_new: job.applicants_new || 0,
          applicants_total: job.applicants_total || 0,
          created_by: createdByName,
          created_at: createdAtDate.toLocaleString(),
          status:
            job.status === "requested"
              ? "In Review"
              : job.status.charAt(0).toUpperCase() + job.status.slice(1),
        };
      });

      // Update state
      setProjects(customizedProjects);
      const totalJobs = JobListData.totalCount;
      setfetchedJobcount(customizedProjects.length);
      setTotalJobs(totalJobs);
      setTotalPages(Math.ceil(totalJobs / offset));
    } catch (err) {
      console.error("Error Fetching Job List:", err);
    }
  };
  
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    setTimeout(() => window.scrollTo(0, 0), 0);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const users = Object.values(userData); 
  const [JobFetchded, setJobFetched]=useState(false)
  useEffect(()=>{
    if(companyId && users && !JobFetchded){
      fetchJobList();
      setJobFetched(true);
    }
  },[companyId, users])
  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };
  const handleClickOutside = (event) => {
    if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
    }
};
const [filterName, setFilterName] = useState([]);
const [filterJobStatus, setFilterJobStatus] = useState([]);
const [filterLocation, setFilterLocation] = useState([]);
const handleJobStatusChange = (e) =>{
  console.log(e.target.checked, e.target.name);
  const jobstatus = e.target.name === 'In Review' ? "requested" : e.target.name.charAt(0).toLowerCase() + e.target.name.slice(1)
  if (e.target.checked) {
    setFilterJobStatus((prev) => ({
      ...prev,
      [jobstatus]: true
    }));
  } else {
    setFilterJobStatus((prev) => {
      const prevData = { ...prev };
      delete prevData[jobstatus];
      return prevData;
    });
  }

  setTimeout(() => window.scrollTo(0, 0), 0);
}
const handleJobLocationChange = (e) =>{
  console.log(e.target.checked, e.target.name);
  if (e.target.checked) {
    setFilterLocation((prev) => ({
      ...prev,
      [e.target.name]: true
    }));
  } else {
    setFilterLocation((prev) => {
      const prevData = { ...prev };
      delete prevData[e.target.name];
      return prevData;
    });
  }

  setTimeout(() => window.scrollTo(0, 0), 0);
}
const handleOwnerChange = (e) => {
  console.log(e.target.checked, e.target.name);
  const matchedUser = users.find(user => user.name === e.target.name);
  const createdByName = matchedUser ? matchedUser.email : "Unnamed User";

  if (e.target.checked) {
    setFilterName((prev) => ({
      ...prev,
      [e.target.name]: createdByName
    }));
  } else {
    setFilterName((prev) => {
      const prevData = { ...prev };
      delete prevData[e.target.name];
      return prevData;
    });
  }

  setTimeout(() => window.scrollTo(0, 0), 0);
};
const [sortBySelect, setSortBySelect] = useState('asc'); // Default to ascending

const sortProjects = (order) => {
  const sortedProjects = [...projects].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      console.log('Comparing:', dateA, dateB);
      if (order === 'asc') {
          return dateA - dateB; // Ascending order
      } else if (order === 'desc') {
          return dateB - dateA; // Descending order
      }
      return 0;
  });
  console.log('Sorted Projects:', sortedProjects);
  setProjects(sortedProjects);
};



const handleSortBy = (value) => {
    setSortBySelect(value);
    sortProjects(value);
};
useEffect(() => {
  const createdByNames = Object.values(filterName);
  const jobStatus = Object.keys(filterJobStatus);
  const location = Object.keys(filterLocation);
  fetchJobList(location, createdByNames, jobStatus);
}, [currentPage,filterName,filterJobStatus,filterLocation]);



useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [isOpen]);
const resetFilter=()=>{
  fetchJobList();
  setFilterName([]);
  setFilterJobStatus([]);
  setFilterLocation([]);
}

const jobOptions =(project) => {
  console.log("Project passed to jobOptions:", project);

  return(
  <div className="py-1">
              <Button
                className="w-full text-left px-4 bg-white py-2 border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                
              >
                Archive Project
              </Button>
              <Button
                className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Reassign Owner
              </Button>
              <Button
                className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={()=>handleJobLists(project.id)}>
                View Job Details
              </Button>
              <Button
                className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Edit Job
              </Button>
              <Button
                className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Share Job
              </Button>
              <Button
                className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Duplicate Job
              </Button>
              <Button
                className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Pause Job
              </Button>
              <Button
                className="w-full text-left px-4 py-2 bg-white border-white text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Close Job
              </Button>
            </div>)
}

    return(<>
        <div className="flex">
            <div className="w-[24%] bg-white p-2 ml-6">
                <Button onClick={()=> resetFilter()} className="bg-white flex text-gray-500 pl-2 mt-6 mr-4 ml-auto pr-2 border-white"><svg className="w-5 h-5 mt-1 mr-2" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#00b3ff" stroke="#00b3ff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" fill-rule="evenodd" stroke="#009dff" stroke-linecap="round" stroke-linejoin="round" transform="matrix(0 1 1 0 2.5 2.5)"> <path d="m3.98652376 1.07807068c-2.38377179 1.38514556-3.98652376 3.96636605-3.98652376 6.92192932 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8"></path> <path d="m4 1v4h-4" transform="matrix(1 0 0 -1 0 6)"></path> </g> </g></svg>Reset filters</Button>
                <Card CardFooterClass="px-6 py-2 bg-gray-100 justify-end gap-2 "  CardClass="min-w-md mx-auto  bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <div>
                    <Textinput placeholder="Search for a Project" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
                    <div className="absolute h-10 inset-y-[32%]  flex pt-1  pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#9effef"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div>
                </div>
                    </>}
                />
                {/* Location Filter */}
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                      <p className="mb-0 font-medium">Location</p>
                      <div className="relative">
                        <Textinput placeholder="Search Cities" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
                        <div className="absolute h-10 inset-y-[24%] flex pt-1 fixed  pl-3">
                          <svg className="w-5 h-5 text-blue-500" fill="none" stroke="#9effef" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"/>
                          </svg>
                        </div>
                      </div>
                      {jobLocation.map((loc)=>(
                        <Textinput type="checkbox" name={loc} checked={filterLocation[loc]} onChange={(e)=> handleJobLocationChange(e)} labelclass="hidden" className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1" divclass="flex h-6 mt-2" postlabel={loc} postlabelclass="ml-2"/>  
                      ))}
                    </>}/>
                    {/* Job Containing Filter*/}
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Filter Jobs Containing</p>
                    <Textinput type="checkbox" labelclass="hidden" className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1" divclass="flex h-6 mt-2" postlabel={`All (${50})`} postlabelclass="ml-2"/>
                    <Textinput type="checkbox" labelclass="hidden" className="mt-1 !w-1/12 w-5 h-5" divclass="flex mt-1 h-6" postlabel={`Only Searches (${50})`} postlabelclass="ml-2"/>
                    <Textinput type="checkbox" labelclass="hidden" className="mt-1 !w-1/12 w-5 h-5 !rounded-xl" divclass="flex mt-1 h-6" postlabel={`Jobs (${50})`} postlabelclass="ml-2"/>
                    <Textinput type="checkbox" labelclass="hidden" className="mt-1 !w-1/12 w-5 h-5 !rounded-xl" divclass="flex mt-1" postlabel={`Shortlisted Participants (${12})`} postlabelclass="ml-2"/>
                    
                    </>}
                />
                {/* Job Status Filter */}
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Job Status</p>
                    {jobStatus.map((status)=>(
                      <Textinput type="checkbox" name={status} checked={filterJobStatus[status === 'In Review' ? "requested" : status.charAt(0).toLowerCase() + status.slice(1)]} onChange={(e) => handleJobStatusChange(e)} labelclass="hidden" className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1" divclass="flex h-6 mt-2" postlabel={status} postlabelclass="ml-2"/>
                    ))}</>}
                />
                 <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                      body={
                          <>
                          <p className="mb-0 font-medium">Project Owner</p>
                          {users.slice(0, visibleCount).map((user, index) => (
                              <Textinput key={user.uid || index} name={user.name} checked={filterName[user.name]} onChange={(e) => handleOwnerChange(e)} type="checkbox" postlabel={user.name || "Unnamed User"} labelclass="hidden" className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1" postlabelclass="ml-2" divclass="flex h-6 mt-2"
                              />
                          ))}
                          {visibleCount < users.length && ( // Show "Show More" only if there are more users to show
                              <p
                              className="text-sm mt-2 hover:cursor-pointer text-green-800 hover:cursor-pointer w-[82px]"
                              onClick={handleShowMore}
                              >
                              Show more...
                              </p>
                          )}
                          </>
                      }
                  />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Workspace</p>
                    <Textinput type="checkbox" labelclass="hidden" className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1" divclass="flex h-6 mt-2" postlabel={`Alchemy`} postlabelclass="ml-2"/>
                    <Textinput type="checkbox" labelclass="hidden" className="mt-1 !w-1/12 w-5 h-5" divclass="flex mt-1 h-6" postlabel={`NovaByte Solutions`} postlabelclass="ml-2"/>
                    <Textinput type="checkbox" labelclass="hidden" className="mt-1 !w-1/12 w-5 h-5" divclass="flex mt-1 h-6" postlabel={`ZenithSoft Labs1`} postlabelclass="ml-2"/>
                    <p className="text-sm mt-2 hover:cursor-pointer text-green-800">Show more...</p>
                    
                    </>}
                />
                
            </div>
            {totalPages === 0 ? 
            <div id="divid" className="w-full align-center justify-center pt-[5%] text-center">
              <span>No Jobs Found!!! Try Clearing Filter</span>
            </div>
            :
            <div className="w-[70%] bg-white rounded-xl bg-opacity-50 p-1 ml-6">
             <div className="flex mt-4">
              <p className="ml-10 mb-0 mt-0.5">Viewing {fetchedJobcount} of {totalJobs} Jobs</p>
              <p className="mb-0 ml-auto">Sort by</p>
              <Selectbox
              labelclass="hidden"
              className="!w-4/12 h-7 ml-2 mr-10"
              value={sortBySelect}
              list={sortTypes}
              onChange={(e) => handleSortBy(e.target.value)}
              />
            </div>
            <div id="divid">
          {projects.map((project) => (
            <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end" key={project.id} CardBodyClass={"flex gap-4"}  CardClass="min-w-md mx-auto bg-white relative shadow rounded-lg w-11/12  mt-7 !rounded-xl"
            body={<>
            <div className="w-[60%] text-sm">
            <Textinput type="checkbox" labelclass="hidden" className="text-lg !w-[4.5%] w-4 h-4 mb-0 mt-[1.5%]" divclass="flex" postlabel={project.title} postlabelclass="ml-2 mb-2 text-xl font-bold"/>
            <div className="flex gap-4">
              <p className="text-black ml-[6%] mb-0 font-medium"><span style={{fontWeight:'500'}}>Created By : </span> {project.created_by}</p>
              <p className="before:content-['•'] before:text-green-700 before:mr-2 mb-2 list-none font-medium"><span style={{fontWeight:'500'}}>Posted : </span> {project.created_at}</p>
            </div>
            <p className="ml-[6%] text-black mb-2 font-medium"><span style={{fontWeight:'500'}}>Workplace : </span> {project.location}</p>
            <p className="ml-[6%] text-black flex font-medium"><span style={{fontWeight:'500'}}>Job : </span> {project.title} 
            <li 
             className={`before:content-['•'] before:mr-2 list-none pl-2 pr-2 pt-0.5 rounded-full text-xs ml-4 ${
              project.status === 'In Review'
                ? 'before:text-yellow-700 bg-yellow-200 text-yellow-900'
                : project.status === 'Active'
                ? 'before:text-green-700 bg-green-200 text-green-900'
                : project.status === 'Closed'
                ? 'before:text-red-700 bg-red-200 text-red-900'
                : ''
            }`}
            >{project.status}</li></p>
            </div>
            <div className=" w-[14%] hover:cursor-pointer flex flex-col justify-center items-center text-center"  onClick={()=>handleJobLists(project.id)}>
              <p className="text-green-600 text-xl font-medium">{project.pipeline_count}</p>
              <p>Pipeline</p>
            </div>
            <div className=" w-[14%] hover:cursor-pointer flex flex-col justify-center items-center text-center" onClick={()=>handleJobLists(project.id)}>
              <p className="text-green-600 text-xl font-medium">{project.applicants_total}{`(${project.applicants_new})`}New</p>
              <p>Applicants</p>
            </div>
            <div className=" pointer w-[10%] relative justify-center flex flex-col items-center text-center" ref={dropdownRef}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} className="" fill="black" aria-hidden="true"
                onClick={() =>
                  setOpenDropdown((prev) => (prev === project.id ? null : project.id))
                }>
                  <circle cx="5" cy="12" r="2" />
                  <circle cx="12" cy="12" r="2" />
                  <circle cx="19" cy="12" r="2" />
              </svg>
            </div>
            {openDropdown === project.id && (
              <div className="absolute  top-24 z-50 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" style={{right:'5%'}}>
                {jobOptions(project)}
              </div>
            )}
            </>}
        />
          ))}
          <div className={`flex w-11/12 mt-8 mb-8`} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <div className="flex" style={{ alignItems: 'center' }}>
              <ButtonSolid
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
                style={{ padding: '10px 30px', marginRight: '10px' }}
                className={`overflow-visible mt-0 border rounded-l-md ${currentPage === 1 ? '!bg-gray-200 hover:bg-gray-300' : ''}`}
                content="Previous"
              />
              <span className="text-nowrap px-4 py-2">
                Page {currentPage} of {totalPages}
              </span>
              <ButtonSolid
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                style={{ padding: '10px 30px', marginLeft: '10px' }}
                className={`overflow-visible mt-0 border rounded-r-md ${currentPage === totalPages ? '!bg-gray-200 hover:bg-gray-300' : ''}`}
                content="Next"
              />
            </div>
          </div>
        </div> 
            </div>}
            </div>
        </>
    );
}
export default ProjectDashboard;