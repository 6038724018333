import React ,{useEffect, useState, useContext, useRef} from "react";
import Topbar from "../Recruiter/Topbar/Topbar";
import Textinput from "../elements/TextInput/TextInput";
import Button from "../elements/Button/Button";
import Card from "../elements/Card/Card";
import Aerolite from "../../assets/Images/Aerolite.png"
import ModalBox from "../elements/ModalBox/ModalBox";
import Selectbox from "../elements/SelectBox/SelectBox";
import Textarea from "../elements/TextArea/TextArea";
import Edit from "../../assets/Images/edit.jpg"
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from 'crypto-js';
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
// import { set } from "mongoose";

const Companyoverview = () =>{
    const { token } = useContext(AuthContext);
    const [companyName,setName]=useState('');
    const [companyProfile,setProfile]=useState('');
    const [companyOverview,setOverview]=useState('');
    const [companyWebsite,setWebsite]=useState('');
    const [companyIndustry,setIndustry]=useState('');
    const [companySize,setSize]=useState('');
    const [companyHeadquarters,setHeadquarters]=useState('');
    const [companyFounded,setFounded]=useState('');
    const [companySpecialities,setSpecialities]=useState('');
    const [companyAddress,setAddress]=useState('');
    const [companyLocation,setLocation]=useState('');
    const fetchCompanyDetails = async () => {
        // setIsLoading(true);
        try {
              const serverURL = process.env.REACT_APP_SERVER_URL;
              const response = await fetch(`${serverURL}/company/fetchCompanyDetails`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    token: token
                  },
              });
              const data = await response.json();
      
              const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
              console.log("Company Details: ",decryptedData);
              if (!response.ok) {
                  var errorMessage = "Something Went Wrong";
                  if(data.error)
                      errorMessage = data.error;
                  showNotification(errorMessage,"error");
                  throw new Error(data.error);
              }
              else{
                //   companyName.current.value=decryptedData[0]['name'];
                  setName(decryptedData[0]['name']);
                  setProfile(decryptedData[0]['profile'])
                  setOverview(decryptedData[0]['about'])
                  setWebsite(decryptedData[0]['url'])
                  setIndustry(decryptedData[0]['industry'])
                  setSize(decryptedData[0]['size'])
                  setHeadquarters(decryptedData[0]['headquarters'])
                  setFounded(decryptedData[0]['founded_year'])
                  console.log("name: ",companyName)
              }
          } catch (error) {
              console.log('Error:', error);
              let errorMessage = "Something went wrong.";
              if (error.response && error.response.data && error.response.data.msg) {
                  errorMessage = error.response.data.msg;
              } 
              else if (error.message) {
                  errorMessage = error.message;
              }
            //   showNotification(errorMessage, "error");
          } finally {
            //   setIsLoading(false); // Always hide loader after the request
          }
      }
      useEffect(()=>{
        fetchCompanyDetails()
    },[])

    const showNotification = (message, type) => {   //For Notification
        setNotification({ message, type });
        setTimeout(() => {
          setNotification({ message: "", type: "" });
        }, 3000); // Auto-hide after 3 seconds
      };

  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const Sampleopen = () => setIsModalOpen(true);
  const closeForm = () => setIsModalOpen(false);
  const profile_navbar_dropdown = () => {                   //Dropdown in navbar
    setIsOpen(!isOpen);
  };
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
    const suggestions = [
        "Talent Acquisition",
        "Full Cycle Recruiting",
        "Recruitment Marketing",
        "AI Driven Recruiting Solutions",
        "Contract Staffing",
        "Global Talent Acquisition",
        "Employee Retention Strategies",
        "Custom"
    ];
  const [selectedValue, setSelectedValue] = useState('');
  const [Industryselect, setIndustryValue] = useState('');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleIndustryChange = (event) => {
    setIndustryValue(event.target.value);
  };

  const Companysize = [
    { id: '0', label: 'Select Size' },
    { id: '1', label: 'Small (1-10 employees)' },
    { id: '2', label: 'Medium (11-50 employees)' }
  ];
  const Industry = [
    { id: '0', label: 'Select Industry' },
    { id: '1', label: 'Small (1-10 employees)' },
    { id: '2', label: 'Medium (11-50 employees)' },
    { id: '3', label: 'Large (50-200 employees)' }
  ];
    const addSpecialty = (specialty) => {
        if (!selectedSpecialties.includes(specialty)) {
            setSelectedSpecialties([...selectedSpecialties, specialty]);
        }
    };

    const removeSpecialty = (specialty) => {
        setSelectedSpecialties(selectedSpecialties.filter(item => item !== specialty));
    };
  const modalBodyOverview=(
    
    <div className="w-3/4 ml-9">
        
                <p className="ml-6 text-black text-md mt-24">Upload Company Logo<span className="text-red-600">*</span></p>
                
                    <div className="flex ml-6 items-center justify-center w-full">
                        <label for="dropzone-file" className="flex items-center w-full h-22 border-2 border-green-300 border-dashed rounded-lg cursor-pointer bg-blue-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <img src={Edit} className="w-6 h-6 border-2 ml-12 fixed-0 mb-10 mr-3 absolute"></img>
                        <img src={Aerolite} className="w-14 h-14 border-2 ml-4 mt-2 mb-2 mr-3 rounded-xl"></img>
                            <div className="flex flex-col items-center justify-center pt-2 pb-2">
                               
                                <p className="mt-3 ml-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold  text-green-500 mr-1">Edit or Click here</span>to upload new Logo</p>
                                
                            </div>
                            <input id="dropzone-file" type="file" className="hidden" />
                        </label>
                    </div> 
                    <Textinput label="Company Name" labelclass="ml-6 mb-2 mt-4" span="*" className="border-1 border-blue-500 ml-6 w-full p-2.5 rounded-lg text-md"  />
                    <Selectbox
                        span="*"
                        label="Company Size"
                        labelClass="ml-6 mt-4 mb-2"
                        name="exampleSelect"
                        value={selectedValue}
                        onChange={handleSelectChange}
                        list={Companysize}
                        className="ml-6 p-2.5"
                        required
                    />
                    <Textinput label="Headquarter" labelclass="ml-6 mb-2 mt-4" span="*" className="border-1 border-blue-500 ml-6 w-full  p-2.5 rounded-lg text-md"  />
                    <Selectbox
                        span="*"
                        label="Industry"
                        labelClass="ml-6 mt-4 mb-2"
                        name="exampleSelect"
                        value={Industryselect}
                        onChange={handleIndustryChange}
                        list={Industry}
                        className="ml-6 p-2.5"
                        required
                    />
                    <Textarea label="Address" labelClass="ml-6 mt-4 mb-2" span="*"  className="resize-none px-1 ml-6"/>
                    <div className="flex ml-6 w-full">
                    <div className="w-7/12">
                    <Textinput className="w-full mt-2 p-2.5 rounded-lg text-md" labelclass="mt-9" label="Location URL" span="*" />
                    </div>
                    <div className="mt-[4.3%] flex">
                    <p className="p-1 bg-gray-50 w-6 h-6 ml-4 mt-16 text-xs rounded-full text-gray-500">Or</p>
                    <Button className="bg-teal-50 text-green-700 font-normal text-xs pt-2 pb-2 h-10 mt-14 pl-2 ml-3 rounded-lg pr-2 ">Current Location</Button>
                    </div>
                    </div>
                    <Textarea label="About"  span="*" labelClass="ml-6 mt-4 mb-2" className="border-1 border-blue-500  ml-6 w-full p-2 rounded-lg text-md resize-none"/>
                    <Textinput label="Company Website Link" className="border-1 border-blue-500 ml-6 w-full p-2.5 rounded-lg text-md" span="*" labelclass="ml-6 mt-4 mb-2" />
                    <Textinput label="Founded" className="border-1 border-blue-500 ml-6 w-full p-2.5 rounded-lg " span="*" labelclass="ml-6 mt-4 mb-2" />
                    <Selectbox
                        span="*"
                        label="Specialities"
                        labelClass="ml-6 mt-4 font-normal mb-2"
                        name="exampleSelect"
                        value={Industryselect}
                        onChange={handleIndustryChange}
                        list={Industry}
                        className="ml-6 p-2.5"
                        required
                    />

    </div>
    
  )
  const ModalFooterOverview=(<>
    <div className="mb-4 mt-10 ml-3">
    <Button className=" border-2 border-green-800 bg-white mt-2 text-sm text-green-700 w-1/4 ml-12 pt-2 pb-2 pl-8 pr-8 rounded "
        onClick={closeForm}>Cancel</Button>
    <Button className=" border-2 border-green-800 bg-green-700 text-sm text-white w-[25%] ml-4 pt-2 pb-2  rounded "
        >Save</Button>
        </div>
        </>
      )
    return(
    
            <Card CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-4/6 mt-10 border-white shadow" CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2"
                body={
                    <>
                    <diV className="flex border-b-2 border-gray-100">
                        <div className="">
                            <img src={Aerolite} className="w-14 h-14 border-2 mt-3 mr-3 rounded-xl"></img>
                        </div>
                        <div className="flex gap-30">
                            <p className="text-xl mt-3 font-normal text-black dark:text-white">
                                 {companyName?companyName:"Aerolite"}
                                 <div className="flex w-full">
                                <p className="text-xs p-1 rounded-lg bg-sky-50">MNC</p>
                                <p className="text-xs p-1 ml-2 rounded-lg bg-sky-50">Software</p>
                                <p className="text-xs p-1 ml-2 rounded-lg bg-sky-50">Bengaluru</p>
                                <p className="text-xs p-1 ml-2 rounded-lg bg-sky-50">Software</p>
                            </div>
                            </p>
                            <div className="ml-96">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" onClick={Sampleopen} className="ml-20  mt-8"  viewBox="0 0 24 24" fill="green">
                                <path d="M13.0207 5.82839L15.8491 2.99996L20.7988 7.94971L17.9704 10.7781M13.0207 5.82839L3.41405 15.435C3.22652 15.6225 3.12116 15.8769 3.12116 16.1421V20.6776H7.65669C7.92191 20.6776 8.17626 20.5723 8.3638 20.3847L17.9704 10.7781M13.0207 5.82839L17.9704 10.7781" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> 
                            {isModalOpen && (
                                <div>
                                    <ModalBox  Modal_Body={modalBodyOverview}   Modal_Footer={ModalFooterOverview} Modal_Close_Func={closeForm} Modal_Header="Edit Company Profile" modal_Header_Text="mt-4 mb-3 ml-14" modal_Header_Class="border-gray-300 border-b-2 z-50 min-w-[45.5%] max-w-[45.5%] fixed bg-white font-medium" modal_Close_Class="mt-1 mr-2 border-2 border-gray-300 rounded-lg pl-2 pt-1 pb-1 pr-2" cardClass="bg-white rounded-2xl shadow-lg overflow-y-scroll w-2/4 h-[95%] p-0  max-w-xl"/>
                                </div>
                            )}
                            </div>
                        </div>
                        
                    </diV>
                    <p className="text-black mt-4">Overview<p className="text-gray-500 text-sm mt-2">{companyOverview?companyOverview:"Aerolite is a leading development company specializing in cutting-edge solutions for web, mobile, and cloud-based applications. With a focus on innovation and quality, Aerolite partners with global clients to deliver high-performance software products tailored to their needs. Our team of experts excels in crafting scalable, efficient, and future-ready solutions, ensuring businesses thrive in the digital age. At Aerolite, we are committed to pushing the boundaries of development to create technology that drives success."} </p></p>
                    <p className="text-black mt-4">Website<p className="text-gray-500 text-xs mt-2"><a href="">{companyWebsite?companyWebsite:"www.aeroliteipsum.com"}</a></p></p>
                    <p className="text-black mt-4">Industry<p className="text-gray-500 text mt-2">{companyIndustry?companyIndustry:"Software"}</p></p>
                    <p className="text-black mt-4">Company Size<p className="text-gray-500 text mt-2">{companySize?companySize:"51-200"}</p></p>
                    <p className="text-black mt-4">Headquarters<p className="text-gray-500 text mt-2">{companyHeadquarters?companyHeadquarters:"Bengaluru"}</p></p>
                    <p className="text-black mt-4">Founded<p className="text-gray-500 text mt-2">{companyFounded?companyFounded:"2008"}</p></p>
                    <p className="text-black mt-4">Specialities<p className="text-gray-500 text-sm mt-2">Design Strategy & Consulting, User Experience for mobile & web apps, Interaction Design, Service Design, Product Design, Front End Experience Design, Interface design , UI/UX , Mobile applications , AR/VR, IoT, Web design , Design thinking , Startup design , enterprise design , and B2B/B2C</p></p>
                    <p className="text-black mt-4">Address<p className="text-gray-500 text mt-2">XYZ, Street 123, Bengaluru, 558946</p></p>
                    <p className="text-black mt-4">Location</p>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.041465472381!2d144.9630932153119!3d-37.81410797975148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577f9cd6f620e33!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1602117830944!5m2!1sen!2sau" 
                        className="w-full h-64"        
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                    </>
                }
            />
            
        
    
    );
}
export default Companyoverview