import React from "react";
import CompanyAdd from "../../components/CompanyProfile/CompanyDetail"

const CompanyDetail = () =>{
    return(
        <div className="radial-gradient-bg">
            <CompanyAdd/>
        </div>
    );
};
export default CompanyDetail;