// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Recruiter/Topbar/Topbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,wBAAwB;IACxB,qBAAqB;AACzB","sourcesContent":[".no-scrollbar::-webkit-scrollbar {\n    display: none;\n}\n.no-scrollbar {\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
