// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-div-bottom {
    position: fixed;
    bottom: 0;
    max-width: 70%;
    min-width: 65%;
}`, "",{"version":3,"sources":["webpack://./src/pages/JobSeeker/Onboard.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,cAAc;IACd,cAAc;AAClB","sourcesContent":[".save-div-bottom {\n    position: fixed;\n    bottom: 0;\n    max-width: 70%;\n    min-width: 65%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
