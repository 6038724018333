// Basic imports
import React from 'react';
// import Eeezo from '../components/home/Eeezo';
import SignupDetails from '../components/SignUp/SignUp';

const RegisterForm = () => {
	return (
		<div className="radial-gradient-bg">
			<div className="signup-page">
				<SignupDetails />
			</div>
		</div>
	);
};

export default RegisterForm;
