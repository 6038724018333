import React, { useState } from 'react';
import searchlens from '../../assets/search-lens.png'

const Projects = () => {
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };
  const recentlyViewedProjects = [
    { id: 1, title: "Project Alpha", job_desc: "Sr. UX Designer",created_by: "Ananya",posted: "24/09/2024",pipeline: "17",applicant: "15(5 New)" },
    { id: 2, title: "Project Beta", job_desc: "Sr. UX Designer",created_by: "Ananya",posted: "24/09/2024",pipeline: "17",applicant: "15(5 New)" },
    { id: 3, title: "Project Gamma", job_desc: "Sr. UX Designer",created_by: "Ananya",posted: "24/09/2024",pipeline: "17",applicant: "15(5 New)" },
  ];

  const recentlySearched = [
    { id: 1, name: "Java Developer With 5+ Years of Experience In Cloud Technologies...", filters: ["Experience","location"],date: "02/12/24"},
    { id: 2, name: "Java Developer With 5+ Years of Experience In Cloud Technologies...", filters: ["Experience","location"],date: "02/12/24"},
    { id: 3, name: "Java Developer With 5+ Years of Experience In Cloud Technologies...", filters: ["Experience","location"],date: "02/12/24"},
  ];

  return (
    <div className="dashboard-project">
      {/* Left Side - Main Content */}
      <div className="main-content-project">
      <div className="recently-viewed">
          <h3>Recently Viewed Projects</h3>
          {recentlyViewedProjects.map((project) => (
            <div className="card" key={project.id}>
                <div className="card-left">
                    <h4 className='font-bold'>{project.title}</h4>
                    <p className='mb-2'>Created By : {project.created_by} <span className='dot-status active inline-block'></span> Posted : {project.posted}</p>
                    <p className='mb-2'>Job : {project.job_desc}</p>
                </div>

                {/* Center Section */}
                <div className="card-center">
                    <div className="number-box">{project.pipeline}</div>
                    <p className="separator">Pipeline</p>
                </div>

                {/* Right Section */}
                <div className="card-right">
                    <div className="number-box">{project.applicant}</div>
                    <p className="separator">Applicants</p>
                </div>
                <div className="menu">
                <span className="three-dots" onClick={() => toggleDropdown(project.id)}>
                  <span style={{height:'5px',width:'5px',backgroundColor:'#059669',display:'inline-block',borderRadius:'10px',marginRight:'2px'}}></span>
                  <span style={{height:'5px',width:'5px',backgroundColor:'#059669',display:'inline-block',borderRadius:'10px',marginRight:'2px'}}></span>
                  <span style={{height:'5px',width:'5px',backgroundColor:'#059669',display:'inline-block',borderRadius:'10px',marginRight:'2px'}}></span>
                </span>
                {dropdownOpen === project.id && (
                  <div className="dropdown absolute">
                    <p>Edit</p>
                    <p>Delete</p>
                    <p>View Details</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Right Side - Continue search section */}
      <div className="sidebar-project">
          <div className="continue-search">
          <h3>Continue Your Search</h3>
          {recentlySearched.map((search) => (
            <div className="search-item" key={search.id}>
              <div className='search-date'>
                <p><img src={searchlens} className='search-star '/> Search</p>
                <p>{search.date}</p>
              </div>
              <p className='ml-2 font-normal'>{search.name}</p>
              <p className='ml-2' style={{color:'#747474'}}>+{search.filters.length} Filters</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
