import React from "react";
import Topbar from "../../components/Recruiter/Topbar/Topbar";
import ProjectsBar from "../../components/Projects/Projects";
const ProjectPipe=()=>{
    return(
        <>
        <div className="radial-gradient-bg">
            <div><Topbar/></div>
            <ProjectsBar/>
            </div>
        </>
    );
}
export default ProjectPipe