import React from 'react';
const Textarea = (props)=>{
    return (
        <>
            <label className={`block  ${props.labelClass || ''}`}>{props.label || ''}<span className="text-red-600">{props.span || ''}</span> </label>
            <textarea
                name={props.name || ''}
                value={props.value}
                onChange={props.onChange || (() => {})} 
                ref={props.InputRef}
                placeholder={props.placeholder || ''}
                className={`profile_sidebar_progress_text mt-1 w-full px-2 py-2 border-1 rounded-md ${props.className || ''}`}
                required = {props.required || false}
                onInput={props.OnInput}
                style={props.style}
            />
        </>
    )
}
export default Textarea;