import React from "react";
import TeamMember from "../../components/TeamMember/TeamMember";

const Team=()=>{
    return(
        <>
        <TeamMember/>
        </>
    );
}
export default Team