import React,{ useState } from "react";
import TeamMember from "../TeamMember/TeamMember";
import Profile from "./UserProfile";
import Companyoverview from "../CompanyProfile/CompanyOverview";
import Button from "../elements/Button/Button";
const AddMember = () => {
  const[sideChange,setSideState]=useState("company");
    const handleSideBar=(state)=>{
        console.log(state);
        setSideState(state);
        console.log(sideChange," side")
    }
    return(
 <div>   
<div className="flex gap-28">
            <div className="w-1/5">
                <Button className={`ml-11 pl-2 pr-4  pt-3 pb-3 rounded-xl flex w-full mt-10  border-white ${sideChange==="company" ? "bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`} onClick={ () => handleSideBar("company")}><svg xmlns="http://www.w3.org/2000/svg" fill="#00945b" className="mr-4" width="24px" height="24px" viewBox="0 0 24 24" stroke="#00945b"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></g></svg>Company Overview</Button>
                <Button className={`ml-11 pl-2 pr-4  pt-3 pb-3 rounded-xl flex w-full mt-2  border-white ${sideChange==="manage" ? "bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`} onClick={() => handleSideBar("manage")}><svg xmlns="http://www.w3.org/2000/svg" fill="#00945b" className="mr-4" width="24px" height="24px" viewBox="0 0 24 24" stroke="#00945b"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></g></svg>Manage Users</Button>
                <Button className={`ml-11 pl-2 pr-4  pt-3 pb-3 rounded-xl flex w-full mt-2  border-white ${sideChange==="message"?"bg-gradient-to-r from-sky-300 to-emerald-100 ...": "bg-white"}`} onClick={ () => handleSideBar("message")}><svg xmlns="http://www.w3.org/2000/svg" fill="#00945b" className="mr-4" width="24px" height="24px" viewBox="0 0 24 24" stroke="#00945b"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></g></svg>Message Templates</Button>
                <Button className={`ml-11 pl-2 pr-4  pt-3 pb-3 rounded-xl flex w-full mt-2  border-white ${sideChange==="user"?"bg-gradient-to-r from-sky-300 to-emerald-100 ..." : "bg-white"}`} onClick={ () => handleSideBar("user")}><svg xmlns="http://www.w3.org/2000/svg" fill="#00945b" className="mr-4" width="24px" height="24px" viewBox="0 0 24 24" stroke="#00945b"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></g></svg>User Profile</Button>
            </div>
            {sideChange === "manage" && <TeamMember />}
            {sideChange==="company" && <Companyoverview/>}
            {sideChange==="user" && <Profile/>}

</div>
</div>
)
};
export default AddMember;