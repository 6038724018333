import React from "react";
import Topbar from "../../components/Recruiter/Topbar/Topbar";
import HomeSearchProfiles from "../../components/HomeSearchResults/HomeSearchResults";
const HomeSearch = () =>{
    return(
        <div className="radial-gradient-bg">
            <Topbar/>
            <div>
                <HomeSearchProfiles/>
            </div>
        </div>
    )
}
export default HomeSearch