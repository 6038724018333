// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mic-icon-div {
    position: absolute;
    right: 65px;
    cursor: pointer;
    height: 35px;
    top: 0px;
    width: 35px;
    background-color: #F2F9F9;
    border-radius: 5px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.upload-icon-div {
    position: absolute;
    right: 20px;
    cursor: pointer;
    height: 35px;
    top: 0px;
    width: 35px;
    background-color: #F2F9F9;
    border-radius: 5px;
    padding: 5px;
    /* margin-right: 10px; */
    justify-content: center;
    align-items: center;
    display: flex;
}
.search-icon {
    max-width: 50px;
}
.option-search-type {
    padding: 15px;
    color: white;
    border-top: 1px solid white;
    cursor: pointer;
}
.option-search-type:hover {
    background-color: #0d6a4a;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/SearchInput/SearchInput.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,YAAY;IACZ,QAAQ;IACR,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,YAAY;IACZ,QAAQ;IACR,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,wBAAwB;IACxB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,YAAY;IACZ,2BAA2B;IAC3B,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".mic-icon-div {\n    position: absolute;\n    right: 65px;\n    cursor: pointer;\n    height: 35px;\n    top: 0px;\n    width: 35px;\n    background-color: #F2F9F9;\n    border-radius: 5px;\n    padding: 5px;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n}\n.upload-icon-div {\n    position: absolute;\n    right: 20px;\n    cursor: pointer;\n    height: 35px;\n    top: 0px;\n    width: 35px;\n    background-color: #F2F9F9;\n    border-radius: 5px;\n    padding: 5px;\n    /* margin-right: 10px; */\n    justify-content: center;\n    align-items: center;\n    display: flex;\n}\n.search-icon {\n    max-width: 50px;\n}\n.option-search-type {\n    padding: 15px;\n    color: white;\n    border-top: 1px solid white;\n    cursor: pointer;\n}\n.option-search-type:hover {\n    background-color: #0d6a4a;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
