import React, { useState, useRef, useContext } from "react";
import Logo from "../../components/Common/Logo";
import Textinput from "../../components/elements/TextInput/TextInput";
import Selectbox from "../../components/elements/SelectBox/SelectBox";
import Textarea from "../../components/elements/TextArea/TextArea";
import Button from "../../components/elements/Button/Button";
import ImageIcon from "../../components/elements/ImageIcon/ImageIcon";
import { validateEmail } from "../../components/Validation/Validation";
import Notification from "../../components/Notification/Notification";
import uploadicon from '../../assets/file-upload.png'
import './Onboard.css';
import ExperienceSelector from "../../components/JobSeeker/UserExperience";
import { useCommonContext } from "../../context/CommonContext";
const JobSeekerOnboard = () => {
    const [notification, setNotification] = useState({ message: "", type: "" });
    const { experience } = useCommonContext();
    const SaveEmployement = () => {
        console.log(experience);
    }

    const showNotification = (message, type) => {   //For Notification
        setNotification({ message, type });
        setTimeout(() => {
            setNotification({ message: "", type: "" });
        }, 3000); // Auto-hide after 3 seconds
    };
    return (
        <div className="">
            <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />
            <div className="flex">
                {/* left side section */}
                <div className="side-option w-3/12 pr-2 mr-8 bg-gradient-to-r from-white to-white-50 to-emerald-50 ...">
                    <div className="ml-4">
                        <Logo />
                    </div>
                    <p className="ml-8 mt-8 mb-8 text-xl text-black font-medium">Steps For Registering</p>
                    {/* first option */}
                    <div className="flex rounded-lg  w-11/12 ml-6 h-16 mt-1  pr-4 pl-2" style={{ backgroundColor: '#F2F9F9', border: '1px solid #BFE0E2' }}>
                        <div className="pr-2 w-11 ml-2 h-11 mt-2  ">
                            <div className="w-1/8 rounded-full ml-1.5 p-2 w-8 h-8 mt-1.5" style={{ backgroundColor: '#059669' }}>
                            </div>
                        </div>
                        <p className="text-white absolute pt-3 text-lg  pl-6 ml-0.5">1</p>
                        <div>
                            <p className="text-black  mt-2.5 ml-4 text-sm">Basic Details<p className="text-gray-400 text-xs ">Step desciption</p></p>
                        </div>
                    </div>
                    {/* second */}
                    <div className="h-8 ml-32 border-l-2 border-green-500"></div>
                    <div className="bg-black flex rounded-lg  w-11/12 ml-6 h-16   pr-4 pl-2">
                        <div className="bg-black pr-2 w-11 ml-2 h-11 mt-2 border-dashed border-2 border-green-500 rounded-full">
                            <div className="bg-green-800 w-1/8 rounded-full ml-1.5 p-2 w-7 h-7 mt-1.5">
                            </div>
                        </div>
                        <p className="text-white absolute mt-3 text-lg  pl-6">2</p>
                        <div>
                            <p className="text-white  mt-2.5 ml-4 text-sm">Employement<p className="text-gray-400 text-xs ">Step desciption</p></p>
                        </div>
                    </div>
                    {/* Third */}
                    <div className="h-8 ml-32 border-l-2 border-green-500"></div>
                    <div className="bg-white flex rounded-lg  w-11/12 ml-6 h-16 mt-1  pr-4 pl-2">
                        <div className="bg-white pr-2 w-11 ml-2 h-11 mt-2  ">
                            <div className="bg-white w-1/8 border-2 border-gray-500 rounded-full ml-1.5 p-2 w-8 h-8 mt-1.5">
                            </div>
                        </div>
                        <p className="text-black absolute pt-3 text-lg  pl-6 ml-0.5">3</p>
                        <div>
                            <p className="text-black  mt-2.5 ml-4 text-sm">Education<p className="text-gray-400 text-xs ">Step desciption</p></p>
                        </div>
                    </div>
                    {/* fourth */}
                    <div className="h-8 ml-32 border-l-2 border-green-500"></div>
                    <div className="bg-white flex rounded-lg  w-11/12 ml-6 h-16 mt-1  pr-4 pl-2">
                        <div className="bg-white pr-2 w-11 ml-2 h-11 mt-2  ">
                            <div className="bg-white w-1/8 border-2 border-gray-500 rounded-full ml-1.5 p-2 w-8 h-8 mt-1.5">
                            </div>
                        </div>
                        <p className="text-black absolute pt-3 text-lg  pl-6 ml-0.5">4</p>
                        <div>
                            <p className="text-black  mt-2.5 ml-4 text-sm">Review & Submit<p className="text-gray-400 text-xs ">Step desciption</p></p>
                        </div>
                    </div>
                    {/* fifth */}
                    <div className="h-8 ml-32 border-l-2 border-green-500"></div>
                    <div className="bg-white flex rounded-lg  w-11/12 ml-6 h-16 mt-1  pr-4 pl-2">
                        <div className="bg-white pr-2 w-11 ml-2 h-11 mt-2  ">
                            <div className="bg-white w-1/8 border-2 border-gray-500 rounded-full ml-1.5 p-2 w-8 h-8 mt-1.5">
                            </div>
                        </div>
                        <p className="text-black absolute pt-3 text-lg  pl-6 ml-0.5">5</p>
                        <div>
                            <p className="text-black  mt-2.5 ml-4 text-sm">Preference & Key Skills<p className="text-gray-400 text-xs ">Step desciption</p></p>
                        </div>
                    </div>
                </div>
                {/* Right side section */}
                <div className="rounded-t-2xl border mt-6 ml-4 mr-4 bg-white w-3/4">
                    <div className="flex">
                        <div className="w-6/12">
                            <p className="text-lg text-black font-medium ml-6 mt-3">Employement Details<p className="text-xs font-normal text-gray-500 mt-1">Fill in your previous details and let our AI help you find your perfect job!</p></p>
                            <p className="ml-6 text-black text-md">Work Status<span className="text-red-600">*</span></p>
                            <div  className="flex ml-6 items-center justify-center w-full">
                                <ExperienceSelector />
                            </div>
                        </div>
                        <div className="mt-24 ml-14 mr-8">
                            <p className="mt-20  absolute pr-2 pt-1 pb-1 pl-2 rounded-full bg-green-50 text-green-600 font-medium">Or</p>
                            <div className="h-48 ml-4 border-l-2 border-gray-200"></div>
                        </div>
                        <div className="mt-28 p-10 rounded-lg ml-4 bg-gradient-to-r from-sky-300 to-emerald-100 ...">
                            <Button className="linkedin-button flex h-16"><img src={uploadicon} style={{ maxHeight: '35px' }} /><p className="font-medium text-md">Upload From Resume<p className="text-xs ml-3 font-normal text-gray-500">To save your information directly</p></p></Button>
                            <Button className="linkedin-button flex h-16"><svg xmlns="http://www.w3.org/2000/svg" className="mr-2 mt-2.5" fill="none" height="24" viewBox="0 0 24 24" width="24"><path d="m19.0413 19.0413h-2.9635v-4.6409c0-1.1067-.0197-2.5313-1.5412-2.5313-1.5435 0-1.7797 1.2057-1.7797 2.4507v4.7211h-2.96337v-9.54349h2.84487v1.30419h.0399c.5792-.99038 1.6602-1.5841 2.8069-1.54152 3.0035 0 3.5573 1.97562 3.5573 4.54582zm-12.59151-10.84845c-.94342 0-1.72009-.77622-1.72009-1.71964s.77631-1.71973 1.71973-1.71973c.94333 0 1.71955.77613 1.71973 1.71937 0 .94324-.77613 1.71991-1.71937 1.72zm1.48169 10.84845h-2.96651v-9.54389h2.96651zm12.58712-17.0398h-17.04282c-.80002-.00903-1.46603.6415-1.47578 1.44152v17.11358c.00939.8004.67531 1.4517 1.47578 1.4431h17.04282c.8024.0101 1.4707-.641 1.4814-1.4431v-17.11475c-.0107-.80171-.6799-1.45216-1.4814-1.4416z" fill="#0a66c2" /></svg><p className="font-medium text-md">Upload From LinkedIn<p className="text-xs ml-3 font-normal text-gray-500">To save your information directly</p></p></Button>
                        </div>
                    </div>
                    <div className="save-div-bottom mt-10 shadow rounded-t-2xl flex mr-4 bg-black h-20">
                        <div>
                            <Button onClick={() => SaveEmployement()} className=" pr-4 pl-4 ml-8 pt-2 pb-2 rounded-lg mt-4 border-none bg-green-700 text-white">Save & Continue</Button>
                        </div>
                        <div>
                            <Button className=" pr-8 pl-8 ml-8 pt-1.5 pb-1.5 rounded-lg mt-4 border  bg-black text-white hidden">Skip</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default JobSeekerOnboard;
