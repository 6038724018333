import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

// Create the AuthContext
export const AuthContext = createContext();

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [isAuthenticated, setAuthenticated] = useState(!!token); // Initial authentication state

  // Function to verify the token
  const verifyUserToken = async () => {
    try {
	    if(!token && window.location.pathname == "/SignUp"){
	    	 localStorage.removeItem("token");
		    return;
	    }
      if (!token) {
	      localStorage.removeItem("token");
        console.log(window.location.pathname+"---No token found. Redirecting to login.");
        setAuthenticated(false);
	     if(window.location.pathname!="/")// || window.location.pathname!="/signup")
      		  navigate("/");
	      return;
      }

      const serverURL = process.env.REACT_APP_SERVER_URL;
      const verifyTokenUrl = `${serverURL}/verifyToken`;

      const response = await fetch(verifyTokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
	console.log(data,"auth data")
      if (data.status === "success") {
       
	console.log("User authenticated.");
        setAuthenticated(true);
	     console.log(data,"Data")
	      const encryptedMetadata = localStorage.getItem('metadata');
	   console.log(encryptedMetadata,"encryptedMetadatai ")
	      if (encryptedMetadata){// && encryptedMetadata.data) {
	       console.log(encryptedMetadata,"encryptedMetadata")
		      const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	         console.log(decryptedMetadata,"dmd",decryptedMetadata.cid)
		      if( window.location.pathname !== "/AddCompany" && (!decryptedMetadata.cid || decryptedMetadata.cid == "" || decryptedMetadata.cid == null))
			      navigate("/AddCompany")

	       }
	      //setAuthenticated(true)
      } else {
        console.log("Invalid token. Redirecting to login.");
        handleLogout();
      }
    } catch (error) {
      console.error("Error verifying token:", error);
      handleLogout();
    }
  };

  // Logout function
  const handleLogout = () => {
    console.log("Logging out...");
    setToken("");
    setAuthenticated(false);
    localStorage.removeItem("token");
    navigate("/");
  };

  // Sync token to localStorage on change
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        isAuthenticated,
        verifyUserToken,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

