// Basic imports
import React, { useState, useRef, useContext } from 'react';
import Topbar from '../components/Recruiter/Topbar/Topbar'
import Projects from '../components/Dashboard/Projects';
import star from '../assets/star-search.png';
import { useCommonContext } from '../context/CommonContext';
import SearchInput from '../components/elements/SearchInput/SearchInput';
import ButtonSolid from '../components/elements/Button/ButtonSolid';
import Notification from "../components/Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import CryptoJS from 'crypto-js';
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
	const { searchTerm, setSearchTerm, setIsAIsearch, isAIsearch, showUpload, setShowUpload,aiSearchResult,setAiSearchResult } = useCommonContext();
	const [suggestions, setSuggestions] = useState([
		'AI Engineer', 'Data Scientist', 'Machine Learning', 'Deep Learning', 'Natural Language Processing'
	]);
	const navigate = useNavigate();
	const [notification, setNotification] = useState({ message: "", type: "" });
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useContext(AuthContext);
	const [jobStats, setJobStats] = useState([
		{ number: 18, label: "Open Jobs" },
		{ number: 21, label: "Open Jobs" },
		{ number: 7, label: "Upcoming Interviews" },
		{ number: 18, label: "Open Jobs" },
	  ]);

	const showNotification = (message, type) => {   //For Notification
        setNotification({ message, type });
        setTimeout(() => {
          setNotification({ message: "", type: "" });
        }, 3000); // Auto-hide after 3 seconds
    };

	  const searchProject = async () => {
		if(isAIsearch){
			if(!searchTerm){
				showNotification("Please Enter Search Data", "error");
				return
			}
			var userId = '';
			var cid = '';
			const encryptedMetadata = localStorage.getItem('metadata');
	        if (encryptedMetadata){
	            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	            if(decryptedMetadata.uid){
	            	userId = decryptedMetadata.uid;
	            }
	            if(decryptedMetadata.cid){
	            	cid = decryptedMetadata.cid;
	            }
	        }
			const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({ search:searchTerm,userId:userId,cid:cid }), process.env.REACT_APP_SECRETKEY).toString();
			var searchData = { data:encryptedData}
			setIsLoading(true);
	        try {
	            const serverURL = process.env.REACT_APP_SERVER_URL;
	            const response = await fetch(`${serverURL}/JD/searchResumeData`, {
	                method: 'POST',
	                headers: {
	                  'Content-Type': 'application/json',
	                  token: token
	                },
	                body: JSON.stringify(searchData), 
	            });
	            const result = await response.json();
	            console.log("Server Response:", result);
	            if (!response.ok) {
	                throw new Error(result.error);
	            }
	            const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	            const encryptedMetadata = localStorage.getItem('metadata');
	            if (encryptedMetadata){
	            	const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
	            	decryptedMetadata['jd_id'] = decryptedData.jd_id;
	            	const encryptedUpdatedMetadata = CryptoJS.AES.encrypt(JSON.stringify(decryptedMetadata), process.env.REACT_APP_SECRETKEY).toString();
                    localStorage.setItem('metadata', encryptedUpdatedMetadata);
                }
	            navigate("/Homesearchresults")
	            return;
        		// console.log(decryptedData);
        		setAiSearchResult(decryptedData);
        		console.log(aiSearchResult)
	            showNotification(result.message, "success");
	            navigate("/Homesearchresults")
	        } catch (error) {
	            console.error("Error uploading file:", error);
	            showNotification(error.message, "error");
	        }finally {
	            setIsLoading(false); // Always hide loader after the request
	        }
	  	}
	  }
	  const changesearchvalue = (value) => {
		setSearchTerm(value);
	  }


	return (
		<div className="dashboard radial-gradient-bg">
			{isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Fetching...</span>
                    </div>
                </div>
            )}
            <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />
			<Topbar />
			<div className="center-container">
            <h1 className='WelcomeUser'>Welcome User</h1>
				<div className="search-box-container" style={{height:'110px'}}>
					<SearchInput />
				</div>
				{!showUpload && <>
				{isAIsearch && <div className="suggestions-container">
					{suggestions.map((suggestion, index) => (
						<span value={suggestion} onClick={() => changesearchvalue(suggestion)} key={index} className="suggestion-pill">
							<img className='search-star' src={star} />
							{suggestion}
						</span>
					))}
				</div>}
				<ButtonSolid
				content="Find Profile"
				className="my-custom-button"
				onClick={() => searchProject()}
				style={{ maxWidth: '120px',overflow: 'visible' }}
				id="button-search"
				type="button"
			/>
			</>}
			</div>
			{/* Add other scrollable content here */}
			<div className='count-section'>
				<div className="other-content">
					{jobStats.map((stat, index) => (
					<div className="container-green" key={index}>
						<h2 className='count-num'>{stat.number}</h2>
						<p className='count-name'>{stat.label}</p>
					</div>
					))}
				</div>
			</div>
			<Projects />
		</div>
	);
};

export default Dashboard;
