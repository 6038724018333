import React from 'react';
const PasswordStrength = (props)=>{
    return (
        <>
            <div className={props.Strength_Hole_Class}>
                <div className={`w-3/12 h-1 w-full bg-gray-100 rounded-lg ${props.Strength_Box_First}`} id={props.first}>
                </div>
                <div className={`w-3/12 h-1 w-full bg-gray-100 rounded-lg ${props.Strength_Box_Second}`} id={props.second}>
                </div>
                <div className={`w-3/12 h-1 w-full bg-gray-100 rounded-lg ${props.Strength_Box_Third}`} id={props.third}>
                </div>
                <div className={`w-3/12 h-1 w-full bg-gray-100 rounded-lg ${props.Strength_Box_Fourth}`} id={props.fourth}>
                </div>
          </div>
        </>
    )
}
export default PasswordStrength;