// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: white;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .notification-error {
    background-color: #f44336;
  }
  
  .notification-success {
    background-color: #4caf50;
  }
  
  .notification-close {
    background: none;
    border: none;
    font-size: 20px;
    color: white;
    cursor: pointer;
    margin-left: 8px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Notification/Notification.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,0CAA0C;IAC1C,YAAY;IACZ,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".notification {\n    position: fixed;\n    top: 20px;\n    right: 20px;\n    padding: 16px;\n    border-radius: 8px;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n    color: white;\n    z-index: 1000;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .notification-error {\n    background-color: #f44336;\n  }\n  \n  .notification-success {\n    background-color: #4caf50;\n  }\n  \n  .notification-close {\n    background: none;\n    border: none;\n    font-size: 20px;\n    color: white;\n    cursor: pointer;\n    margin-left: 8px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
