// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nsm7Bb-HzV7m-LgbsSe {
    border: none !important;
}
#signUpButton {
    width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/components/GmailSignUp/GmailSignUp.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,UAAU;AACd","sourcesContent":[".nsm7Bb-HzV7m-LgbsSe {\n    border: none !important;\n}\n#signUpButton {\n    width: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
