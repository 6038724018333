import React,{useState,useEffect,useRef} from "react";
import Card from "../elements/Card/Card";
import Button from "../elements/Button/Button";
import Person from "../../assets/Images/person.jpg"
// import Applicant from "./Applicants";

const HiddenCandidates = () =>{
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
      
        const toggleDropdownPipeline = () => {
          setIsOpen((prev) => !prev);
        };
    const [isOpenMessage, setIsOpenMessage] = useState(false);
      
        const toggleDropdownMessage = () => {
          setIsOpenMessage((prev) => !prev);
        };
    // const [applicants,setApplicantStatus]=useState(1);
    // const handleApplicant = () =>{
    //     setApplicantStatus(2);
    // }
    const handleClickOutside = (event) => {
        if ((isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target))||(isOpenMessage && dropdownRef.current && !dropdownRef.current.contains(event.target))) {
            setIsOpen(false);
            setIsOpenMessage(false)
        }
        
    };
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen,isOpenMessage]);
    return(
        <>
        <div className="bg-white ml-12 gap-2 w-[70%] p-4 bg-opacity-[50%] rounded-xl">
            <div>
                <div>
                <div className="flex gap-[16px]">
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`18`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Open to Work</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`21`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Active Talent</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[14px] pb-[14px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`07`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto text-center">Rediscovered Candidates</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`18`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Open Jobs</p>
                        </div>
                        <div className="w-[177px] h-[100px] bg-[#F2F9F9] rounded-[17px] relative pt-[14px] pb-[14px] pl-[10px] pr-[10px] grid gap-[4px]">
                            <p className="mb-0 text-[26px] font-[700] mx-auto items-center text-[#059669]">{`1`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto text-center ">Engaged with your talent brand</p>
                        </div>
                    </div> 
                </div>
            </div>
            <div>
                        <p className="mb-0 text-xl mt-4 font-[700]">Hidden Candidates Search <span className="text-xs font-[400] text-[#747474]">1.6k results</span></p>
                    </div>
            <div>
            <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow rounded-lg no-max-width  overflow-hidden mt-4 mb-4 !rounded-xl shadow border-white w-full"
                body={<>
                <div className="flex">
                    <img src={Person} className="w-28 h-28 rounded-full object-cover"></img>
                    <div className="pl-2 absolute ml-6 inset-6 mt-14 h-9 bg-green-50 shadow-lg shadow-green-700  w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" stroke-width="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" stroke-width="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                    </svg>
    
                    </div>
      
         
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{94}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>

                    <div className="ml-6 hover:cursor-pointer">
                        <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                        <p className="text-sm mb-0">UI/UX Designer</p>
                        <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                    </div>
                   <div className="ml-auto mt-4"> 
                    <div className="relative items-center flex text-left">
      
        <Button
          type="button"
          className="inline-flex border-[1px] border-[#059669]  justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdownPipeline}
        >
          <p className="mt-2 mb-0 text-[#059669]">Save to pipeline</p>
          <svg
            className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-[#059669]"
            viewBox="0 0 20 20"
            fill="#059669"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      
      {/* {filterOpen &&(
        <ModalBox Modal_Body={ModalBody} Modal_Header={ModalHeader} modal_Close_Class="mt-2.5 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-6" Modal_Close_Func={handleCloseFilter} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-full !rouded-none !w-96 opacity-none overflow-y-auto" ModalTopClass="!items-right !justify-end "/>
      )}
      {saveProject &&(
        <ModalBox Modal_Body={AnotherProject} Modal_Footer={SaveProjectFooter} Modal_Header={ProjectHeader} modal_Header_Text="text-2xl font-[500] mb-0" modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}

      {isProjectCreation &&(
        <ModalBox Modal_Body={<ProjectCreation onClose={handleCloseNewProject}/>}  modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseNewProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )} */}
      
      {isOpen && (
        <div
          className="absolute z-10 w-8/12 mt-2 top-9 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={dropdownRef}
        >
          <div class="p-1 space-y-0.5">
      <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Uncontacted
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Contacted
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Replied
      </a>
      
    </div>
        </div>
      )}
      {isOpenMessage && (
        <div
          className="absolute left-4 z-10 w-10/12 top-11 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={dropdownRef}
        >
          <div class="p-1 space-y-0.5">
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        UnHide Candidate
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Add Note
      </a>
      {/* <a class="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={handleSaveToProject} href="#">
      Save To Another Project
      </a> */}
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Share for Review
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Another Project
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save to PDF
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3  text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Find More People Like This
      </a>
      
    </div>
        </div>
      )}
    
    
                    
                    <Button type="button" className="!bg-gray-100  ml-3 h-9 items-center justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleDropdownMessage}>
                <img src="/image/message.png" className='w-5' />
            </Button>
            <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="p-1 h-5 mb-1 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
            <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
            </svg>
            </button>
            </div>
            </div>






                </div>
                <div className="grid gap-[32px]">
                <div className="mt-4 flex gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-[12px] font-[500]">Experience</p></div>
                  <div>
                    <p className="text-xs opacity-[50%] mb-0 font-[500]">4+ years of experience in UX design</p>
                    <p className="text-black text-xs flex font-[400] mb-0"><span className="text-green-700 font-[700] mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                    <p className="text-black text-xs font-[400] flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                    <p className="text-black text-xs font-[400] flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                  </div>
                </div>
                <div className="flex gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-xs font-[500]">Education</p></div>
                  <div>
                    
                    <p className="text-black flex text-xs mb-0">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                    <p className="text-black flex text-xs mb-0">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
                  </div>
                </div>
                <div className="flex gap-[32px]">
                <div className="w-[80px]">
                  <p className="ml-2 text-xs mb-0 font-[500] text-black">Skills Match</p></div>
                  <div>
                    
                    <p className="flex text-xs text-green-700 mb-0 font-medium">User Research <li className="text-xs ml-2">User-Centered Design Thinking</li><li className="ml-2">Problem-solving</li></p>
                  </div>
                </div>
                <div className="flex mb-4 gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-xs font-[500]">Activity</p></div>
                  <div className="w-[381px] justify-between">
                    
                    <p className="text-black flex text-xs mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                    
                  </div>
                </div>
                </div>
                </>}
                />
            </div>

        </div>
    </>
    );
}
export default HiddenCandidates;