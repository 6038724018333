// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-project-creation {
    box-shadow: 0px 4px 40px 0px #00000014;
    border:none;
    border-radius: 20px;

}
/* Make the scrollable area smooth and hide the scrollbar */
.modal-body-prcr {
    overflow-y: auto;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
    scroll-behavior: smooth;
}

.modal-body-prcr::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome/Safari */
}

/* Fix the footer to the bottom of the modal */
.modal-footer-prcr {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 0 0 20px 20px ;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProjectPipeline/ProjectCreation.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,WAAW;IACX,mBAAmB;;AAEvB;AACA,2DAA2D;AAC3D;IACI,gBAAgB;IAChB,qBAAqB,EAAE,+BAA+B;IACtD,wBAAwB,EAAE,+BAA+B;IACzD,uBAAuB;AAC3B;;AAEA;IACI,aAAa,EAAE,qCAAqC;AACxD;;AAEA,8CAA8C;AAC9C;IACI,gBAAgB;IAChB,SAAS;IACT,sBAAsB;IACtB,aAAa;IACb,0CAA0C;IAC1C,WAAW;IACX,6BAA6B;AACjC","sourcesContent":[".modal-project-creation {\n    box-shadow: 0px 4px 40px 0px #00000014;\n    border:none;\n    border-radius: 20px;\n\n}\n/* Make the scrollable area smooth and hide the scrollbar */\n.modal-body-prcr {\n    overflow-y: auto;\n    scrollbar-width: none; /* Hide scrollbar for Firefox */\n    -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */\n    scroll-behavior: smooth;\n}\n\n.modal-body-prcr::-webkit-scrollbar {\n    display: none; /* Hide scrollbar for Chrome/Safari */\n}\n\n/* Fix the footer to the bottom of the modal */\n.modal-footer-prcr {\n    position: sticky;\n    bottom: 0;\n    background-color: #fff;\n    padding: 1rem;\n    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);\n    z-index: 10;\n    border-radius: 0 0 20px 20px ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
