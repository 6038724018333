import React from 'react';
const RadioInput = (props)=>{
    return (
        <>
            <label className={` ${props.labelClass}`}>
            <input
                type={props.type || 'radio'}
                name={props.name}
                value={props.value}
                checked = {props.checked || false}
                onChange={props.onChange || (()=>{})}
                className={`mr-2 ${props.className || ''}`}
            />
            {props.label}
            <p className={`ml-5 text-xs text-gray-500 ${props.subtextClass}`}>{props.subtext}</p>
            </label>
        </>
    )
}
export default RadioInput;