import React , { useState,useRef,useEffect,useContext} from "react";
import Textinput from "../../elements/TextInput/TextInput";
import TextInputOtp from "../../elements/TextInputOtp/TextInputOtp";
import ImageIcon from "../../elements/ImageIcon/ImageIcon";
import ModalBox from "../../elements/ModalBox/ModalBox";
import PasswordStrength from "../../elements/PasswordStrength/PasswordStrength";
import Button from "../../elements/Button/Button";
import { AuthContext } from "../../../context/AuthContext";

const Profile = () => {
	const [isOpen, set_Is_Open] = useState(false);            //For dropdown
    const [profile_phone, set_Phone] = useState("");         //Phone number validation
    const [showPassword, set_Show_Password] = useState(false);    //Show and hide password
    const [isModalOpen, set_Is_Modal_Open] = useState(false);
    const [showNewPassword, set_New_Show_Password] = useState(false);    //Show and hide password
    const [showConformPassword, set_Conform_Show_Password] = useState(false);
    const [new_password, new_password_input] = useState('');
    const [confirm_password, confirm_password_input] = useState('');
    const [change_password, change_Password_State] = useState(false);
    const [match_password, password_not_match] = useState(false);
    const [isOtpModalOpen, set_Otp_IsModal_Open] = useState(false);
    const input_Otp_Refs = useRef([]);

  const password_Otp = () => set_Otp_IsModal_Open(true);
  const password_Otp_Close = () => set_Otp_IsModal_Open(false);
  const toggle_New_Password_Visibility = () => {                  //Password visibility and hidden
    set_New_Show_Password(!showNewPassword);
  };
  const toggle_Conform_Password_Visibility = () => {                  //Password visibility and hidden
    set_Conform_Show_Password(!showConformPassword);
  };
  const Modal_on_Close = () =>{
    set_Is_Modal_Open(false);
  }
  const profile_change_password = () =>{
   change_Password_State(true);
   if (new_password === confirm_password) {
      password_not_match(false);
      console.log("Inputs match");
      Modal_on_Close();
      password_Otp()
    } else {
      password_not_match(true);
      console.log("Inputs do not match");
    }
  }

  const change_Password = () => set_Is_Modal_Open(true);
  const close_Pass_Modal = () => set_Is_Modal_Open(false);
  const toggle_Password_Visibility = () => {                  //Password visibility and hidden
    set_Show_Password(!showPassword);
  };
  const profile_navbar_dropdown = () => {                   //Dropdown in navbar
    set_Is_Open(!isOpen);
  };

  const profile_phone_no = (e) => {                         //Mobile number validation
    const input = e.target.value.replace(/\D/g, '').slice(0, 10); // Limit to 10 digits for a standard mobile number

    set_Phone(input);
  };
  const oninput_Otp = (e, index) => {         //OTP oninput
    const value = e.target.value;
    if (value && index < input_Otp_Refs.current.length - 1) {
      // Move to the next input if there's a value
      input_Otp_Refs.current[index + 1].focus();
    }
  };

  const handle_Otp_Backspace = (e, index) => {      //backspace for otp
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      input_Otp_Refs.current[index - 1].focus();
    }
  };
  const handle_Otp_Paste = (e) => {                 //Paste the OTP
    const pasteData = e.clipboardData.getData('Text');
    if (pasteData.length === input_Otp_Refs.current.length) {
      pasteData.split('').forEach((char, index) => {
        input_Otp_Refs.current[index].value = char;
      });
      input_Otp_Refs.current[input_Otp_Refs.current.length - 1].focus(); // Focus on last input
      e.preventDefault();
    }
  };
  const eyeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5 text-teal-300"
      fill="none"
      stroke="gray"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"
      />
    </svg>
  );

  const eyeOffIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5 text-teal-300"
      fill="none"
      stroke="gray"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m17.069 6.546 2.684-2.359c.143-.125.32-.187.497-.187.418 0 .75.34.75.75 0 .207-.086.414-.254.562l-16.5 14.501c-.142.126-.319.187-.496.187-.415 0-.75-.334-.75-.75 0-.207.086-.414.253-.562l2.438-2.143c-1.414-1.132-2.627-2.552-3.547-4.028-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 1.815 0 3.536.593 5.071 1.546zm2.319 1.83c.966.943 1.803 2.014 2.474 3.117.092.156.138.332.138.507s-.046.351-.138.507c-2.068 3.403-5.721 6.493-9.864 6.493-1.297 0-2.553-.313-3.729-.849l1.247-1.096c.795.285 1.626.445 2.482.445 3.516 0 6.576-2.622 8.413-5.5-.595-.932-1.318-1.838-2.145-2.637zm-3.434 3.019c.03.197.046.399.046.605 0 2.208-1.792 4-4 4-.384 0-.756-.054-1.107-.156l1.58-1.389c.895-.171 1.621-.821 1.901-1.671zm-.058-3.818c-1.197-.67-2.512-1.077-3.898-1.077-3.465 0-6.533 2.632-8.404 5.5.853 1.308 1.955 2.567 3.231 3.549l1.728-1.519c-.351-.595-.553-1.289-.553-2.03 0-2.208 1.792-4 4-4 .925 0 1.777.315 2.455.843zm-2.6 2.285c-.378-.23-.822-.362-1.296-.362-1.38 0-2.5 1.12-2.5 2.5 0 .36.076.701.213 1.011z"
      />
    </svg>
  );
  
  const [showStrength,setStrengthText]=useState("");
  const [nowText,setChange]=useState("");
  const [strengthFirst,setStrengthFirst]=useState("");
  const [strengthSecond,setStrengthSecond]=useState("");
  const [strengthThird,setStrengthThird]=useState("");
  const [strengthFourth,setStrengthFourth]=useState("");
  const handleChange = (e) => {
    const value = e.target.value;
    setChange(value);
  
    let strengthScore = 0;

    if (value.length === 0) strengthScore = 0;
    if (/[a-z]/.test(value)) strengthScore++;
    if (/[A-Z]/.test(value)) strengthScore++;
    if (/[0-9]/.test(value)) strengthScore++;
    if (/[\W_]/.test(value)) strengthScore++;
  
    console.log("strength: ", strengthScore, "length: ", value.length);
  
    if (strengthScore > 0 && strengthScore <= 1) {
      setStrengthFirst("bg-red-500");
      setStrengthText("Very Weak");
      setStrengthSecond("");
      setStrengthThird("");
      setStrengthFourth("");
    } else if (strengthScore === 2) {
      setStrengthFirst("bg-yellow-500");
      setStrengthSecond("bg-yellow-500");
      setStrengthText("Weak");
      setStrengthThird("");
      setStrengthFourth("");
    } else if (strengthScore === 3) {
      setStrengthFirst("bg-green-500");
      setStrengthSecond("bg-green-500");
      setStrengthThird("bg-green-500");
      setStrengthText("Good");
      setStrengthFourth("");
    } else if (strengthScore === 4) {
      setStrengthFirst("bg-green-500");
      setStrengthSecond("bg-green-500");
      setStrengthThird("bg-green-500");
      setStrengthFourth("bg-green-500");
      setStrengthText("Strong");
    } else {
      setStrengthText("");
      setStrengthFirst("");
      setStrengthSecond("");
      setStrengthThird("");
      setStrengthFourth("");
    }
  
    
  };

  const [showStrengthConfirm,setStrengthTextConfirm]=useState("");
  const [nowTextConfirm,setChangeConfirm]=useState("");
  const [strengthFirstConfirm,setStrengthFirstConfirm]=useState("");
  const [strengthSecondConfirm,setStrengthSecondConfirm]=useState("");
  const [strengthThirdConfirm,setStrengthThirdConfirm]=useState("");
  const [strengthFourthConfirm,setStrengthFourthConfirm]=useState("");
  const handleChangeConfirm = (e) => {
    const value = e.target.value;
    setChangeConfirm(value);
  
    let strengthScoreConfirm = 0;

    if (value.length === 0) strengthScoreConfirm = 0;
    if (/[a-z]/.test(value)) strengthScoreConfirm++;
    if (/[A-Z]/.test(value)) strengthScoreConfirm++;
    if (/[0-9]/.test(value)) strengthScoreConfirm++;
    if (/[\W_]/.test(value)) strengthScoreConfirm++;
  
    console.log("strength: ", strengthScoreConfirm, "length: ", value.length);
  
    if (strengthScoreConfirm > 0 && strengthScoreConfirm <= 1) {
      setStrengthFirstConfirm("bg-red-500");
      setStrengthTextConfirm("Very Weak");
      setStrengthSecondConfirm("");
      setStrengthThirdConfirm("");
      setStrengthFourthConfirm("");
    } else if (strengthScoreConfirm === 2) {
      setStrengthFirstConfirm("bg-yellow-500");
      setStrengthSecondConfirm("bg-yellow-500");
      setStrengthTextConfirm("Weak");
      setStrengthThirdConfirm("");
      setStrengthFourthConfirm("");
    } else if (strengthScoreConfirm === 3) {
      setStrengthFirstConfirm("bg-green-500");
      setStrengthSecondConfirm("bg-green-500");
      setStrengthThirdConfirm("bg-green-500");
      setStrengthTextConfirm("Good");
      setStrengthFourthConfirm("");
    } else if (strengthScoreConfirm === 4) {
      setStrengthFirstConfirm("bg-green-500");
      setStrengthSecondConfirm("bg-green-500");
      setStrengthThirdConfirm("bg-green-500");
      setStrengthFourthConfirm("bg-green-500");
      setStrengthTextConfirm("Strong");
    } else {
      setStrengthTextConfirm("");
      setStrengthFirstConfirm("");
      setStrengthSecondConfirm("");
      setStrengthThirdConfirm("");
      setStrengthFourthConfirm("");
    }
  
    
  };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      onCriteriaChange({ [name]: value });
    };
      const tick_icon =(
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 text-teal-300"
            fill="none"
            stroke="#047857"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
      );
    const image_with_icon = (
      <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" fill="none" stroke="white" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L8.5 20.5l-4 1 1-4L16.732 3.732z"/>
                    </svg>
    );
    
    const Modal_Body = (
      <div>
        <p className="profile_left_side_content_head_text" style={{fontWeight:"400"}}>Change Your Password</p>
        <p className="navbar_content text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <div className='py-2'>
          <Textinput
            type={showNewPassword ? 'text' : 'password'}
            label = "New Password*"
            labelclass="navbar_content"
            name="Password"
            divclass = "relative"
            placeholder="(Minimum 6 characters)"
            className={`profile_sidebar_progress_text border-[2px] w-full rounded-lg focus:ring-0 p-2.5 pr-10 ${change_password && match_password ? 'border-red-600' : 'border-sky-100'}`}
            required = {true}
            svgicon ={showNewPassword ? eyeIcon : eyeOffIcon}
            inputPassPostClass="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            inputPassPostOnclick={toggle_New_Password_Visibility}
            onChange={(e) => new_password_input(e.target.value)}
            OnInput={handleChange}
            value={nowText}
          />
          <PasswordStrength Strength_Box_First={`${strengthFirst}`} Strength_Box_Second={`${strengthSecond}`} Strength_Box_Third={`${strengthThird}`} Strength_Box_Fourth={`${strengthFourth}`} Strength_Hole_Class="flex items-center gap-2 mt-2" first="1"/>
          <p className="profile_sidebar_progress_text text-gray-400 flex float-right mt-1">{showStrength?showStrength:'Password Strength'}</p>
        </div>
        <div className='py-2 mb-4'>
          <Textinput
            type={showConformPassword ? 'text' : 'password'}
            label = "Confirm Password*"
            labelclass="navbar_content"
            name="Confirm_Password"
            divclass = "relative"
            placeholder="(Minimum 6 characters)"
            className={`profile_sidebar_progress_text border-[2px] w-full rounded-lg focus:ring-0 p-2.5 pr-10 ${change_password && match_password ? 'border-red-600' : 'border-sky-100'}`}
            required = {true}
            svgicon ={showConformPassword ? eyeIcon : eyeOffIcon}
            inputPassPostClass="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            inputPassPostOnclick={toggle_Conform_Password_Visibility}
            onChange={(e) => confirm_password_input(e.target.value)}
            OnInput={handleChangeConfirm}
          />
          <PasswordStrength Strength_Box_First={`${strengthFirstConfirm}`} Strength_Box_Second={`${strengthSecondConfirm}`} Strength_Box_Third={`${strengthThirdConfirm}`} Strength_Box_Fourth={`${strengthFourthConfirm}`} Strength_Box_Class="w-3/12 h-1 w-full bg-gray-100 rounded-lg" Strength_Hole_Class="flex items-center gap-2 mt-2" />
          <p className="profile_sidebar_progress_text text-gray-400 flex float-right mt-1">{showStrengthConfirm?showStrengthConfirm:'Password Strength'}</p>
        </div>
        {change_password && match_password && (
        <p className="profile_sidebar_progress_text text-red-600">Password and Confirm Password do not match</p>
      )}
        <div className="flex items-center py-2 w-full">
          <Button className="border-[1px] bg-white py-2 rounded-lg px-4 border-emerald-700 text-emerald-700" onClick={Modal_on_Close}>Cancel</Button>
          <Button className="border-[1px] py-2 rounded-lg px-4 !border-emerald-700 text-white !bg-emerald-700 ml-auto" onClick={profile_change_password}>Save</Button>
          
        </div>
      </div>

    )
    const Modal_Otp_Body = (
          <div>
            <p className="profile_left_side_content_head_text" style={{fontWeight:"400"}}>Change your password</p>
            <p className="navbar_content text-gray-500">We sent a code to <span className="underline">ananyab@company.com</span> </p>
            <div id="otp_container"><center className="mx-auto">
              {[...Array(5)].map((_, index) => (
                <TextInputOtp
                  InputKey={index}
                  type="text"
                  name={`Otp_${index}`}
                  className="border-2 border-sky-100 m-2 w-8 h-12 p-2 text-center rounded-md"
                  required = {true}
                  InputMaxLength="1"
                  InputRef={(el) => (input_Otp_Refs.current[index] = el)}
                  OnInput={(e) => oninput_Otp(e, index)}
                  OnKeyDown={(e) => handle_Otp_Backspace(e, index)}
                  OnPaste={handle_Otp_Paste}
                      />
              ))}
              </center>
            </div>
            <div className="flex items-center py-2 w-full">
              <p className="navbar_content text-gray-500">Didn't get a code? <span className="underline text-emerald-700">Resend code</span></p>
              <Button className="border-[1px] py-2 rounded-lg px-4 !border-emerald-700 text-white !bg-emerald-700 ml-auto" onClick={password_Otp_Close}>Verify</Button>
            </div>
          </div>
    )

    
    return(
        <div>
            <div className='mx-auto mt-4 bg-white w-4/6 shadow px-10 py-10 pb-4 rounded-lg'>
                <p className='mb-0 profile_left_side_content_head_text'>User Profile</p>
                <div className='flex items-center gap-2'>
                    <div className='w-3/6 py-2'>
                    <Textinput
                        type="text"
                        label = "Full Name"
                        labelclass="navbar_content"
                        name="Name"
                        divclass = "relative"
                        svgicon = {tick_icon}
                        placeholder="Enter your Name"
                        className="pr-10 border-sky-100"
                        required = {true}
                    />
                    </div>
                    <div className='w-3/6 py-2'>
                    
                    <ImageIcon svgIcon={image_with_icon} imagepath="/image/notification.png" labelClass="cursor-pointer bg-emerald-700 rounded-full w-5 h-5 p-1 absolute top-0 right-0 transform translate-x-1 -translate-y-1" imageclass="w-16" inputid="profile_image_add"></ImageIcon>

                    </div>
                </div>
                <div className='flex items-center gap-2'>
                    <div className='w-3/6 py-2'>
                        <Textinput
                          type="email"
                          label = "Email ID"
                          labelclass="navbar_content"
                          name="Email"
                          placeholder="ananyab@company.com"
                          className="border-sky-100"
                          required = {true}
                      />
                    </div>
                    <div className='w-3/6 py-2'>
                        <Textinput
                          type="text"
                          label = "Phone Number"
                          labelclass="navbar_content"
                          name="Phone_no"
                          divclass = "flex items-center profile_sidebar_progress_text border-[2px] border-sky-100 w-full rounded-lg focus:ring-0 p-2.5 focus:border-teal-300"
                          placeholder="Enter your mobile number"
                          className="outline-none flex-grow border-0 p-0 rounded-none"
                          inputPre="+91"
                          inputPreClass='text-gray-500 mr-2'
                          required = {true}
                          value={profile_phone} 
                          onChange={profile_phone_no}
                        />
                    </div>
                </div>
                 <div className='flex items-center gap-2'>
                    <div className='w-3/6 py-2'>
                        <Textinput
                          type={showPassword ? 'text' : 'password'}
                          label = "Password*"
                          labelclass="navbar_content"
                          name="Password"
                          divclass = "relative"
                          placeholder="Enter your Password"
                          className="profile_sidebar_progress_text border-[2px] border-sky-100 w-full rounded-lg focus:ring-0 p-2.5 pr-10"
                          required = {true}
                          svgicon ={showPassword ? eyeIcon : eyeOffIcon}
                          inputPassPostClass="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                          inputPassPostOnclick={toggle_Password_Visibility}
                        />
                        
                        <label className="navbar_content text-emerald-700 font-semibold w-full cursor-pointer" onClick={change_Password} style={{textAlign:"right"}}>Change Password</label><br/>
                    </div>
                </div>
                <div className='flex items-center gap-2'>
                    <div className='w-3/6 py-2'>
                        <Textinput
                          type="text"
                          label = "Designation"
                          labelclass="navbar_content"
                          name="Designation"
                          placeholder="Enter Designation"
                          className="border-sky-100"
                          required = {true}
                      />
                    </div>
                    <div className='w-3/6 py-2'>
                        <Textinput
                          type="text"
                          label = "Department"
                          labelclass="navbar_content"
                          name="Department"
                          placeholder="Enter Department"
                          className="border-sky-100"
                          required = {true}
                      />
                    </div>
                </div>
                <label className="navbar_content">Notification Preferences<span className='text-red-600'>*</span></label>
                <p className="profile_sidebar_progress_text mb-0 text-gray-500 flex">
                  <Textinput
                          type="checkbox"
                          className="mr-2 w-3 h-3 border-gray-500"
                          required = {true}
                      />
                Please send me relevant news, personalized offers, and tips on how to get the most out of my job postings. Eeezo may continue to email you for confirmations, application status, and other messages directly relevant to your activities with Eeezo.</p>
                <div className="flex items-center py-2">
                    <Button className="border-[1px] py-2 bg-white rounded-lg px-4 border-emerald-700 text-emerald-700">Cancel</Button>
                    <Button className="!border-none py-2 rounded-lg px-4 border-emerald-700 text-white !bg-emerald-700 ml-auto">Save</Button>
                    
                </div>
            </div>
            {isModalOpen && (
              <div>
                <ModalBox Modal_Body={Modal_Body} Modal_Close_Func={Modal_on_Close} modal_Header_Class="hidden" />
              </div>
            )}
            {isOtpModalOpen && (
              <div>
                  <ModalBox Modal_Body={Modal_Otp_Body} Modal_Close_Func={Modal_on_Close} modal_Header_Class="hidden" />
              </div>
            )}
        </div>
)};
export default Profile;
