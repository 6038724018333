import React from 'react';
const ImageIcon = (props)=>{
    return (
        <>
            <div className={`relative inline-block ${props.ImageClass}`}>
                {props.outersvg}
                <img src={props.imagepath} className={props.imageclass} />
                <label htmlFor={props.inputid} className={props.labelClass}>
                    <div className='mr-auto'>
                    {props.topLabel}
                    </div>
                    {props.svgIcon}
                    {props.insideLabel}
                </label>
                <input onChange={props.OnChange} type="file" id={props.inputid} className='hidden' />
            </div>
        </>
    )
}
export default ImageIcon;