// Basic imports
import React from 'react';
import Logo from '../components/Common/Logo';
import GmailSignIn from '../components/GmailSignIn/GmailSignIn';

const GmailSI = () => {
	return (
		<div className="radial-gradient-bg">
			<div className='ml-4 absolute'><Logo /></div>
			<div className="signup-page">
				<GmailSignIn />
			</div>
		</div>
	);
};

export default GmailSI;
