import React from "react";
import OngoingAddTeam from "../../components/Team/TeamMember";
import Logo from "../../components/Common/Logo";
const OngoingTeamMember = () =>{
    return(
        <div className="radial-gradient-bg">
			<div className="">
				<OngoingAddTeam />
			</div>
		</div>
    );
}
export default OngoingTeamMember