import React from "react";
import Companyoverview from "../../components/CompanyProfile/CompanyOverview"
import Topbar from "../../components/Recruiter/Topbar/Topbar";

const CompanySummary = () =>{
    return(
        <div className="radial-gradient-bg">
            <Topbar/>
            <div className="sign-up">
              <Companyoverview/>
            </div>
        </div>
    );
};
export default CompanySummary;