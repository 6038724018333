import React, { useState, useRef,useEffect, useContext } from "react";
import Button from "../elements/Button/Button";
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import SearchInput from '../elements/SearchInput/SearchInput';

export default function ResumeUpload() {
    var [isUploaded, setIsUploaded] = useState(false);
    var [resumeFiles, setResumeFiles] = useState([]);
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [haveData, setHaveData] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate()


    // for change the page
    var handleUploadClick = () => {
        setIsUploaded(true);
    };

    useEffect(() => {
        getResumeData();
    }, []);

    const getResumeData = async () =>{
        var cid;
        var userId;
        const encryptedMetadata = localStorage.getItem('metadata');
        if (encryptedMetadata){// && encryptedMetadata.data) {
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            if(decryptedMetadata.cid){
                console.log(decryptedMetadata.cid)
                cid = Number(decryptedMetadata.cid);
                userId = Number(decryptedMetadata.uid);
            }
        }
        if(!userId || userId == ""){
            showNotification("User ID Not Stored", "success");
        }
        var formData = {userId: userId,cid:cid};
        // console.log(formData.get("file"));
        setIsLoading(true);
        try {
            const serverURL = process.env.REACT_APP_SERVER_URL;
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(formData), process.env.REACT_APP_SECRETKEY).toString();
            var searchData = { data:encryptedData}
            const response = await fetch(`${serverURL}/company/getResumeData`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  token: token
                },
                body: JSON.stringify(searchData), // Automatically sets the correct Content-Type
            });
            const result = await response.json();
            console.log("Server Response:", result);
            if (!response.ok) {
                throw new Error(errorMessage);
            }
            const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            console.log(decryptedData);
            if(decryptedData.length > 0){
                console.log("nilll")
                setHaveData(true);
            }
            // showNotification(result.message, "success");

            // signUpImageFun(result.files.file[0]['newFilename'])
            // signUpImageNameFun(result.files.file[0]['originalFilename'])
            // console.log(result.files.file[0]['newFilename'])
        } catch (error) {
            console.error("Error uploading file:", error);
            showNotification(error.message, "error");
        }finally {
            setIsLoading(false); // Always hide loader after the request
        }
    } 

    const showNotification = (message, type) => {   //For Notification
        setNotification({ message, type });
        setTimeout(() => {
          setNotification({ message: "", type: "" });
        }, 3000); // Auto-hide after 3 seconds
    };

    // for handle drag and drop
    var handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    var handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            setResumeFiles(Array.from(droppedFiles));
        }
    };

    // save the file using state
    var handleFileInputChange = (e) => {
        var selectedFiles = e.target.files;
        if (selectedFiles.length > 0) {
            setResumeFiles(Array.from(selectedFiles));
        }
        console.log(selectedFiles)
    };

    // trigger the input via click the div
    var handleDivClick = () => {
        document.getElementById("fileInput").click();
    };

    // save resume api calling
    var saveResume = async () => {
        console.log(resumeFiles)
        if (!resumeFiles || resumeFiles.length < 1){ 
            showNotification("Please Select The Resume", "error");
            return;
        }
        var cid;
        var userId;
        const encryptedMetadata = localStorage.getItem('metadata');
        if (encryptedMetadata){// && encryptedMetadata.data) {
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            if(decryptedMetadata.cid){
                console.log(decryptedMetadata.cid)
                cid = decryptedMetadata.cid
                userId = decryptedMetadata.uid
            }
        }
        const formData = new FormData();
        formData.append("file", resumeFiles[0]);
        formData.append("userId", userId);
        formData.append("cid", cid);
        // console.log(formData.get("file"));
        setIsLoading(true);
        try {
            const serverURL = process.env.REACT_APP_SERVER_URL;
            const response = await fetch(`${serverURL}/company/uploadResume`, {
                method: 'POST',
                headers: {
                  // 'Content-Type': 'application/json',
                  token: token
                },
                body: formData, // Automatically sets the correct Content-Type
            });
            const result = await response.json();
            console.log("Server Response:", result);
            if (!response.ok) {
                const errorMessage = result.error?.message || JSON.stringify(result.error);
                throw new Error(errorMessage);
            }
            showNotification(result.message, "success");
            setIsUploaded(false);

            // signUpImageFun(result.files.file[0]['newFilename'])
            // signUpImageNameFun(result.files.file[0]['originalFilename'])
            // console.log(result.files.file[0]['newFilename'])
        } catch (error) {
            console.error("Error uploading file:", error);
            showNotification(error.message, "error");
        }finally {
            setIsLoading(false); // Always hide loader after the request
        }
        
    };
    const navigateUploadResume = () =>{
        setIsUploaded(true)
    }
    const backTODash = () =>{
        setIsUploaded(false)
    }

    return (
        <div className="flex items-center justify-center flex-col gap-4 mx-5 p-5 bg-white bg-opacity-50 rounded-t-2xl h-full">
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Uploading...</span>
                    </div>
                </div>
            )}
            <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />
            
                {!isUploaded ? (
                    !haveData ? (
                <>
                    <div className="flex items-center flex-col justify-center gap-2">
                        <h1 className="plusJakartaSansFont text-3xl text-center font-bold">
                            No resumes here yet!
                        </h1>
                        <p className="w-3/4 plusJakartaSansFont m-0 text-base font-normal text-center text-[#3D3D3D]">
                            Let our AI help you find the perfect candidates.
                            Upload your database to get started.
                        </p>
                    </div>
                    <Button
                        onClick={handleUploadClick}
                        children={"Upload"}
                        className={
                            "text-white font-medium text-lg px-4 py-2 rounded !bg-[#059669] plusJakartaSansFont"
                        }
                    />
                    <img src="./image/ResumeBoxBg.svg" alt="" />
                </>
                ):(
                    <div>
                        <div className='flex justify-end mb-8'>
                            <Button className="border-[1px] !border-green-700 !bg-transparent rounded-lg px-4 py-2 text-green-700" type="button" onClick={navigateUploadResume} children="Add Resumes +" /> 
                        </div>
                        <div className="search-box-container" style={{height:'110px'}}>
                            <SearchInput />
                        </div>
                    </div>
                )
            ) : (
                <>
                    <div className="flex items-center flex-col justify-center gap-2 w-2/4">
                        <h1 className="plusJakartaSansFont text-3xl text-center font-bold">
                            Ready to Discover Talent?
                        </h1>
                        <p className="plusJakartaSansFont m-0 text-base font-normal text-center text-[#3D3D3D]">
                            Upload resumes to dive into a pool of qualified
                            candidates.
                        </p>
                    </div>
                    <div 
                        onClick={handleDivClick}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop} 
                        className="cursor-pointer h-96 border-2 border-dashed border-[#059669] w-3/4 rounded-lg flex items-center justify-center flex-col"
                    >
                        <svg
                            width="79"
                            height="79"
                            viewBox="0 0 79 79"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1599_7728)">
                                <path
                                    d="M46.0039 19.5781H52.5039V26.0781H68.7539C69.6159 26.0781 70.4425 26.4205 71.052 27.03C71.6615 27.6395 72.0039 28.4662 72.0039 29.3281V53.7031L52.5039 42.3281L52.6209 68.5296L59.8456 61.5421L65.6371 71.5781H29.7539C28.8919 71.5781 28.0653 71.2357 27.4558 70.6262C26.8463 70.0167 26.5039 69.1901 26.5039 68.3281V52.0781H20.0039V45.5781H26.5039V29.3281C26.5039 28.4662 26.8463 27.6395 27.4558 27.03C28.0653 26.4205 28.8919 26.0781 29.7539 26.0781H46.0039V19.5781ZM72.0039 56.4266V68.3281C72.0042 68.6668 71.9516 69.0034 71.8479 69.3259L65.4779 58.2954L72.0039 56.4266ZM13.5039 45.5781V52.0781H7.00391V45.5781H13.5039ZM13.5039 32.5781V39.0781H7.00391V32.5781H13.5039ZM13.5039 19.5781V26.0781H7.00391V19.5781H13.5039ZM13.5039 6.57812V13.0781H7.00391V6.57812H13.5039ZM26.5039 6.57812V13.0781H20.0039V6.57812H26.5039ZM39.5039 6.57812V13.0781H33.0039V6.57812H39.5039ZM52.5039 6.57812V13.0781H46.0039V6.57812H52.5039Z"
                                    fill="#059669"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1599_7728">
                                    <rect
                                        width="78"
                                        height="78"
                                        fill="white"
                                        transform="translate(0.503906 0.078125)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <p className="plusJakartaSansFont m-0 text-xl font-bold text-[#3D3D3D]">
                            {resumeFiles && resumeFiles[0] && resumeFiles[0]['name'] ? 
                              (resumeFiles[0]['name']) : 
                              ('Drag and Drop or choose files')
                            }
                        </p>
                        <input
                            type="file"
                            id="fileInput"
                            onChange={handleFileInputChange}
                            className="hidden"
                        />
                    </div>
                    <div className="flex items-center w-3/4">
                        <Button
                            onClick={backTODash}
                            children={"Back"}
                            className={
                                "text-white font-medium text-sm px-2 py-1 rounded !bg-[#059669] plusJakartaSansFont"
                            }
                        />
                        <Button
                            onClick={saveResume}
                            children={"Upload"}
                            className={
                                "text-white font-medium text-sm px-2 py-1 rounded ml-auto !bg-[#059669] plusJakartaSansFont"
                            }
                        />
                    </div>
                </>
            )}
        </div>
    );
    
}