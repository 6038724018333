import React, { useState,useContext, useEffect } from "react";
import Textinput from "../elements/TextInput/TextInput";
import Button from "../elements/Button/Button";
import JobDashCss from "./JobDashboard.css";
import { useNavigate } from 'react-router-dom';
import { useCommonContext } from "../../context/CommonContext";
const JobDashboard = () =>{
    const { jobCreationData, setJobCreationData } = useCommonContext();
    const navigate = useNavigate();
    const [JobTitle, SetJobTitle] = useState("");
    const [warningmsg, setWarningmsg] = useState("");

    const starSvgIcon = (
        <svg className="w-4 h-5 text-teal-300 mr-2"
            fill="none"
            stroke="white"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            
            <path strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M11 6.999c2.395.731 4.27 2.607 4.999 5.001.733-2.395 2.608-4.269 5.001-5-2.393-.731-4.268-2.605-5.001-5-.729 2.394-2.604 4.268-4.999 4.999zm7 7c1.437.438 2.562 1.564 2.999 3.001.44-1.437 1.565-2.562 3.001-3-1.436-.439-2.561-1.563-3.001-3-.437 1.436-1.562 2.561-2.999 2.999zm-6 5.501c1.198.365 2.135 1.303 2.499 2.5.366-1.198 1.304-2.135 2.501-2.5-1.197-.366-2.134-1.302-2.501-2.5-.364 1.197-1.301 2.134-2.499 2.5zm-6.001-12.5c-.875 2.873-3.128 5.125-5.999 6.001 2.876.88 5.124 3.128 6.004 6.004.875-2.874 3.128-5.124 5.996-6.004-2.868-.874-5.121-3.127-6.001-6.001z"/>

        </svg>
    );
    const valueChange = (value) => {
        SetJobTitle(value);
        setWarningmsg("");
    }
    const randomJobId = Math.floor(1000 + Math.random() * 9000); 
    // const mannualJobPost = () =>{
    //     setJobCreationData((prevData) => ({
    //         ...prevData,
    //         jobid: randomJobId.toString(),
    //     }));
    //     localStorage.setItem("jobCreationData", JSON.stringify(jobCreationData));
    //     if(JobTitle === ""){
    //         setWarningmsg("Please enter Job Title to proceed");
    //     } else {
    //         navigate("/NewJobPost");
    //     }
    // }
    useEffect(() => {
        if (jobCreationData) {
          localStorage.setItem("jobCreationData", JSON.stringify(jobCreationData));
        }
      }, [jobCreationData]);
    const mannualJobPost = () => {
        if (JobTitle === "") {
          setWarningmsg("Please enter Job Title to proceed");
          return;
        }
      
        setJobCreationData((prevData) => ({
          ...prevData,
          jobid: randomJobId.toString(),
        }));
      
        setTimeout(() => {
          navigate("/NewJobPost");
        }, 0);
      };
      
      const aiJobPost = () => {
        if (JobTitle === "") {
          setWarningmsg("Please enter Job Title to proceed");
          return;
        }
      
        setJobCreationData((prevData) => ({
          ...prevData,
          jobid: randomJobId.toString(),
        }));
      
        setTimeout(() => {
          navigate("/JobCreation");
        }, 0);
      };
    // const aiJobPost = () =>{
    //     setJobCreationData((prevData) => ({
    //         ...prevData,
    //         jobid: randomJobId.toString(),
    //     }));
    //     localStorage.setItem("jobCreationData", JSON.stringify(jobCreationData));
    //     if(JobTitle === ""){
    //         setWarningmsg("Please enter Job Title to proceed");
    //     } else {
    //         navigate("/JobCreation");
    //     }
    // }

    return(
        <div>
            <div className="flex items-center px-4 mx-4 rounded-2xl py-12 font-family" style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)' }}>
                <div className="mx-auto">
                    <h3 className="text-6xl text-center leading-[80px] font-semibold">Open Doors to Talent</h3>
                    <p className='text-2xl text-gray-600'>Craft a role description that invites the best to join your team.</p>
                    <div className="my-4">
                        <p className="text-md text-black">Job Title <span style={{color:'red'}}>*</span></p>
                        <Textinput onChange={(e) => valueChange(e.target.value)} placeholder="Add your title" labelclass="hidden" className="border-2 focus:border-none border-gray-300 focus:ring-4 focus:ring-green-700 focus:outline-none py-3 focus:shadow-[0_0_20px_4px_rgba(0,255,0,0.5)]" />
                        {warningmsg && <span style={{color:'red'}}>{warningmsg}</span>}
                    </div>
                    <div className="mx-auto flex justify-center gap-4 mb-5">
                        <Button PreSvgIcon={starSvgIcon} children={` Write with AI`} className="text-white px-4 py-2 flex" onClick={aiJobPost} />
                        <Button children="Write On My Own" className="text-white px-4 py-2"  onClick={mannualJobPost} />
                    </div>
                    <div className='mt-3 w-4/6 justify-center mx-auto'>
                        <p className="text-sm text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor</p>
                        <p className="text-sm text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default JobDashboard