import React ,{useRef, useState,useEffect}from "react";
import Card from "../elements/Card/Card";
import Button from "../elements/Button/Button";
import Textinput from "../elements/TextInput/TextInput";
import Person from "../../assets/Images/person.jpg"
const RecruiterSearch = () =>{
    const dropdownRef=useRef(null);
    const [isOpen, setIsOpen] = useState(false);
      
        const toggleDropdownPipeline = () => {
          setIsOpen((prev) => !prev);
        };
    const [isOpenMessage, setIsOpenMessage] = useState(false);
      
        const toggleDropdownMessage = () => {
          setIsOpenMessage((prev) => !prev);
        };
    const Locations=[
        "chennai",
        "kolkata",
        "Banglore",
        "kerala",
        "coimbatore",
        "Delhi"
      ]
      const assessments=[
        "Project Management",
        "Testing",
        "Programming",
        "Code Development",
        "Web Development",
        "System Design",
      ]
      const Companies=[
        "Amazon",
        "Amazon Logistics",
        "TCS",
        "ZOHO",
        "WIPRO",
        "Atlassian",
        "Google"
      ]
    
      const Keywords=[
        "Ananya (17)",
        "Jhon Doe",
        "micheal",
        "antony",
        "Raj",
        "David"
      ]
      const Degrees =[
        "B.Tech",
        "Master Degree",
        "Post Graduate",
        "Diploma"
      ]
      const Recruiting =[
        "Planning",
        "Searching",
        "Evaluation",
        "Progress"
      ]
      const [projects, setProjects] = useState([
        {
          id: 1,
          createdBy: "Ananya",
          postedDate: "24/09/2024",
          workplace: "Alchemy",
          jobTitle: "Senior UX Designer",
          status: "Active",
          pipelineCount: 17,
          applicants: { total: 15, new: 5 },
        },
        {
          id: 2,
          createdBy: "Jhon Doe",
          postedDate: "20/09/2024",
          workplace: "NovaByte Solutions",
          jobTitle: "Frontend Developer",
          status: "Inactive",
          pipelineCount: 10,
          applicants: { total: 8, new: 3 },
        },
        {
          id: 3,
          createdBy: "Sarah Connor",
          postedDate: "18/09/2024",
          workplace: "ZenithSoft Labs",
          jobTitle: "Backend Engineer",
          status: "Active",
          pipelineCount: 12,
          applicants: { total: 6, new: 2 },
        },
      ]);
      const[seeLocations,showMoreLocations]=useState(false)
      const toggleState = () => {
        showMoreLocations((prevState) => !prevState);
        
      };
      const[seeAssessments,showMoreAssessments]=useState(false)
      const toggleAssesment = () => {
        showMoreAssessments((prevState) => !prevState);
        
      };
      const[seeCompanies,showMoreCompanies]=useState(false)
      const toggleCompany = () => {
        showMoreCompanies((prevState) => !prevState);
        
      };
      const[seeKeywords,showMoreKeywords]=useState(false)
      const toggleKeywords = () => {
        showMoreKeywords((prevState) => !prevState);
        
      };
      const[seeDegree,showMoreDegrees]=useState(false)
      const toggleDegree = () => {
        showMoreDegrees((prevState) => !prevState);
        
      };
      const[seeRecruiting,showMoreRecruiting]=useState(false)
      const toggleRecruiting = () => {
        showMoreRecruiting((prevState) => !prevState);
        
      };
      const handleClickOutside = (event) => {
        if ((isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target))||(isOpenMessage && dropdownRef.current && !dropdownRef.current.contains(event.target))) {
            setIsOpen(false);
            setIsOpenMessage(false)
        }
        
    };
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen,isOpenMessage]);
    return(
        <>
            <div className="flex !w-[75%] gap-2 p-3 bg-white bg-opacity-50 rounded-xl">
            <div className="bg-white w-[30%] p-2  grid gap-[22px]">
                <div className="flex px-4"><p className="text-[21px] font-[700] mb-0">Filters</p>
                </div>
                <Card CardFooterClass="px-6 py-2 bg-gray-100 justify-end gap-2 "  CardClass="min-w-md mx-auto  bg-white shadow-md rounded-lg overflow-hidden w-11/12   !rounded-xl"
                    body={<>
                    <Textinput placeholder="Search for a Project" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
                    <div className="absolute h-10 inset-y-[32%]  flex pt-1  pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#9effef"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div>
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden w-11/12   !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Location</p>
                    {
                        Locations.slice(0,3).map((location) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={location}
                            postlabelclass="ml-2"
                            key={location}
                            />
                        ))
                    }
                    
                    {seeLocations &&
                    Locations.slice(3).map((location,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={location}
                        postlabelclass="ml-2"
                        key={location}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleState}>view all...</p>
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden w-11/12   !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Skills and assessments</p>
                    {
                        assessments.slice(0,4).map((assessments) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={assessments}
                            postlabelclass="ml-2"
                            key={assessments}
                            />
                        ))
                    }
                    
                    {seeAssessments &&
                    assessments.slice(4).map((assessments,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={assessments}
                        postlabelclass="ml-2"
                        key={assessments}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleAssesment}>view all...</p>
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden w-11/12   !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Companies</p>
                    {
                        Companies.slice(0,2).map((company) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={company}
                            postlabelclass="ml-2"
                            key={company}
                            />
                        ))
                    }
                    
                    {seeCompanies &&
                    Companies.slice(2).map((company,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={company}
                        postlabelclass="ml-2"
                        key={company}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleCompany}>view all...</p>
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden w-11/12   !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Keywords</p>
                    {
                        Keywords.slice(0,2).map((keyword) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={keyword}
                            postlabelclass="ml-2"
                            key={keyword}
                            />
                        ))
                    }
                    
                    {seeKeywords &&
                    Keywords.slice(2).map((keyword,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={keyword}
                        postlabelclass="ml-2"
                        key={keyword}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleKeywords}>view all...</p>
                    
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden w-11/12   !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Degrees</p>
                    {
                        Degrees.slice(0,3).map((degree) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={degree}
                            postlabelclass="ml-2"
                            key={degree}
                            />
                        ))
                    }
                    
                    {seeDegree &&
                    Degrees.slice(3).map((degree,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={degree}
                        postlabelclass="ml-2"
                        key={degree}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleDegree}>view all...</p>
                    
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden w-11/12   !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Recruiting</p>
                    {
                        Recruiting.slice(0,3).map((degree) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={degree}
                            postlabelclass="ml-2"
                            key={degree}
                            />
                        ))
                    }
                    
                    {seeRecruiting &&
                    Recruiting.slice(3).map((degree,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={degree}
                        postlabelclass="ml-2"
                        key={degree}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleRecruiting}>view all...</p>
                    </>}
                />
                
            </div>
            <div className="w-[70%]">
                    <div className="flex gap-[16px]">
                        <div className="w-[120px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`18`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Open to Work</p>
                        </div>
                        <div className="w-[120px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`21`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Active Talent</p>
                        </div>
                        <div className="w-[120px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[14px] pb-[14px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`07`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto text-center">Rediscovered Candidates</p>
                        </div>
                        <div className="w-[120px] h-[100px] bg-[#F2F9F9] rounded-[17px] pt-[20px] pb-[20px] pl-[16px] pr-[16px] grid gap-[4px]">
                            <p className="mb-0 text-[27px] font-[700] mx-auto items-center text-[#059669]">{`18`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto">Open Jobs</p>
                        </div>
                        <div className="w-[132px] h-[100px] bg-[#F2F9F9] rounded-[17px] relative pt-[14px] pb-[14px] pl-[10px] pr-[10px] grid gap-[4px]">
                            <p className="mb-0 text-[26px] font-[700] mx-auto items-center text-[#059669]">{`1`}</p>
                            <p className="mb-0 text-[11px] font-[500] text-[#059669] mx-auto text-center ">Engaged with your talent brand</p>
                        </div>
                    </div>
                    <div>
                        <p className="mb-0 text-xl mt-4 font-[700]">Recruiter Search <span className="text-xs font-[400] text-[#747474]">1.6k results</span></p>
                    </div>
                    <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow rounded-lg no-max-width  overflow-hidden mt-4 mb-4 !rounded-xl shadow border-white w-full"
                body={<>
                <div className="flex">
                    <img src={Person} className="w-28 h-28 rounded-full object-cover"></img>
                    <div className="pl-2 absolute ml-6 inset-6 mt-14 h-9 bg-green-50 shadow-lg shadow-green-700  w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" stroke-width="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" stroke-width="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                    </svg>
    
                    </div>
      
         
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{94}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>

                    <div className="ml-6">
                        <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                        <p className="text-sm mb-0">UI/UX Designer</p>
                        <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                    </div>
                    <div>
                    <div className="relative inline-block text-left">
      <div className="ml-24 pl-3 mt-4">
        <Button
          type="button"
          className="inline-flex border-[1px] border-[#059669] w-full justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdownPipeline}
        >
          <p className="mt-2 mb-0 text-[#059669]">Save to pipeline</p>
          <svg
            className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-[#059669]"
            viewBox="0 0 20 20"
            fill="#059669"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      </div>
      {/* {filterOpen &&(
        <ModalBox Modal_Body={ModalBody} Modal_Header={ModalHeader} modal_Close_Class="mt-2.5 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-6" Modal_Close_Func={handleCloseFilter} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-full !rouded-none !w-96 opacity-none overflow-y-auto" ModalTopClass="!items-right !justify-end "/>
      )}
      {saveProject &&(
        <ModalBox Modal_Body={AnotherProject} Modal_Footer={SaveProjectFooter} Modal_Header={ProjectHeader} modal_Header_Text="text-2xl font-[500] mb-0" modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}

      {isProjectCreation &&(
        <ModalBox Modal_Body={<ProjectCreation onClose={handleCloseNewProject}/>}  modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseNewProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )} */}
      
      {isOpen && (
        <div
          className="absolute left-24  z-10 w-8/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={dropdownRef}
        >
          <div class="p-1 space-y-0.5">
      <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Uncontacted
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Contacted
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Replied
      </a>
      
    </div>
        </div>
      )}
      {isOpenMessage && (
        <div
          className="absolute left-24  z-10 w-10/12 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={dropdownRef}
        >
          <div class="p-1 space-y-0.5">
      <a class="flex items-center gap-x-3.5  py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Reject Candidate
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Hide Candidate
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Add Note
      </a>
      {/* <a class="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={handleSaveToProject} href="#">
      Save To Another Project
      </a> */}
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Share for Review
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Another Project
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save to PDF
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Find More People Like This
      </a>
      
    </div>
        </div>
      )}
    </div>
    
                    </div>
                    <Button type="button" className="!bg-gray-100 inline-flex ml-3 h-9 mt-7 justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleDropdownMessage}>
                <img src="/image/message.png" className='w-5' />
            </Button>
            <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-5 mt-9  text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
<svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
<path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
</svg>
</button>






                </div>
                <div className="grid gap-[32px]">
                <div className="mt-4 flex gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-[12px] font-[500]">Experience</p></div>
                  <div>
                    <p className="text-xs opacity-[50%] mb-0 font-[500]">4+ years of experience in UX design</p>
                    <p className="text-black text-xs flex font-[400] mb-0"><span className="text-green-700 font-[700] mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                    <p className="text-black text-xs font-[400] flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                    <p className="text-black text-xs font-[400] flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                  </div>
                </div>
                <div className="flex gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-xs font-[500]">Education</p></div>
                  <div>
                    
                    <p className="text-black flex text-xs mb-0">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                    <p className="text-black flex text-xs mb-0">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
                  </div>
                </div>
                <div className="flex gap-[32px]">
                <div className="w-[80px]">
                  <p className="ml-2 text-xs mb-0 font-[500] text-black">Skills Match</p></div>
                  <div>
                    
                    <p className="flex text-xs text-green-700 mb-0 font-medium">User Research <li className="text-xs ml-2">User-Centered Design Thinking</li><li className="ml-2">Problem-solving</li></p>
                  </div>
                </div>
                <div className="flex mb-4 gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-xs font-[500]">Activity</p></div>
                  <div className="w-[381px] justify-between">
                    
                    <p className="text-black flex text-xs mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                    
                  </div>
                </div>
                </div>
                </>}
                />
            </div>
            
            </div>
        </>
    )
}
export default RecruiterSearch;