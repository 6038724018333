import React from 'react';
const TextInputOtp = (props)=>{
    return (
        <>
            <label className={`mb-2 ${props.labelclass || ''}`}>{props.label || ''} </label>
                <input
                    type={props.type || 'text'}
                    key={props.InputKey} 
                    name={props.name || ''}
                    value={props.value}
                    onChange={props.onChange || (() => {})} 
                    placeholder={props.placeholder || ''}
                    className={`profile_sidebar_progress_text border-[2px] rounded-lg focus:ring-0 p-2.5 ${props.className || ''}`}
                    required={props.required || false}
                    maxLength={props.InputMaxLength}
                    ref={props.InputRef}
                    onInput={props.OnInput}
                    onKeyDown={props.OnKeyDown}
                    onPaste={props.OnPaste}
                />
        </>
    )
}
export default TextInputOtp;