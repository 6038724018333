import React, { useState, useEffect, useContext } from "react";
import Swal from 'sweetalert2';
import Textinput from "../elements/TextInput/TextInput";
import Button from "../elements/Button/Button";
import Jobscreen from "./JobScreen";
import Jobscreening from "./JobScreening";
import JobTeam from "./JobTeam";
import { useNavigate } from "react-router-dom";
import { useCommonContext } from "../../context/CommonContext";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from "crypto-js";
const JobPost = () =>{
  const { token } = useContext(AuthContext);
  const { jobCreationData, setValidationErrors, setJobCreationData } = useCommonContext();
    const encryptedMetadata = localStorage.getItem('metadata');

    const [nextScreen, setNextScreen] = useState(1);
    const navigate = useNavigate();
    // useEffect(() => {
        
    // }, [jobCreationData]);
    
    // Retrieve jobCreationData from localStorage during initialization
    useEffect(() => {
        const savedFormData = localStorage.getItem("jobCreationData");
        if (savedFormData) {
            setJobCreationData(JSON.parse(savedFormData));
        }
    }, []);

    const sendData = async (data) => {
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRETKEY).toString();
      const encryptjson = { data: encryptedData };
    
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/job/createJob`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: token,
          },
          body: JSON.stringify(encryptjson),
        });
    
        if (response.ok) {
          localStorage.removeItem("jobCreationData");
          Swal.fire({
            icon: 'success',
            title: 'Job successfully added to verification!',
            text: 'Once verified, it will go live.',
            // showConfirmButton: false,
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/Dashboard';
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an issue adding the job. Please try again.',
          });
        }
      } catch (error) {
        console.log('Error adding JD to db:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred. Please try again later.',
        });
      }
    };

  const handleSave = () => {
    const errors = {};
    const firstStageJC = ['jobTitle', 'company', 'workplaceType', 'jobLocation', 'jobType', 'description','selectedSkills']
    const secondStageJC = ['questions']
  
    Object.keys(jobCreationData).forEach((key) => {
      if(nextScreen === 1){
        if (firstStageJC.includes(key)) {
          if (!jobCreationData[key] || (Array.isArray(jobCreationData[key]) && jobCreationData[key].length === 0)) {
              errors[key] = true; 
          }
        }
      }else if(nextScreen === 2){
          if (!jobCreationData['questions'] || Array.isArray(jobCreationData['questions']) && jobCreationData['questions'].length === 0) {
            errors['questions'] = true;
          }else {
            jobCreationData.questions.forEach((question, index) => {
              if (!question.question_desc || question.question_desc.trim() === "") {
                errors[index] = true;
              }
            });
          }
      }else if(nextScreen === 3){
        if (!jobCreationData['collaborators'] || Array.isArray(jobCreationData['collaborators']) && jobCreationData['collaborators'].length === 0) {
          errors['collaborators'] = true;
        }
      }
    });
    localStorage.setItem("jobCreationData", JSON.stringify(jobCreationData));
    if (encryptedMetadata){
      const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
      setJobCreationData((prev) => ({
      ...prev,
      company_id: decryptedMetadata.cid
    }))
  }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      console.log("Validation failed:", errors);
      return;
    }
  
    setValidationErrors({});
    
    if(nextScreen === 3){
      console.log(jobCreationData);
      sendData(jobCreationData);
    }
    setNextScreen((prevScreen) => (prevScreen < 3 ? prevScreen + 1 : prevScreen));
    console.log("next: ", nextScreen);
  };
  const handleBack = () => {
    if(nextScreen === 1){
      navigate('/JobDashboard')
    }else{
      setNextScreen((prevScreen) => prevScreen>1?prevScreen-1:prevScreen);
      console.log("next: ", nextScreen);
    }
  };
  const handleJobPrevie = () => {
    localStorage.removeItem("jobCreationData");
  }

    return(
        <div>
            <div className="ml-12 mt-2">
                <p className="font-[700] text-[32px] mb-0">Post a new job</p>
            
            <div className="flex mt-3">
                <p className="pl-4 pr-5 pt-2.5 pb-2 rounded-full text-white bg-green-700">1</p>
                <div className="w-[4.5%] border-b-2 border-gray-400 h-6"></div>
                <p className={`pl-4 pr-4 pt-2 pb-2 rounded-full  border-2 border-green-700 ${nextScreen===3?"bg-green-700 text-white":"bg-teal-50 text-green-900"}`}>2</p>
                <div className="w-[4.5%] border-b-2 border-gray-400 h-6"></div>
                <p className="pl-4 pr-5 pt-2.5 pb-2 rounded-full text-green-900  bg-teal-50">3</p>
            </div>
            </div>
            <div className="w-[94.5%] bg-white bg-opacity-50 ml-12 mt-4 rounded-xl pr-[5%]">
            {nextScreen === 1 ? <Jobscreen /> : null}
            {nextScreen === 2 ? <Jobscreening /> : null}
            {nextScreen === 3 ? <JobTeam /> : null}
            <div className="flex mt-[32px] pb-10 ml-7">
                <div>
                <Button className="bg-white border-[1px] border-green-700 font-[500] text-green-700 w-[71px] h-[40px] p-[5px] rounded-[8px]" onClick={ ()=>handleBack()}>Back</Button>
                </div>
                <div className="ml-auto">
                {nextScreen === 3 && <Button onClick={() => {handleJobPrevie()}} className="bg-white border-[1px]  border-green-700 font-[500] w-[90px] text-green-800 h-[40px] p-[5px] rounded-[8px]">Preview</Button>}
                <Button className="text-white ml-[14px] h-[40px] font-[500] p-[8px] rounded-[8px]" onClick={ ()=>handleSave()}>{nextScreen === 3 ? "Submit For Approval": "Save & Proceed"}</Button>
                </div>
            </div>
            </div>
        </div>
    );
}
export default JobPost