import React from "react";
import ResumeBox from "../../components/ResumeBox/ResumeBox";
import Topbar from '../../components/Recruiter/Topbar/Topbar';

const ResumeBoxPage = () =>{
    return(
        <div className="radial-gradient-bg">
            <Topbar />
            <ResumeBox />
        </div>
    )
}
export default ResumeBoxPage;