import React, { useRef, useState,useEffect } from "react";
import Topbar from "../Recruiter/Topbar/Topbar";
// import ProjectsBar from "../elements/ProjectSidebar/ProjectSideBar";
import Card from "../elements/Card/Card";
import Textinput from "../elements/TextInput/TextInput";
import Person from "../../assets/Images/person.jpg"
import Button from "../elements/Button/Button";
import ModalBox from "../elements/ModalBox/ModalBox";
import RadioInput from "../elements/RadioInput/RadioInput";
import ProjectCreation from "./ProjectCreation";
const Pipeline = () =>{
    const dropdownRef=useRef(null);
    const [candidateStatus,setCandidateStatus]=useState("All");
    const handleStatus=(status)=>{
        setCandidateStatus(status)
    }

    const [isProjectCreation,setProjectCreation]=useState('');
    const handleProjectCreation= () =>{
          handleCloseProject();
          setProjectCreation((prev)=>!prev);
    }
    
        const [isOpen, setIsOpen] = useState(false);
      
        const toggleDropdownPipeline = () => {
          setIsOpen((prev) => !prev);
        };

        const [saveProject, setSaveProject] = useState(false);
      
        const handleSaveToProject = () => {
          setSaveProject((prev) => !prev);
        };
        const [isOpenMessage, setIsOpenMessage] = useState(false);
      
        const toggleDropdownMessage = () => {
          setIsOpenMessage((prev) => !prev);
        };
        const [filterOpen, setfilter]=useState(false);
        const handleAllFilters = () =>{
          setfilter(true)
        }
        const handleCloseFilter =()=>{
          setfilter(false)
        }
        const handleCloseProject = () =>{
          setSaveProject(false);
        }
        const handleCloseNewProject = () =>{
          setProjectCreation(false);
        }

        const handleClickOutside = (event) => {
          if ((isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target))||(isOpenMessage && dropdownRef.current && !dropdownRef.current.contains(event.target))) {
              setIsOpen(false);
              setIsOpenMessage(false)
          }
          
      };
      
      useEffect(() => {
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
              document.removeEventListener("mousedown", handleClickOutside);
          };
      }, [isOpen,isOpenMessage]);
    const SearchFront=(<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M17.5 17.5007L13.1692 13.1698M13.1692 13.1698C14.3414 11.9977 14.9999 10.4079 14.9999 8.75023C14.9999 7.09257 14.3414 5.5028 13.1692 4.33065C11.9971 3.1585 10.4073 2.5 8.74962 2.5C7.09196 2.5 5.50219 3.1585 4.33004 4.33065C3.15789 5.5028 2.49939 7.09257 2.49939 8.75023C2.49939 10.4079 3.15789 11.9977 4.33004 13.1698C5.50219 14.342 7.09196 15.0005 8.74962 15.0005C10.4073 15.0005 11.9971 14.342 13.1692 13.1698Z" stroke="black" stroke-opacity="0.3" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>);

    const AnotherProject=(
      <div>
          <Textinput placeholder="Choose a project" inputPre={SearchFront} inputPreClass="ml-1" className="w-full outline-none flex-grow border-0  text-sm font-[400]" label="Project Name" divclass="flex items-center rounded-[8px] profile_sidebar_progress_text border-[1px]"/>
      </div>
    );
    const SaveProjectFooter=(
      <div className="flex items-center">
          <div className="">
            <p className="mb-0 text-base font-[700] text-[#059669] hover:cursor-pointer" onClick={handleProjectCreation}>Create A New Project</p>
          </div>
          <div className="ml-auto flex gap-4">
            <Button className="text-[#059669] bg-white text-base font-[500] border-[#059669] w-[87px] h-[40px] rounded-[8px]">Cancel</Button>
            <Button className="text-white bg-[#059669] text-base font-[500] w-[70px] h-[40px] rounded-[8px]">Save</Button>
          </div>
      </div>
    )
    const ProjectHeader=(
      <>
        Save To Another Project
      </>
    );
    const ModalBody=(
    <div>
     <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <Textinput placeholder="Search for a Project" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Job Titles</p>
        <Textinput placeholder="Search for a Project" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Location</p>
        <div>
        <RadioInput type="checkbox" label="Banglore" subtextClass="hidden" className="mt-3 text-lg"/></div>
        <div><RadioInput type="checkbox" label="Mumbai" subtextClass="hidden" className="mt-2"/></div>
        <div><RadioInput type="checkbox" label="Kerala" subtextClass="hidden" className="mt-2"/>
        </div>
        <p className="text-xs text-green-600 mt-2">View All</p>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Skills and Assessments</p>
        <div>
        <RadioInput type="checkbox" label="Project Management" subtextClass="hidden" className="mt-3 text-lg"/></div>
        <div><RadioInput type="checkbox" label="Testing" subtextClass="hidden" className="mt-2"/></div>
        <div><RadioInput type="checkbox" label="Programming" subtextClass="hidden" className="mt-2"/>
        </div>
        <div><RadioInput type="checkbox" label="Code Development" subtextClass="hidden" className="mt-2"/>
        </div>
        <p className="text-xs text-green-600 mt-2">View All</p>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Companies</p>
        <div>
        <RadioInput type="checkbox" label="Amazon" subtextClass="hidden" className="mt-2 text-lg"/></div>
        <div><RadioInput type="checkbox" label="Amazon Logistics" subtextClass="hidden" className="mt-2"/></div>
        
        <p className="text-xs text-green-600 mt-2">View All</p>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Keywords</p>
        <div>
        <RadioInput type="checkbox" label="Ananya (17)" subtextClass="hidden" className="mt-2 text-lg"/></div>
        <div><RadioInput type="checkbox" label="Jhon Doe" subtextClass="hidden" className="mt-2"/></div>
        
        <p className="text-xs text-green-600 mt-2">View All</p>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Degrees</p>
        <div>
        <RadioInput type="checkbox" label="B.Tech" subtextClass="hidden" className="mt-2 text-lg"/></div>
        <div><RadioInput type="checkbox" label="Master Degree" subtextClass="hidden" className="mt-2"/></div>
        <div><RadioInput type="checkbox" label="Post Graduate" subtextClass="hidden" className="mt-2"/></div>
        
        <p className="text-xs text-green-600 mt-2">View All</p>
        </>}
      />
      <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12 p-2 mt-7 !rounded-xl"
        body={<>
        <p className="mb-0 font-medium">Recruiting</p>
        <div>
        <RadioInput type="checkbox" label="Planning" subtextClass="hidden" className="mt-2 text-lg"/></div>
        <div><RadioInput type="checkbox" label="Searching" subtextClass="hidden" className="mt-2"/></div>
        <div><RadioInput type="checkbox" label="Evaluation" subtextClass="hidden" className="mt-2"/></div>
        <p className="text-xs text-green-600 mt-2">View All</p>
        </>}
      />
      <div className="absolute h-10 inset-y-64 ml-10 flex pt-1.5  pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#9effef"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div>
                <div className="absolute h-10 inset-y-28 ml-10 flex pt-1.5  pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#9effef"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div>
    </div>);


    const ModalHeader=(
      <p className="text-black font-medium ml-4 mt-1">All Filters</p>
      
    );
    
    return(
       
            <div className="mt-2 pt-4 pl-4 w-[80%] flex bg-white bg-opacity-50  pt-2 mr-2 rounded-xl">
                
                <div className="">
                    <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white !shadow-md rounded-lg overflow-hidden border-white" CardBodyClass="grid gap-2.5"
                    body={
                        <>
                        <p className={`mb-0 hover:cursor-pointer ${candidateStatus=="All"?"text-green-600 font-medium":"text-black"}`} onClick={()=>handleStatus("All")}>All Candidates<span className="ml-20 pl-2.5 ">19</span></p>
                        <p className={`mb-0 hover:cursor-pointer ${candidateStatus=="Uncontacted"?"text-green-600 font-medium":"text-black"}`} onClick={()=>handleStatus("Uncontacted")}>Uncontacted<span className="ml-24 pl-2">11</span></p>
                        <p className={`mb-0 hover:cursor-pointer ${candidateStatus=="Contacted"?"text-green-600 font-medium":"text-black"}`} onClick={()=>handleStatus("Contacted")}>Contacted<span className="ml-28 pl-2.5">07</span></p>
                        <p className={`mb-0 hover:cursor-pointer ${candidateStatus=="Replied"?"text-green-600 font-medium":"text-black"}`} onClick={()=>handleStatus("Replied")}>Replied<span className="ml-32  pl-3.5">01</span></p>
                        </>
                        }
                    />
                    <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden border-white mt-4"
                    body={<>
                    <p className="mt-2 font-medium">Archieved Candidates<span className="ml-10">05</span></p></>}/>
                </div>
                <div className="w-8/12 ml-8 rounded-xl">
                <div className="flex w-full">
                <div className="w-9/12 relative">
                <Textinput placeholder="Search Candidates" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/></div>
                <p className="mb-0 mt-2 ml-20 text-green-700 font-medium hover:cursor-pointer" onClick={handleAllFilters}>All Filters</p>
                </div>
                <div className="absolute h-10 inset-y-28 flex pt-[18px]  pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#9effef"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div>
                {/* <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow rounded-lg no-max-width  overflow-hidden mt-4 mb-4 !rounded-xl shadow border-white w-full"
                body={<>
                <div className="flex">
                    <img src={Person} className="w-28 h-28 rounded-full object-cover"></img>
                    <div className="pl-2 absolute ml-6 inset-6 mt-14 h-9 bg-green-50 shadow-lg shadow-green-700  w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" stroke-width="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" stroke-width="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                    </svg>
    
                    </div>
      
         
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{94}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>

                    <div className="ml-6">
                        <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                        <p className="text-sm mb-0">UI/UX Designer</p>
                        <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                    </div>
                    <div>
                    <div className="relative inline-block text-left">
      <div className="ml-20 pl-3 mt-4">
        <Button
          type="button"
          className="inline-flex border-[#059669] w-full justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdownPipeline}
        >
          <p className="mt-2 mb-0 text-green-600">Save to pipeline</p>
          <svg
            className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
            viewBox="0 0 20 20"
            fill="green"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      </div>
      {filterOpen &&(
        <ModalBox Modal_Body={ModalBody} Modal_Header={ModalHeader} modal_Close_Class="mt-2.5 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-6" Modal_Close_Func={handleCloseFilter} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-full !rouded-none !w-96 opacity-none overflow-y-auto" ModalTopClass="!items-right !justify-end "/>
      )}
      {saveProject &&(
        <ModalBox Modal_Body={AnotherProject} Modal_Footer={SaveProjectFooter} Modal_Header={ProjectHeader} modal_Header_Text="text-2xl font-[500] mb-0" modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}

      {isProjectCreation &&(
        <ModalBox Modal_Body={<ProjectCreation onClose={handleCloseNewProject}/>}  modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseNewProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}
      
      {isOpen && (
        <div
          className="absolute left-24  z-10 w-8/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div class="p-1 space-y-0.5">
      <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Uncontacted
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Contacted
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Replied
      </a>
      
    </div>
        </div>
      )}
      {isOpenMessage && (
        <div
          className="absolute left-24  z-10 w-10/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div class="p-1 space-y-0.5">
      <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Archieve Candidate
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Add Note
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Share For Review
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={handleSaveToProject} href="#">
      Save To Another Project
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Pdf
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Another Project
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Find More People Like This
      </a>
      
    </div>
        </div>
      )}
    </div>
    
                    </div>
                    <Button type="button" className="!bg-gray-100 inline-flex ml-3 h-9 mt-7 justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleDropdownMessage}>
                <img src="/image/message.png" className='w-5' />
            </Button>
            <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-5 mt-9  text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
<svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
<path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
</svg>
</button>






                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Experience</p>
                  <div className="ml-14">
                    <p className="text-xs mb-0">4+ years of experience in UX design</p>
                    <p className="text-black text-sm flex mb-0"><span className="text-green-700 font-medium mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                    <p className="text-black text-sm flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                    <p className="text-black text-sm flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                  </div>
                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Education</p>
                  <div className="ml-16">
                    
                    <p className="text-black flex text-sm mb-0">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                    <p className="text-black flex text-sm mb-0">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
                  </div>
                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Skills Match</p>
                  <div className="ml-12">
                    
                    <p className="flex text-sm text-green-700 mb-0 font-medium">User Research <li className="text-sm ml-2">User-Centered Design Thinking</li><li className="ml-2">Problem-solving</li></p>
                  </div>
                </div>
                <div className="mt-1 flex mb-4">
                  <p className="ml-2 text-black">Activity</p>
                  <div className="ml-16">
                    
                    <p className="text-black flex text-sm ml-4 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                    
                  </div>
                </div>
                </>}
                /> */}
                <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow rounded-lg no-max-width  overflow-hidden mt-4 mb-4 !rounded-xl shadow border-white w-full"
                body={<>
                <div className="flex">
                    <img src={Person} className="w-28 h-28 rounded-full object-cover"></img>
                    <div className="pl-2 absolute ml-6 inset-6 mt-14 h-9 bg-green-50 shadow-lg shadow-green-700  w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" stroke-width="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" stroke-width="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                    </svg>
    
                    </div>
      
         
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{94}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>

                    <div className="ml-6 hover:cursor-pointer">
                        <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                        <p className="text-sm mb-0">UI/UX Designer</p>
                        <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                    </div>
                   <div className="ml-auto mt-4"> 
                    <div className="relative items-center flex text-left">
      
        <Button
          type="button"
          className="inline-flex border-[1px] border-[#059669]  justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdownPipeline}
        >
          <p className="mt-2 mb-0 text-[#059669]">Save to pipeline</p>
          <svg
            className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-[#059669]"
            viewBox="0 0 20 20"
            fill="#059669"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      
      {filterOpen &&(
        <ModalBox Modal_Body={ModalBody} Modal_Header={ModalHeader} modal_Close_Class="mt-2.5 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-6" Modal_Close_Func={handleCloseFilter} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-full !rouded-none !w-96 opacity-none overflow-y-auto" ModalTopClass="!items-right !justify-end "/>
      )}
      {saveProject &&(
        <ModalBox Modal_Body={AnotherProject} Modal_Footer={SaveProjectFooter} Modal_Header={ProjectHeader} modal_Header_Text="text-2xl font-[500] mb-0" modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}

      {isProjectCreation &&(
        <ModalBox Modal_Body={<ProjectCreation onClose={handleCloseNewProject}/>}  modal_Close_Class="mt-3 border-2 pl-2 pr-2 pt-1 pb-1 rounded-lg mr-5" Modal_Close_Func={handleCloseNewProject} cardClass="min-w-md  bg-white shadow rounded-lg overflow-hidden h-auto grid gap-[30px] p-[30px] !rouded-xl no-max-width !w-[719px] opacity-none overflow-y-auto"/>
      )}
      
      {isOpen && (
        <div
          className="absolute z-10 w-8/12 mt-2 top-9 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={dropdownRef}
        >
          <div class="p-1 space-y-0.5">
      <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Uncontacted
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Contacted
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Replied
      </a>
      
    </div>
        </div>
      )}
      {isOpenMessage && (
        <div
          className="absolute left-4 z-10 w-10/12 top-11 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={dropdownRef}
        >
          <div class="p-1 space-y-0.5">
      <a class="flex items-center gap-x-3.5  py-2 px-3  text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Reject Candidate
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Hide Candidate
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Add Note
      </a>
      {/* <a class="flex items-center gap-x-3.5 py-2 px-3   text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" onClick={handleSaveToProject} href="#">
      Save To Another Project
      </a> */}
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Share for Review
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#" onClick={handleSaveToProject}>
      Save To Another Project
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3   text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save to PDF
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3  text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Find More People Like This
      </a>
      
    </div>
        </div>
      )}
    
    
                    
                    <Button type="button" className="!bg-gray-100  ml-3 h-9 items-center justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleDropdownMessage}>
                <img src="/image/message.png" className='w-5' />
            </Button>
            <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="p-1 h-5 mb-1 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
            <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
            </svg>
            </button>
            </div>
            </div>






                </div>
                <div className="grid gap-[32px]">
                <div className="mt-4 flex gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-[12px] font-[500]">Experience</p></div>
                  <div>
                    <p className="text-xs opacity-[50%] mb-0 font-[500]">4+ years of experience in UX design</p>
                    <p className="text-black text-xs flex font-[400] mb-0"><span className="text-green-700 font-[700] mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                    <p className="text-black text-xs font-[400] flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                    <p className="text-black text-xs font-[400] flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                  </div>
                </div>
                <div className="flex gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-xs font-[500]">Education</p></div>
                  <div>
                    
                    <p className="text-black flex text-xs mb-0">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                    <p className="text-black flex text-xs mb-0">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
                  </div>
                </div>
                <div className="flex gap-[32px]">
                <div className="w-[80px]">
                  <p className="ml-2 text-xs mb-0 font-[500] text-black">Skills Match</p></div>
                  <div>
                    
                    <p className="flex text-xs text-green-700 mb-0 font-medium">User Research <li className="text-xs ml-2">User-Centered Design Thinking</li><li className="ml-2">Problem-solving</li></p>
                  </div>
                </div>
                <div className="flex mb-4 gap-[32px]">
                    <div className="w-[80px]">
                  <p className="ml-2 text-black mb-0 text-xs font-[500]">Activity</p></div>
                  <div className="w-[381px] justify-between">
                    
                    <p className="text-black flex text-xs mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                    
                  </div>
                </div>
                </div>
                </>}
                />
                {/* <Card CardFooterClass="px-6 py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden mt-4 mb-4 !rounded-xl shadow border-white w-full"
                body={<>
                <div className="flex">
                    <img src={Person} className="w-28 h-28 rounded-full object-cover"></img>
                    <div className="pl-2 absolute ml-6 inset-6 mt-14 h-9 bg-green-50 shadow-lg shadow-green-700  w-20 pb-1  rounded-md ">
                    <div className="relative size-6 mt-1">
                    <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" stroke-width="4"></circle>

                        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 dark:text-blue-500" stroke-width="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                    </svg>


                    
                    </div>
      
        


                    
                   

                    
                    <div className="absolute rounded-lg inset-2 ml-8 mt-2 mr-2 flex items-center  justify-center  text-gray-700 dark:text-white">
                        
                        <div className="h-14">
                          <p className="mb-0 text-black font-medium mt-1 ml-3">{94}%</p>
                          
                        </div>
                    </div>
                    <p className="text-xs mb-0 absolute inset-y-4 ml-8">Match</p>
                    
                    </div>

                    <div className="ml-6">
                        <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                        <p className="text-sm mb-0">UI/UX Designer</p>
                        <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                    </div>
                    <div>
                    <div className="relative inline-block text-left">
      <div className="ml-20 pl-3 mt-4">
        <Button
          type="button"
          className="inline-flex  w-full justify-center gap-x-1.5 rounded-md bg-white px-3  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdownPipeline}
        >
          <p className="mt-2 mb-0 text-green-600">Save to pipeline</p>
          <svg
            className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
            viewBox="0 0 20 20"
            fill="green"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      </div>

      
      {isOpen && (
        <div
          className="absolute left-24  z-10 w-8/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="p-1 space-y-0.5">
      <a className="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Uncontacted
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Contacted
      </a>
      <a className="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Replied
      </a>
      
    </div>
        </div>
      )}
      {isOpenMessage && (
        <div
          className="absolute left-24  z-10 w-10/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div class="p-1 space-y-0.5">
      <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Archieve Candidate
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
        Add Note
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Share For Review
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Another Project
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Pdf
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Save To Another Project
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
      Find More People Like This
      </a>
      
    </div>
        </div>
      )}
    </div>
    
                    </div>
                    <Button type="button" className="!bg-gray-100 inline-flex ml-3 h-9 mt-7 justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleDropdownMessage}>
                <img src="/image/message.png" className='w-5' />
            </Button>
            <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-5 mt-9  text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
<svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
<path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
</svg>
</button>






                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Experience</p>
                  <div className="ml-14">
                    <p className="text-xs mb-0">4+ years of experience in UX design</p>
                    <p className="text-black text-sm flex mb-0"><span className="text-green-700 font-medium mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                    <p className="text-black text-sm flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                    <p className="text-black text-sm flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                  </div>
                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Education</p>
                  <div className="ml-16">
                    
                    <p className="text-black flex text-sm mb-0">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                    <p className="text-black flex text-sm mb-0">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
                  </div>
                </div>
                <div className="mt-4 flex">
                  <p className="ml-2 text-black">Skills Match</p>
                  <div className="ml-12">
                    
                    <p className="flex text-sm text-green-700 mb-0 font-medium">User Research <li className="text-sm ml-2">User-Centered Design Thinking</li><li className="ml-2">Problem-solving</li></p>
                  </div>
                </div>
                <div className="mt-1 flex mb-4">
                  <p className="ml-2 text-black">Activity</p>
                  <div className="ml-16">
                    
                    <p className="text-black flex text-sm ml-4 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                    
                  </div>
                </div>
                </>}
                /> */}
                </div>
                
            </div>
    );
}
export default Pipeline;
