import React,{useState,useRef,useContext} from "react";
import Logo from "../Common/Logo";
import Textinput from "../elements/TextInput/TextInput";
import Selectbox from "../elements/SelectBox/SelectBox";
import Textarea from "../elements/TextArea/TextArea";
import Button from "../elements/Button/Button";
import ImageIcon from "../elements/ImageIcon/ImageIcon";
import UploadImageTemp from "../elements/ImageTempUpload/ImageTempUpload"
import {validateEmail} from "../Validation/Validation"
import Notification from "../Notification/Notification";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from 'crypto-js';
import ClipLoader from "react-spinners/ClipLoader";
const CompanyAdd = () =>{
    const { token } = useContext(AuthContext);
    const [selectedSpecialties, setSelectedSpecialties] = useState([]);
    const [addCompanyNameVal,companyNameFun] = useState("");
    const [companyProfileImage,imageFun] = useState(false);
    const [companyProfileImageName,imageNameFun] = useState(false);
    const [addCompanyFoundVal,foundedValFun] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const companyName = useRef(null);
    const companyEmail = useRef(null);
    const companySizeRef = useRef(null);
    const companyHeadQuater = useRef(null);
    const companyIndustry = useRef(null);
    const companyAddress = useRef(null);
    const companyLocation = useRef(null);
    const companyAbout = useRef(null);
    const companyWebsite = useRef(null);
    const companyFounded = useRef(null);
    // const companySpecialies = useRef(null);
    const suggestions = [
        "Talent Acquisition",
        "Full Cycle Recruiting",
        "Recruitment Marketing",
        "AI Driven Recruiting Solutions",
        "Contract Staffing",
        "Global Talent Acquisition",
        "Employee Retention Strategies",
        "Custom"
    ];
  const [selectedValue, setSelectedValue] = useState('');
  const [Industryselect, setIndustryValue] = useState('');
  const [notification, setNotification] = useState({ message: "", type: "" });

  const showNotification = (message, type) => {   //For Notification
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: "", type: "" });
    }, 3000); // Auto-hide after 3 seconds
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    companySizeRef.current.classList.remove("border-red-600");

  };
  const emailOninputFun = () =>{
    companyEmail.current.classList.remove("border-red-600");
  }

  const handleIndustryChange = (event) => {
    setIndustryValue(event.target.value);
    companyIndustry.current.classList.remove("border-red-600");
  };

  const Companysize = [
    { id: '', label: 'Select Size' },
    { id: '1', label: 'Small (1-10 employees)' },
    { id: '2', label: 'Medium (11-50 employees)' }
  ];
  const Industry = [
    { id: '', label: 'Select Industry' },
    { id: '1', label: 'Small (1-10 employees)' },
    { id: '2', label: 'Medium (11-50 employees)' },
    { id: '3', label: 'Large (50-200 employees)' }
  ];
    const addSpecialty = (specialty) => {
        if (!selectedSpecialties.includes(specialty)) {
            setSelectedSpecialties([...selectedSpecialties, specialty]);
        }
    };

    const removeSpecialty = (specialty) => {
        setSelectedSpecialties(selectedSpecialties.filter(item => item !== specialty));
    };
    const validatePhoneNo = (e) => {          //Mobile Number Validation                
        const input = e.target.value.replace(/\D/g, '').slice(0, 10);
        companyPhoneNoFun(input);
    };
    const validateName = (e) => {    //Name Validation only allow sting ,space and dot
        const input = e.target.value.replace(/[^a-zA-Z.\s]/g, '');
        if(input)
        companyName.current.classList.remove("border-red-600");
        companyNameFun(input);
    };
    const headQuarterOninputFun = () =>{
        companyHeadQuater.current.classList.remove("border-red-600");
    }
    const addressOninputFun = () =>{
        companyAddress.current.classList.remove("border-red-600");
    }
    const locationOninputFun = () =>{
        companyLocation.current.classList.remove("border-red-600");
    }
    const aboutOninputFun = () =>{
        companyAbout.current.classList.remove("border-red-600");
    }
    const websiteOninputFun = () =>{
        companyWebsite.current.classList.remove("border-red-600");
    }
    const foundedOninputFun = (e) =>{
        const input = e.target.value.replace(/\D/g, '');
        foundedValFun(input)
        if(input)
        companyFounded.current.classList.remove("border-red-600");
    }
    const UploadImageTemp = async (event) => {   //Upload Image Fun
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("file", file);
        // console.log(formData.get("file"));
        try {
            const serverURL = process.env.REACT_APP_SERVER_URL;
            const response = await fetch(`${serverURL}/signUpImageTemp`, {
                method: 'POST',
                body: formData, // Automatically sets the correct Content-Type
            });
            if (!response.ok) {
                throw new Error('Failed to upload file');
            }

            const result = await response.json();
            console.log("Server Response:", result);
            imageFun(result.files.file[0]['newFilename'])
            imageNameFun(result.files.file[0]['originalFilename'])
            // console.log(result.files.file[0]['newFilename'])

        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };
    const addCompanyFun = async () =>{
        var emailVal=companyEmail.current.value
        var sizeVal=companySizeRef.current.value
        var headquaterVal=companyHeadQuater.current.value
        var industryVal=companyIndustry.current.value
        var addressVal=companyAddress.current.value
        var locationVal=companyLocation.current.value
        var aboutVal=companyAbout.current.value
        var websiteVal=companyWebsite.current.value
        var foundVal=companyFounded.current.value
        var specialityVal=1;
        // var specialityVal=companySpecialies.current.value
        if(!addCompanyNameVal){
            showNotification("Enter Company Name","error");
            companyName.current.classList.add("border-red-600");
            companyName.current.focus();
            return;
        }
        if(!emailVal){
            showNotification("Enter Company Email","error");
            companyEmail.current.classList.add("border-red-600");
            companyEmail.current.focus();
            return;
        }
        else if(!validateEmail(emailVal)){
            showNotification("Enter Valid Email","error");
            companyEmail.current.classList.add("border-red-600");
            companyEmail.current.focus();
            return;
        }
        if(!sizeVal){
            showNotification("Choose Company Size","error");
            companySizeRef.current.classList.add("border-red-600");
            companySizeRef.current.focus();
            return;
        }
        if(!headquaterVal){
            showNotification("Enter Company Head Quater","error");
            companyHeadQuater.current.classList.add("border-red-600");
            companyHeadQuater.current.focus();
            return;
        }
        if(!industryVal){
            showNotification("Choose Company Industry","error");
            companyIndustry.current.classList.add("border-red-600");
            companyIndustry.current.focus();
            return;
        }
        if(!addressVal){
            showNotification("Enter Company Address","error");
            companyAddress.current.classList.add("border-red-600");
            companyAddress.current.focus();
            return;
        }
        if(!locationVal){
            showNotification("Enter Company Location","error");
            companyLocation.current.classList.add("border-red-600");
            companyLocation.current.focus();
            return;
        }
        if(!aboutVal){
            showNotification("Enter Company About","error");
            companyAbout.current.classList.add("border-red-600");
            companyAbout.current.focus();
            return;
        }
        if(!websiteVal){
            showNotification("Enter Company Website","error");
            companyWebsite.current.classList.add("border-red-600");
            companyWebsite.current.focus();
            return;
        }
        if(!foundVal){
            showNotification("Enter Company Founded","error");
            companyFounded.current.classList.add("border-red-600");
            companyFounded.current.focus();
            return;
        }
        if(!companyProfileImage){
            showNotification("Select Company Profile","error");
            return;
        }
        // if(!specialityVal){
        //     showNotification("Enter Company Speciality","error");
        //     companySpecialies.current.classList.add("border-red-600");
        //     return;
        // }
        const companyData = {name:addCompanyNameVal,company_email:emailVal,location:locationVal,size:sizeVal,description:"nill",head_quarters:headquaterVal,url:websiteVal,industry:industryVal,about:aboutVal,comp_suggestion_master:specialityVal,founded_year:foundVal,lat:"12",lang:"12",profile:companyProfileImage};
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(companyData), process.env.REACT_APP_SECRETKEY).toString();
        const serverURL = process.env.REACT_APP_SERVER_URL;
        var encryptjson = { data:encryptedData}
        setIsLoading(true);
        try {
            const response = await fetch(`${serverURL}/company/addCompany`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  token: token
                },
                body: JSON.stringify(encryptjson),
            });
            const data = await response.json();
            const companyData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            var notificationStatus = ''
            var notificationContent = ''
            if(data.status){
                notificationStatus = data.status;
            }
            if(data.message){
                notificationContent = data.message;
            }
            if(data.error){
                notificationContent = data.error;
            }
            showNotification(notificationContent , notificationStatus);
            if(response.ok){
                const encryptedMetadata = localStorage.getItem('metadata');
                if (encryptedMetadata) {
                    const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                    if(companyData && companyData.company_id)
                        decryptedMetadata['cid'] = companyData.company_id;
                    const encryptedUpdatedMetadata = CryptoJS.AES.encrypt(JSON.stringify(decryptedMetadata), process.env.REACT_APP_SECRETKEY).toString();
                    localStorage.setItem('metadata', encryptedUpdatedMetadata);
                    if (localStorage.getItem('metadata') === encryptedUpdatedMetadata) {
                        window.location = "/TeamMember";
                    } else {
                        console.error("Failed to set metadata in localStorage");
                    }
                }
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
        finally {
            setIsLoading(false); // Always hide loader after the request
        }
        // const decrypted = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRETKEY);
        // const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
        // if(data.error){
        //     showNotification(data.error, "error");
        // }
    }
        const UploadIcon=(<svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
        </svg>);
        const UploadLabel=(<p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold text-green-700 mr-1">Click here</span>to upload or drag and drop</p>);
        const serverURL = process.env.REACT_APP_SERVER_URL;
        // console.log(<img src={`${serverURL}/public/images/temp/${companyProfileImage}`} />)
        const UploadIconWithImage=(<div>{companyProfileImageName}</div>);
   return(
    <div className="">
        <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification({ message: "", type: "" })}
        />
        {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Company Adding...</span>
                    </div>
                </div>
            )}
        <div className="flex">
        <div className="w-3/12 pr-2 mr-8 bg-gradient-to-r from-white to-white-50 to-emerald-50 ...">
        <div className="ml-4">
        <Logo/>
        </div>
        
        
            <p className="ml-8 mt-8 mb-8 text-xl text-black font-medium">Steps For Profile Registering</p>
            <div className="bg-black flex rounded-lg  w-11/12 ml-6 h-16   pr-4 pl-2">
            <div className="bg-black pr-2 w-11 ml-2 h-11 mt-2 border-dashed border-2 border-green-500 rounded-full">
              <div className="bg-green-800 w-1/8 rounded-full ml-1.5 p-2 w-7 h-7 mt-1.5">
                
              </div>
              </div>
              <p className="text-white absolute mt-3 text-lg  pl-6">1</p>
                <div>
                <p className="text-white  mt-2.5 ml-4 text-sm">Company Details<p className="text-gray-400 text-xs ">Step desciption</p></p>
                
                </div>
            </div>
            <div className="h-14 ml-32 border-l-2 border-green-500"></div>
            <div className="bg-white flex rounded-lg  w-11/12 ml-6 h-16 mt-1  pr-4 pl-2">
            <div className="bg-white pr-2 w-11 ml-2 h-11 mt-2  ">
              <div className="bg-white w-1/8 border-2 border-gray-500 rounded-full ml-1.5 p-2 w-8 h-8 mt-1.5">
                
              </div>
              </div>
              <p className="text-black absolute pt-3 text-lg  pl-6 ml-0.5">2</p>
                <div>
                <p className="text-black  mt-2.5 ml-4 text-sm">Add Collaborators<p className="text-gray-400 text-xs ">Step desciption</p></p>
                
                </div>
            </div>
        </div>
        <div className="rounded-t-2xl border mt-6 ml-4 mr-4 bg-white w-3/4">
            <div className="flex">
            <div className="w-6/12">
                <p className="text-lg text-black font-medium ml-6 mt-3">Company Profile<p className="text-xs font-normal text-gray-500 mt-1">Fill in your previous details and let our AI help you find your perfect job!</p></p>
                <p className="ml-6 text-black text-md">Upload Company Logo<span className="text-red-600">*</span></p>
                
                    <div class="flex ml-6 items-center justify-center w-full">
                        {/* <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-32 border-2 border-green-300 border-dashed rounded-lg cursor-pointer bg-green-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center pt-2 pb-2">
                                <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold text-green-700 mr-1">Click here</span>to upload or drag and drop</p>
                                
                            </div>
                            <input id="dropzone-file" type="file" className="hidden" />
                        </label> */}
                        <ImageIcon OnChange={(event) =>UploadImageTemp(event)} svgIcon={companyProfileImage ?  UploadIconWithImage:UploadIcon } ImageClass="w-full" inputid="dropzone-file" insideLabel={UploadLabel} labelClass="flex flex-col items-center justify-center w-full h-32 border-2 border-green-300 border-dashed rounded-lg cursor-pointer bg-green-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"></ImageIcon>
                    </div> 
                    {/* <p className="ml-6 text-black text-md mt-4">Company Name<span className="text-red-600">*</span></p>
                    <input className="border-1 border-gray-200 ml-6 w-full p-2 rounded-lg text-md" placeholder="Enter Company Name"/>
                     */}
                    <Textinput InputRef={companyName} value={addCompanyNameVal} onChange={validateName} label="Company Name" labelclass="ml-6 mb-2 mt-4 " span="*" className="border-1 border-gray-200 ml-6 w-full pt-2.5 pb-2.5 rounded-lg "  placeholder="Enter Company Name"/>
                    <Textinput type="email" InputRef={companyEmail} OnInput={emailOninputFun} label="Email" labelclass="ml-6 mb-2 mt-4" span="*" className="border-1 border-gray-200 ml-6 w-full pt-2.5 pb-2.5 rounded-lg "  placeholder="Enter Company Email"/>
                     
                    {/* <p className="ml-6 text-black text-md mt-4">Company Size<span className="text-red-600">*</span></p> */}
                    <Selectbox
                        span="*"
                        label="Company Size"
                        labelClass="ml-6 mt-4 mb-2"
                        name="exampleSelect"
                        value={selectedValue}
                        onChange={handleSelectChange}
                        list={Companysize}
                        className="ml-6 pt-2 pb-2 rounded-lg border-gray-200 bg-white"
                        InputRef={companySizeRef}
                        required
                    />
                    {/* <p className="ml-6 text-black text-md mt-4">Head Quarter<span className="text-red-600">*</span></p>
                    <input className="border-1 border-gray-border-gray-200 ml-6 w-full p-2 rounded-lg text-md" placeholder="Enter Headquarter City"/> */}
                    <Textinput label="Head Quarter" OnInput={headQuarterOninputFun} InputRef={companyHeadQuater} span="*" labelclass="ml-6 mt-4 mb-2" className="border-1 border-gray-200 ml-6 w-full pb-2.5 pt-2.5 rounded-lg text-md"/>
                    {/* <p className="ml-6 text-black text-md mt-4">Industry<span className="text-red-600">*</span></p>
                    <select className="border-1 border-gray-200 w-full ml-6 p-2 rounded-lg text-md">
                        <option value="0">Select Industry</option>
                        <option value="small">Small (1-10 employees)</option>
                    </select> */}
                    <Selectbox
                        span="*"
                        label="Industry"
                        InputRef={companyIndustry}
                        labelClass="ml-6 mt-4 mb-2"
                        name="exampleSelect"
                        value={Industryselect}
                        onChange={handleIndustryChange}
                        list={Industry}
                        className="ml-6 pt-2 pb-2 border-gray-200 bg-white"
                        required
                    />

                    {/* <p className="ml-6 text-black text-md mt-4">Address<span className="text-red-600">*</span></p>
                    <textarea 
                        className="border-1 border-gray-200  ml-6 w-full p-2 rounded-lg text-md resize-none" 
                        placeholder="Enter Address">
                    </textarea> */}
                    <Textarea OnInput={addressOninputFun} InputRef={companyAddress} label="Address" labelClass="ml-6 mt-4 mb-2" span="*" placeholder="Enter Address" className="resize-none px-1 ml-6 border-gray-200"/>

                    <div className="flex ml-6 w-full">
                    <div className="w-8/12">
                    {/* <p className="text-black text-md mt-4">Location URL<span className="text-red-600">*</span></p>
                    <input className="border-1 border-gray-200  w-full p-2 rounded-lg text-md" placeholder="Enter Location URL"/> */}
                    <Textinput OnInput={locationOninputFun} InputRef={companyLocation} className="w-full p-2 rounded-lg text-md !border-[1px]" labelclass="mt-9" label="Location URL" span="*" placeholder="Enter Location URL"/>
                    </div>
                    <div className="mt-2 flex">
                    <p className="p-1 bg-gray-50 w-6 h-6 ml-2 mt-16 text-xs rounded-full text-gray-500">Or</p>
                    <Button className="bg-teal-50 text-green-700 border-none font-normal text-xs pt-2 pb-2 h-10 mt-14 pl-2 ml-2 rounded-lg pr-2 p-2">Current Location</Button>
                    </div>
                    </div>
                    {/* <p className="ml-6 text-black text-md mt-4">About<span className="text-red-600">*</span></p>
                    <textarea 
                        OnInput={aboutOninputFun} InputRef={companyAbout} className="border-1 border-gray-200  ml-6 w-full p-2 rounded-lg text-md resize-none" 
                        placeholder="About Company">
                    </textarea> */}
                    <Textarea OnInput={aboutOninputFun} InputRef={companyAbout} label="About" placeholder="About Company" span="*" labelClass="ml-6 mt-4 mb-2" className="border-1 border-gray-200  ml-6 w-full p-2 rounded-lg text-md resize-none"/>
                    {/* <p className="ml-6 text-black text-md mt-4">Company Website Link<span className="text-red-600">*</span></p>
                    <input className="border-1 border-gray-200 ml-6 w-full p-2 rounded-lg text-md" placeholder="Enter Company Website Link"/> */}
                    <Textinput OnInput={websiteOninputFun} InputRef={companyWebsite} label="Company Website" className="border-1 border-gray-200 ml-6 w-full p-2 rounded-lg text-md" span="*" labelclass="ml-6 mt-4 mb-2" placeholder="Enter Company Website Link"/>
                    {/* <p className="ml-6 text-black text-md mt-4">Founded<span className="text-red-600">*</span></p>
                    <input className="border-1 border-gray-200 ml-6 w-full p-2 rounded-lg text-md" placeholder="Founded Year"/> */}
                    <Textinput value={addCompanyFoundVal} onChange={foundedOninputFun} InputRef={companyFounded} label="Founded" className="border-1 border-gray-200 ml-6 w-full p-2 rounded-lg text-md" span="*" labelclass="ml-6 mt-4 mb-2" placeholder="Founded Year"/>
                <div>    
                {/* <label className="font-normal ml-6 mt-4 text-md mb-2">
                Specialties<span className="text-red-500">*</span>
                </label> */}
                            {/* <Textinput label="Specialities" className="border-1 border-gray-200 flex-grow ml-6 w-full p-2 rounded-lg text-md" span="*" labelclass="ml-6 mt-4 mb-2"/> */}
            <p className="mb-0 ml-6 mt-4 mb-2">Specialities<span className="text-red-600">*</span></p>
            <div className="border-[1px] border-gray-200 w-full ml-6 pt-3 pb-3 rounded-lg flex flex-wrap gap-2">
                {selectedSpecialties.map((specialty, index) => (
                    <span key={index} className="flex items-center border-1 border-gray-200 bg-teal-50 text-black px-3 py-1 rounded-lg">
                        {specialty}
                        <Button
                            onClick={() => removeSpecialty(specialty)}
                            className="ml-2 text-black bg-teal-50 border-none font-bold hover:text-red-500"
                        >
                            ×
                        </Button>
                    </span>
                ))}
                {/* <input
                    type="text"
                    className="flex-grow  focus:outline-none"
                /> */}
            </div>

            <div className="ml-6 w-full">
                <p className="text-gray-500 text-sm mb-2 mt-2">Suggestions:</p>
                <div className="flex flex-wrap gap-1">
                    {suggestions.map((suggestion, index) => (
                        <Button
                            key={index}
                            onClick={() => addSpecialty(suggestion)}
                            className={`px-3 py-1 border-2 bg-white rounded-lg ${selectedSpecialties.includes(suggestion) ? 'border-teal-300 text-xs bg-teal-100 text-teal-700' : 'border-teal-100  text-xs text-gray-700'} hover:border-teal-400`}
                        >
                            {suggestion}
                        </Button>
                    ))}
                </div>
            </div>
            </div>
            </div>
            <div className="mt-24 ml-14 mr-8">
            <p className="mt-20  absolute pr-2 pt-1 pb-1 pl-2 rounded-full bg-green-50 text-green-600 font-medium">Or</p>
            <div className="h-48 ml-4 border-l-2 border-gray-200"></div>
            </div>
            <div className="mt-28 p-10 h-36 rounded-lg ml-4 bg-gradient-to-r from-sky-300 to-emerald-100 ...">
        
        <Button className="linkedin-button flex h-16"><svg xmlns="http://www.w3.org/2000/svg" className="mr-2 mt-2.5" fill="none" height="24" viewBox="0 0 24 24" width="24"><path d="m19.0413 19.0413h-2.9635v-4.6409c0-1.1067-.0197-2.5313-1.5412-2.5313-1.5435 0-1.7797 1.2057-1.7797 2.4507v4.7211h-2.96337v-9.54349h2.84487v1.30419h.0399c.5792-.99038 1.6602-1.5841 2.8069-1.54152 3.0035 0 3.5573 1.97562 3.5573 4.54582zm-12.59151-10.84845c-.94342 0-1.72009-.77622-1.72009-1.71964s.77631-1.71973 1.71973-1.71973c.94333 0 1.71955.77613 1.71973 1.71937 0 .94324-.77613 1.71991-1.71937 1.72zm1.48169 10.84845h-2.96651v-9.54389h2.96651zm12.58712-17.0398h-17.04282c-.80002-.00903-1.46603.6415-1.47578 1.44152v17.11358c.00939.8004.67531 1.4517 1.47578 1.4431h17.04282c.8024.0101 1.4707-.641 1.4814-1.4431v-17.11475c-.0107-.80171-.6799-1.45216-1.4814-1.4416z" fill="#0a66c2"/></svg><p className="font-medium text-md">Upload From LinkedIn<p className="text-xs ml-3 font-normal text-gray-500">To save your information directly</p></p></Button>
        
    </div>
            
            </div>
            <div className="mt-10 shadow rounded-t-2xl flex mr-4 bg-black h-20">
                <div>
                    <Button onClick={addCompanyFun} className=" pr-4 pl-4 ml-8 pt-2 pb-2 rounded-lg mt-4 border-none bg-green-700 text-white">Save & Continue</Button>
                    </div>
                    <div>
                    <Button className=" pr-8 pl-8 ml-8 pt-1.5 pb-1.5 rounded-lg mt-4 border  bg-black text-white hidden">Skip</Button>
                    </div> 
            </div>
        </div>
        </div>
    </div>
   )
};
export default CompanyAdd;
