import React, { useEffect, useState } from "react";
import Textinput from "../elements/TextInput/TextInput";
import Button from "../elements/Button/Button";
import { useCommonContext } from "../../context/CommonContext";
import { useNavigate } from "react-router-dom";
import Textarea from "../elements/TextArea/TextArea";

const Jobscreen = () => {
  const navigate = useNavigate();
  const { jobCreationData, setJobCreationData, validationErrors } = useCommonContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const gobacktochatbot = () => {
    navigate("/JobCreation");
  };

  const [skills, setSkills] = useState([
    "Education",
    "Background Check",
    "Expertise With Skill",
    "Driver's License",
    "Hybrid Work",
    "Drug Test",
    "Industry Experience",
  ]);



  const [customSkill, setCustomSkill] = useState("");
  const [showCustomInput, setShowCustomInput] = useState(false);

  const toggleSkill = (skill) => {
    setJobCreationData((prev) => ({
      ...prev,
      selectedSkills: prev.selectedSkills.includes(skill)
        ? prev.selectedSkills.filter((s) => s !== skill)
        : [...prev.selectedSkills, skill],
    }));
  };

  const addCustomSkill = () => {
    if (customSkill && !skills.includes(customSkill)) {
      setSkills([...skills, customSkill]);
      setJobCreationData((prev) => ({
        ...prev,
        selectedSkills: [...prev.selectedSkills, customSkill],
      }));
      setCustomSkill("");
      setShowCustomInput(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJobCreationData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="ml-7">
      <p className="text-lg font-medium mb-0">Review Job Description</p>
      <p className=" text-gray-600">
        Add members below which will be visible to the Job seekers under ‘Meet
        your teams’ sections
      </p>
      <div className="flex">
        <div className="w-[26%]">
          <Textinput
            label="Job Title"
            value={jobCreationData.jobTitle}
            name="jobTitle"
            onChange={handleInputChange}
            labelclass="font-medium"
            className={`w-[90%] outline-none ${validationErrors.jobTitle ? "border-red-500" : ""}`}
          />
        </div>
        <div className="w-[26%] ml-4">
          <Textinput
            label="Company"
            value={jobCreationData.company}
            name="company"
            onChange={handleInputChange}
            labelclass="font-medium"
            className={`w-[90%] outline-none ${validationErrors.company ? "border-red-500" : ""}`}
          />
        </div>
        <div className="w-[26%] ml-4">
          <Textinput
            label="Workplace Type"
            value={jobCreationData.workplaceType}
            name="workplaceType"
            onChange={handleInputChange}
            labelclass="font-medium"
            className={`w-[90%] outline-none ${validationErrors.workplaceType ? "border-red-500" : ""}`}
          />
        </div>
      </div>
      <div className="flex">
        <div className="w-[26%]">
          <Textinput
            label="Job Location"
            value={jobCreationData.jobLocation}
            name="jobLocation"
            onChange={handleInputChange}
            labelclass="font-medium"
            className={`w-[90%] outline-none ${validationErrors.jobLocation ? "border-red-500" : ""}`}
          />
        </div>
        <div className="w-[26%] ml-4">
          <Textinput
            label="Job Type"
            value={jobCreationData.jobType}
            name="jobType"
            onChange={handleInputChange}
            labelclass="font-medium"
            className={`w-[90%] outline-none ${validationErrors.jobType ? "border-red-500" : ""}`}
          />
        </div>
      </div>
      <div className="w-full mt-2 ">
        <Textarea
          label="Description"
          labelclass="font-medium"
          className={`w-[90%] outline-none ${validationErrors.description ? "border-red-500" : ""}`}
          value={jobCreationData.description}
          name="description"
          style={{height:'200px',fontSize:'15px'}}
          onChange={handleInputChange}
        />
      </div>
      <div className="w-full mb-4 mt-4 flex justify-end">
        <Button
            onClick={() => gobacktochatbot()}
            className="bg-white text-[#059669] !border-1 border-[#059669] pl-1 w-[168px] rounded-lg pr-1 pt-1 pb-1"
          >
            Rewrite with AI +
          </Button>
        </div>
      <div>
        <p className="text-lg font-medium">Skills</p>
        <div className={`w-[60%] outline-none ${validationErrors.selectedSkills ? "border-2 border-red-500" : ""}`}>
          {skills.map((skill) => (
            <button
              key={skill}
              className={`!p-[12px] !shadow-lg text-[14px] !rounded-full ${
                jobCreationData.selectedSkills.includes(skill)
                  ? "bg-teal-200"
                  : "bg-teal-50"
              } ml-[12px] mb-[12px]`}
              onClick={() => toggleSkill(skill)}
            >
              {skill} +
            </button>
          ))}
          <button
            className="!p-[12px] !shadow-lg text-[14px] !rounded-full bg-teal-50 ml-[12px] mb-[12px]"
            onClick={() => setShowCustomInput(true)}
          >
            Custom +
          </button>
        </div>

        {showCustomInput && (
          <div className="flex mt-[12px]">
            <input
              type="text"
              className="border p-[8px] rounded text-[14px] mr-[8px]"
              placeholder="Enter custom skill"
              value={customSkill}
              onChange={(e) => setCustomSkill(e.target.value)}
            />
            <button
              className="!p-[12px] !shadow-lg text-[14px] !rounded-full bg-teal-200"
              onClick={addCustomSkill}
            >
              Add Skill
            </button>
          </div>
        )}
        <div className="mt-[16px]">
          <p className="text-md font-medium">Selected Skills</p>
          <div className="flex flex-wrap mt-[8px] w-[50%]">
            {jobCreationData.selectedSkills.map((skill) => (
              <div
                key={skill}
                className="flex items-center bg-teal-100 p-[8px] rounded-full mr-[8px] mb-[8px]"
              >
                <span className="text-[14px] mr-[4px]">{skill}</span>
                <button
                  style={{ cursor: "pointer" }}
                  className="text-red-500 text-[14px] font-bold"
                  onClick={() => toggleSkill(skill)}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobscreen;