import React from 'react';

const Badge = (props) => {
  return (
    <div>
    <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${props.className}`}>{props.badgeText}</span>
    </div>
  );
};

export default Badge;
