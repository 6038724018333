import React from 'react';

const ButtonSolid = ({ content = '',className = '',style = {},onClick = () => {},...rest}) => {
  return (
    <button 
      className={`signin-button ${className}`} 
      style={style} 
      onClick={onClick} 
      {...rest}
    >
      {content}
    </button>
  );
};

export default ButtonSolid;
