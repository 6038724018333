import React from "react";
import "./Notification.css"; // Add your custom styles here

const Notification = ({ message, type, onClose }) => {
  if (!message) return null;

  const typeClass = type === "error" ? "notification-error" : "notification-success";

  return (
    <div className={`notification ${typeClass}`}>
      <p className="mb-0">{message}</p>
      <button className="notification-close" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default Notification;
