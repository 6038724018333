import React from 'react';
const Button = (props)=>{
    return (
        <>
            <button
      type={props.type || 'submit'}
      name={props.name || ''}
      onClick={props.onClick || (() => {})}
      className={`border-1 bg-green-700  rounded-md hover:transform transition duration-150 hover:scale-105 ${props.className || ''}`}
    >
      {props.PreSvgIcon || ""}{props.children || 'Button'}{props.PostSvgIcon || ""}
    </button>
        </>
    )
}
export default Button;