import React, { useState, useEffect } from "react";
import Button from "../elements/Button/Button";
import Textinput from "../elements/TextInput/TextInput";
import Card from "../elements/Card/Card";
import Selectbox from "../elements/SelectBox/SelectBox";
import Toggle from "../elements/ToggleButton/ToggleButton";
import { useCommonContext } from "../../context/CommonContext";

const Jobscreening = () => {
    const { jobCreationData, setJobCreationData, validationErrors } = useCommonContext();

    const screenquestions = {
        "Education": { question: "Question Related to Education ?" },
        "Background Check": { question: "Question Related to Background Check ?" },
        "Expertise With Skill": { question: "Question Related to Expertise with Skill ?" },
        "Driver's License": { question: "Question Related to Driver's License ?" },
        "Hybrid Work": { question: "Question Related to Hybrid Work ?" },
        "Drug Test": { question: "Question Related to Drug Test ?" },
        "Industry Experience": { question: "Question Related to Industry Experience ?" },
    };
    const [isCustom, setIsCustom] = useState({});

    const dataTypes = [
        { id: '0', label: 'String' },
        { id: '1', label: 'Numeric' },
        { id: '2', label: 'Array' },
    ];

    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const handleCustomToggle = (questionId) => {
        console.log("custom key passed : ",questionId)
        setIsCustom((prev) => ({
          ...prev,
          [questionId]: "yes", // Toggle the isCustom value for the specific question
        }));
      };

    const handleCustom = () => {
        handleCustomToggle(jobCreationData.questions.length);
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: [
                ...prevData.questions,
                { questiontype: "String", question_desc: "", required: true },
            ],
        }));
    };

    const handleDelete = (indexToRemove) => {
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: prevData.questions.filter((_, index) => index !== indexToRemove),
        }));
    };

    const handleInputChange = (index, field, value) => {
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: prevData.questions.map((q, i) =>
                i === index ? { ...q, [field]: value } : q
            ),
        }));
    };
    const [minimumHidden, setHiddendiv] = useState([]);
    const [requiredControl, setControl] = useState(72);
    // const handleCheckBox = (index) => {
    //     setHiddendiv((prev) => (prev === index ? null : index));
    //     setControl(72);
    // };
    const handleCheckBox = (index) => {
        setJobCreationData((prevState) => {
          const updatedQuestions = [...prevState.questions];
      
          if (updatedQuestions[index]?.minimum === undefined || updatedQuestions[index].minimum === null) {
            updatedQuestions[index] = {
                ...updatedQuestions[index],
                minimum: '1',  // Default value for minimum
              };
          } else {
            delete updatedQuestions[index].minimum; 
          }
      
          return {
            ...prevState,
            questions: updatedQuestions,
          };
        });
      
        setTimeout(() => setControl(72), 0);
      };
      
      
      

    const SelectScreeningQuestion = (key) => {
        const selectedQuestion = screenquestions[key]?.question || "Default Question";
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: [
                ...prevData.questions,
                { questiontype: "String", question_desc: selectedQuestion, required: true },
            ],
        }));
        setSelectedQuestions((prev) => [...prev, key]); // Add the selected question to the state
    };

    useEffect(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });
    }, [jobCreationData]);
    const [selecedDataType, setSelectedDataType] = useState("");
    const handleSelectDataType = (e) => {
        setSelectedDataType(e.target.value)

    }
        
    const updateQuestionAndRequired = (index, newQuestion) => {
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: 
                    prevData.questions.map((old, idx) =>
                          idx === index
                              ? { ...old, question_desc: newQuestion } // Update the specific question
                              : old // Keep other questions unchanged
                      )

        }))
    };
    const updateMinimum = (index, minimum) => {
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: 
                    prevData.questions.map((old, idx) =>
                          idx === index
                              ? { ...old, minimum: minimum } // Update the specific question
                              : old // Keep other questions unchanged
                      )

        }))
    };
    const handleRequired = (index, required_value) => {
        setJobCreationData((prevData) => ({
            ...prevData,
            questions: 
                    prevData.questions.map((old, idx) =>
                          idx === index
                              ? { ...old, required: !old.required } // Update the specific question
                              : old // Keep other questions unchanged
                      )

        }))
    };

    return (
        <div>
            <div className="w-[93%]">
                <p className="mt-[15px] font-medium text-[28px]">2. Screening Questions</p>
                <p className="text-[14px]">
                    By using screening questions your job post is highlighted to matching members, while screened
                    applicants are also highlighted to ensure qualified candidates are not missed, and applicants are
                    sorted based on qualifications met.
                </p>
            </div>
            <div>
                <p className="text-[20px] font-[500]">Add Screening Questions</p>
                <div className={`flex w-[50%] flex-wrap ${validationErrors.questions ? "border-red-500 border-2" : ""}`}>
                    {Object.keys(screenquestions).map((key, index) => (
                        <Button
                            key={index}
                            className={`!p-[12px] !shadow-lg text-[14px] !rounded-full ml-[12px] text-nowrap mt-[12px] ${
                                // selectedQuestions.includes(key) ? "bg-gray-300 cursor-not-allowed" : "bg-teal-50"
                                jobCreationData.questions.some(
                                    (question) => question.question_desc === screenquestions[key].question) ? "bg-gray-300 !cursor-not-allowed !cursor-none	" : "bg-teal-50"
                            }`}
                            onClick={() => {
                                if (
                                  !jobCreationData.questions.some(
                                    (question) => question.question_desc === screenquestions[key].question
                                  )
                                ) {
                                  SelectScreeningQuestion(key); // Trigger the action only if not disabled
                                }
                              }}                            
                        >
                            {key} +
                        </Button>
                    ))}
                    <Button
                        className="!p-[12px] !shadow-lg text-[14px] !rounded-full bg-teal-50 ml-[12px] text-nowrap mt-[12px]"
                        onClick={handleCustom}
                    >
                        Custom +
                    </Button>
                </div>
            </div>
            <div>
            {Object.values(jobCreationData.questions).map((item, index) => (
                    <Card key={index} CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end" CardBodyClass={"gap-8"}
                        CardClass="min-w-md bg-white relative shadow rounded-lg w-[1175px]  mt-7 !rounded-xl"
                        body={<>
                            <div className="w-[99%]">
                            <div className="w-[8%] h-[34%] relative  left-[97.7%] top-[4px]">
                         <svg xmlns="http://www.w3.org/2000/svg" className="absolute hover:cursor-pointer" onClick={()=>handleDelete(index)} x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
                            <path d="M 24 4 C 20.491685 4 17.570396 6.6214322 17.080078 10 L 10.238281 10 A 1.50015 1.50015 0 0 0 9.9804688 9.9785156 A 1.50015 1.50015 0 0 0 9.7578125 10 L 6.5 10 A 1.50015 1.50015 0 1 0 6.5 13 L 8.6386719 13 L 11.15625 39.029297 C 11.427329 41.835926 13.811782 44 16.630859 44 L 31.367188 44 C 34.186411 44 36.570826 41.836168 36.841797 39.029297 L 39.361328 13 L 41.5 13 A 1.50015 1.50015 0 1 0 41.5 10 L 38.244141 10 A 1.50015 1.50015 0 0 0 37.763672 10 L 30.919922 10 C 30.429604 6.6214322 27.508315 4 24 4 z M 24 7 C 25.879156 7 27.420767 8.2681608 27.861328 10 L 20.138672 10 C 20.579233 8.2681608 22.120844 7 24 7 z M 11.650391 13 L 36.347656 13 L 33.855469 38.740234 C 33.730439 40.035363 32.667963 41 31.367188 41 L 16.630859 41 C 15.331937 41 14.267499 40.033606 14.142578 38.740234 L 11.650391 13 z M 20.476562 17.978516 A 1.50015 1.50015 0 0 0 19 19.5 L 19 34.5 A 1.50015 1.50015 0 1 0 22 34.5 L 22 19.5 A 1.50015 1.50015 0 0 0 20.476562 17.978516 z M 27.476562 17.978516 A 1.50015 1.50015 0 0 0 26 19.5 L 26 34.5 A 1.50015 1.50015 0 1 0 29 34.5 L 29 19.5 A 1.50015 1.50015 0 0 0 27.476562 17.978516 z"></path>
                            </svg>
                         </div>
                         {isCustom[index] ? <Textinput label="Enter Question:" labelclass="font-[500] text-[20px]" 
                         className={` w-full bg-teal-50 ${
                            validationErrors[index] ? "border-red-500" : "outline-none"
                          }`}
                          
                         value={item.question_desc}
                        //  readOnly={!item.isCustom}
                         onChange={(e) => updateQuestionAndRequired(index, e.target.value)}
                         /> : <h4>{item.question_desc}</h4>}
                         
                         </div>
                         
                         <Textinput type="checkbox" labelclass="hidden" 
                           checked={jobCreationData.questions && jobCreationData.questions[index]?.minimum !== undefined} 
                         onChange={()=>handleCheckBox(index)} className="float-left  !w-[2.2%] mt-[1px]" postlabel="Minimum Requirement" postlabelclass="ml-2 mb-0" divclass="flex mt-[12px]"/>
                         {(jobCreationData.questions && jobCreationData.questions[index]?.minimum !== undefined &&
                         <div>
                         <div className="">
                         <Textinput labelclass="hidden" value={jobCreationData.questions[index]?.minimum || ""} onChange={(e) => updateMinimum(index, e.target.value)} className="!w-[15%] bg-teal-50 !rounded-[6px] h-[5.5%] mt-[1px] p-[0.5%]" postlabelclass="ml-2 mb-0 mt-0.5" postlabel="Minimum" divclass="flex mt-[12px] h-[1.5%]"/>
                         </div>
                          <div className="mt-2">
                            <Selectbox label="Response Type" list={dataTypes} value={selecedDataType} onChange={handleSelectDataType} className="!w-[20%] mt-2 pt-[12px] pr-[16px] pb-[12px] pl-[16px] !outline-none"/>
                          </div>
                          </div>)}
                          <div className={`w-[12%] h-[6%]  left-[87%] top-[${requiredControl}%] float-right`}><Toggle onChange={(e) => handleRequired(index,e.target.value)} toggled={jobCreationData.questions && jobCreationData.questions[index]?.required === true} className="ml-2 " spanbefore="Required"/></div>
                         </>}
                    />
                ))}
            </div>
        </div>
    );
};

export default Jobscreening;
