import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
// Router Import
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import RegisterForm from "./pages/SignUp";
import RecruiterProfile from "./pages/Recruiter/Profile";
// import ProjectsBar from "./components/elements/ProjectSidebar/ProjectSideBar";
import ProjectPipe from "./pages/Piplines/ProjectPipeline";
import AddTeam from "./pages/AddTeam/UserSettings";
import AddTeamMember from "./pages/AddTeam/TeamMember";
import CompanyDetail from "./pages/CompayProfiles/CompanyAdd";
import CompanySummary from "./pages/CompayProfiles/CompanySummary";
import NotFound from "./pages/NotFound";  // Import the NotFound page
import GmailSI from "./pages/GmailSignIn";
import HomeSearch from "./pages/HomeSearchResults/HomeSearchResults";
import ResumeBox from "./pages/ResumeBox/ResumeBox";
import JobSeekerOnboard from "./pages/JobSeeker/Onboard";
// Context Import
import { AuthProvider, useAuth } from "./context/AuthContext";
import Team from "./pages/TeamMember/TeamMember";
import JobFilter from "./pages/JobListing/JobListing";
import NewJobPost from "./pages/JobPost/JobPost";
import AiJobPost from "./pages/JobPost/AiJobPost";
import JobDashboard from "./pages/JobPost/JobDashboard";
import { CommonContextProvider } from "./context/CommonContext";
import JobCreation from "./pages/JobCreation/JobCreation";
import JobApplicant from "./pages/Applicants/Applicants";
// Custom Auth Route Component
const AuthRoute = ({ element, path }) => {
  const { token } = useAuth(); // Get the token from context

  // If the path is '/SignUp', always allow access
  if ((path === "/signup" ) && !token && path === "/") {
    return element;// <Navigate to="/signUp" />;
  }

  // If token exists, allow access to other routes, otherwise redirect to '/'
   return token ? element : <Navigate to="/" />;
 // return element;
};
// Custom Redirect Route Component
const RedirectRoute = ({ element }) => {
  const { isAuthenticated } = useAuth(); // Check if the user is authenticated
  const location = useLocation();

  if (isAuthenticated && (location.pathname === "/" || location.pathname === "/SignUp")) {
    return <Navigate to="/Dashboard" />;
  }

  return element;
};

const AppContent = () => {
  const { verifyUserToken } = useAuth();
  const location = useLocation();

  // Verify token on location change
  useEffect(() => {
    verifyUserToken();
  }, [location,verifyUserToken]);

  return (
    <Routes>
      <Route path="/" element={<RedirectRoute element={<SignIn />} />} />
    <Route path="/SignUp" element={<RedirectRoute element={<RegisterForm />} />} />
      <Route path="/Dashboard" element={<AuthRoute element={<Dashboard />} />} />
     <Route path="/AddCompany" element={<AuthRoute element={<CompanyDetail />} />} />
      <Route path="/UserSettings" element={<AuthRoute element={<AddTeam />} />} />
      <Route path="/TeamMember" element={<AuthRoute element={<AddTeamMember />} />} />
      <Route path="/CompanyOverview" element={<AuthRoute element={<CompanySummary />} />} />
      <Route path="/Recruiter/Profile" element={<AuthRoute element={<RecruiterProfile />} />} />
      {/* <Route path="/sideview" element={<AuthRoute element={<ProjectsBar />} />} /> */}
      <Route path="/Projects" element={<AuthRoute element={<ProjectPipe />} />} />
      <Route path="/JobListing" element={<AuthRoute element={<JobFilter />} />} />
      <Route path="/NewJobPost" element={<AuthRoute element={<NewJobPost />} />} />
      <Route path="/HomeSearchResults" element={<AuthRoute element={<HomeSearch />} />} />
      <Route path="/AiJobPost" element={<AuthRoute element={<AiJobPost />} />} />
      <Route path="/JobDashboard" element={<AuthRoute element={<JobDashboard />} />} />
      <Route path="/JobSeeker" element={<AuthRoute element={<JobSeekerOnboard />} />} />
      <Route path="/JobCreation" element={<AuthRoute element={<JobCreation />} />} />
    {/* Catch-all route for undefined routes */}
  <Route path="/GmailSignIn" element={<RedirectRoute element={<GmailSI />} />} />
  <Route path="/Resume" element={<RedirectRoute element={<ResumeBox />} />} />
	  {/* Catch-all route for undefined routes */}
       <Route path="*" element={<NotFound />} /> 
       <Route path="/Applicants" element={<AuthRoute element={<JobApplicant />} />} />
	  </Routes>
  );
};

const App = () => {
  return (
    <React.StrictMode>
    <AuthProvider>
      <CommonContextProvider>
        <AppContent />
      </CommonContextProvider>
    </AuthProvider>
    </React.StrictMode>
  );
};

export default App;

