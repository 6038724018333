import React, { useEffect, useContext, useState } from "react";
import Card from "../elements/Card/Card";
import Button from "../elements/Button/Button";
import Webenza from "../../assets/Images/webenza.jpg"
import Vector from "../../assets/Images/Vector.png"
import person from "../../assets/Images/person.jpg"
import Linkedin from "../../assets/Images/linkedin.jpg";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from 'crypto-js';
import { useLocation } from "react-router-dom";
    
const ProjectJob = () =>{
    const { token } = useContext(AuthContext);
    const [jobArray,setJobPOst]=useState([]);
    const location = useLocation();
    const [teamArray,setTeam]=useState([]);
    const jobId = location.state?.jobId;
    const listJobDetails = async () =>{

        const job_id={job_id : jobId};
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(job_id), process.env.REACT_APP_SECRETKEY).toString();
        var encryptjson = { data:encryptedData}
        const serverURL = process.env.REACT_APP_SERVER_URL;
                      const response = await fetch(`${serverURL}/job/fetchJobDetails`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            token: token
                          },
                          body: JSON.stringify(encryptjson),
                      });
                      const data = await response.json();
              
                      const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                      setJobPOst(decryptedData["job details"]);
                      setTeam(decryptedData["job_team"]);
                    
    }
    useEffect(()=>{
        listJobDetails()        
    },[]);
    
    
    // const listTeamDetails = async () =>{
    //     const serverURL = process.env.REACT_APP_SERVER_URL;
    //                   const response = await fetch(`${serverURL}/job/fetchTeamDetails`, {
    //                       method: 'POST',
    //                       headers: {
    //                         'Content-Type': 'application/json',
    //                         token: token
    //                       },
    //                   });
    //                   const data = await response.json();
              
    //                   const teamdecryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
    //                   console.log("Team Details: ",teamdecryptedData);
    //                   setTeam(teamdecryptedData);
    // }
    // useEffect(()=>{
    //     listTeamDetails()        
    // },[]);


    let userName;
    const encryptedMetadata = localStorage.getItem('metadata');
           // console.log(encryptedMetadata,"encryptedMetadatai ")
              if (encryptedMetadata){// && encryptedMetadata.data) {
               // console.log(encryptedMetadata,"encryptedMetadata")
                  const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                userName=decryptedMetadata.name;
                console.log("currrr: ",decryptedMetadata);
            }

    return(
        <div className="w-[70%] ml-14 rounded-xl bg-white bg-opacity-50 p-[30px] grid gap-4">
        {Array.isArray(jobArray) &&
            jobArray.map((item,i) =>(
                <div key={i}>
           <Card CardClass="!rounded-2xl p-2 border-1 border-white shadow-md"
           body={
            <>
            <div className="flex">
            <div className="w-[80%]">
                <div className="flex">
                    <div>
                    <p className="text-[24px] font-[500] mb-0">{item.title?item.title:''}</p></div>
                    <div className=" mt-[12px] ">
                    <p className="ml-4 text-[10px] rounded-3xl mb-0 pl-2.5 pr-3.5 bg-green-200">● Active</p>
                    </div>
                </div>
                <div>
                     <p className="text-[16px] mb-0">Created By: <span className="font-[400]">{userName}</span> ● Posted: {item.created_at}</p>
                     <p className="text-[16px] mb-0">Location: <span className="font-[400]">{item.location?item.location:''}</span></p>
                     <p className="text-[16px] mb-0">Workspace: <span className="font-[400]">{item.workplace_type?item.workplace_type:''}</span></p>
                     {/* <p className="text-[16px]">Project: <span className="font-[400]">{`UX Designer`}</span></p> */}
                 </div>
             </div>
             <div className="w-[50%] mt-2 justify-end flex hidden">
                 <div className="">
             <Button className="bg-[#059669]  pl-4 pr-4 h-[40px] text-white">View</Button></div>
             <div className="mt-2 h-6">
            
            
             <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={20}
            height={20}
            className="mt-1 ml-6 bg-tean-500 rounded-full"
            fill="black"
            aria-hidden="true"
            >
            <circle cx="5" cy="12" r="2" />
            <circle cx="12" cy="12" r="2" />
            <circle cx="19" cy="12" r="2" />
            </svg>
            </div>
            </div>
            </div>
            </>
           }
           />
           <Card CardClass="!rounded-2xl p-2 mt-4 border-1 border-white shadow-md" CardBodyClass="grid gap-4"
                body={
                    <>
                     <div className="border-2 border-gray-50 rounded-xl p-3 shadow-md">
                        <div className="flex gap-4 pb-6  border-b-2 border-gray-200">
                            <div className="py-3">
                            <img src={Webenza} className="w-12 h-12 border-1 border-gray-300 rounded-lg"/>
                            </div>
                            <div>
                                <p className="text-[16px] text-gray-400 mb-0">{item.department?item.department:''}</p>
                                <p className="text-[24px] font-[500] mb-0">{item.title?item.title:''}</p>
                                <div className="flex gap-2 mt-2">
                                    <p className="bg-teal-50 p-1 text-xs rounded-xl">2-5 years</p>
                                    <p className="bg-teal-50 p-1 text-xs rounded-xl">On-Site</p>
                                    <p className="bg-teal-50 p-1 text-xs rounded-xl">{item.location?item.location:''}</p>
                                    <p className="bg-teal-50 p-1 text-xs rounded-xl">8LPA</p>
                                </div>
                            </div>
                            <div className="w-[30%] ml-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" className=" float-right mt-3" y="0px" width="20px" height="20px" viewBox="0 0 24 24">
                            <path d="M 6.0097656 2 C 4.9143111 2 4.0097656 2.9025988 4.0097656 3.9980469 L 4 22 L 12 19 L 20 22 L 20 20.556641 L 20 4 C 20 2.9069372 19.093063 2 18 2 L 6.0097656 2 z M 6.0097656 4 L 18 4 L 18 19.113281 L 12 16.863281 L 6.0019531 19.113281 L 6.0097656 4 z"></path>
                            </svg>
                            </div>
                        </div>
                        <div className="flex mt-3">
                            <div>
                                <img src={Vector}/>
                            </div>
                            <div className="">
                                <p className="text-xs mb-0">job matchrate: {`high`}</p>
                                <p className="font-medium text-[18px]">You were born for something like this! </p>
                            </div>
                            <div className="w-1/3 ml-auto">
                                <Button className="float-right bg-[#059669] text-white w-[130px] h-[40px]">Apply Now</Button>
                            </div>
                        </div>
                     </div>
                     <div className="border-2  border-gray-50 rounded-xl p-3 shadow-md">
                        <p className="text-lg font-medium">Job Description</p>
                        <p className="text-[14px] text-[#747474] justify-content">{item.job_desc?item.job_desc:''}</p>
                     </div>
                     <div className="border-2 border-gray-50 rounded-xl p-3 shadow-md">
                            <p className="text-lg font-medium">Skills and Requirements</p>
                            {/* Check if item.skills is an array */}
                            {Array.isArray(item.skills) ? (
                                item.skills.map((val, k) => (
                                    <li
                                        className="text-[14px] text-[#747474]"
                                        key={k}
                                    >
                                        {val}
                                    </li>
                                ))
                            ) : (
                                <p className="text-[14px] text-[#747474]">
                                    No skills listed
                                </p>
                            )}
                        </div>
                     <div className="border-2  border-gray-50 rounded-xl p-3 shadow-md">
                     <p className="text-lg font-medium">Your Role</p>
                        <li className="text-[14px] text-[#747474]">Lead and develop key projects</li>
                        <li className="text-[14px] text-[#747474]">Collaborate with cross-functional teams</li>
                        <li className="text-[14px] text-[#747474]">Review code and mentor juniors</li>
                        <li className="text-[14px] text-[#747474]">Optimize and manage cloud applications</li>
                     </div>
                     <div className="border-2  border-gray-50 rounded-xl p-3 shadow-md">
                     <p className="text-lg font-medium">Benefits</p>
                        <li className="text-[14px] text-[#747474]">Competitive Salary</li>
                        <li className="text-[14px] text-[#747474]">Flexible Remote Work</li>
                        <li className="text-[14px] text-[#747474]">Health Insurance</li>
                        <li className="text-[14px] text-[#747474]">Learning and Development Opportunities</li>
                        <li className="text-[14px] text-[#747474]">Work-Life Balance</li>
                        <li className="text-[14px] text-[#747474]">Annual Bonus</li>
                     </div>
                     <div className="border-2  border-gray-50 rounded-xl p-3 shadow-md">
                        <p className="text-lg font-[500]">Meet Your Team</p>
                        <div className="flex gap-[26px]">
                        {Array.isArray(teamArray) &&
                        teamArray
                            .map((teamMember, g) => (
                                <div className="grid gap-[20px] relative" key={g}>
                                    <img
                                        src={person}
                                        className="w-[120px] h-[120px] rounded-full object-cover"
                                    />
                                    <div className="bg-white p-[11.05px] absolute left-[70%] top-[40%] w-[35px] h-[35px] rounded-full">
                                        <img src={Linkedin} alt="LinkedIn" />
                                    </div>
                                    <div className="grid gap-[8px]">
                                        <p className="text-base font-[500] mb-0 text-center">
                                            {teamMember.name ? teamMember.name : ""}
                                        </p>
                                        <p className="text-xs font-[400] text-center">
                                            {teamMember.designation
                                                ? teamMember.designation
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            ))}

                        </div>
                     </div>
                     <div className="border-2 grid gap-4 border-gray-50 rounded-xl p-3 shadow-md">
                     <div className="flex gap-4  border-b-2 border-gray-200 items-center">
                            <div className="py-3">
                            <img src={Webenza} className="w-12 h-12 border-1 border-gray-300 rounded-lg"/>
                            </div>
                            <div className="items-center justify-center">
            
                                <p className="text-[24px] font-[500] mb-0">{item.department?item.department:''}</p>
                            </div>
                            <div className="w-[30%] ml-auto">
                            
                            </div>
                         </div>
                         <div>
                             <p className="text-sm font-[400] text-[#747474]">Aerolite is a leading development company specializing in cutting-edge solutions for web, mobile, and cloud-based applications. With a focus on innovation and quality, Aerolite partners with global clients to deliver high-performance software products tailored to their needs. Our team of experts excels in crafting scalable, efficient, and future-ready solutions, ensuring businesses thrive in the digital age. At Aerolite, we are committed to pushing the boundaries of development to create technology that drives success.</p>
                         </div>
                      </div>
                     </>
                }
           /> 
           </div>
           ))}
        </div>
    );
};
export default ProjectJob;