import React from "react";
import ProjectDashboard from "../../components/JobListing/JobListing";
import Topbar from "../../components/Recruiter/Topbar/Topbar";

const JobFilter = () =>{
    return(
        <div className="radial-gradient-bg">
            <Topbar/>
            <ProjectDashboard/>
        </div>
    )
}
export default JobFilter;