import React from 'react';
const Selectbox = (props)=>{
    return (
        <>
            <label className={`block ${props.labelClass}`}>{props.label || ''}<span className="text-red-600">{props.span || ''}</span></label>
            <select
                ref={props.InputRef}
                onChange={props.onChange || (()=> {})}
                value={props.value || ''}
                name={props.name || ''}
                className={`profile_sidebar_progress_text w-full px-2 border-1 border-blue-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${props.className || ''}`}
                required = {props.required || false}
            >
                {props.list && props.list.map((option) => (
                    
                    <option key={option.id} value={option.id}>
                        {option.label}
                        
                    </option>
                ))}
            </select>
        </>
    )
}
export default Selectbox;