import React,{useState,useEffect,useRef,useContext} from "react";
import Button from "../elements/Button/Button";
import Card from "../elements/Card/Card";
import Textinput from "../elements/TextInput/TextInput";
import RadioInput from "../elements/RadioInput/RadioInput";
import Selectbox from "../elements/SelectBox/SelectBox";
import Person from "../../assets/Images/person.jpg"
import { useCommonContext } from '../../context/CommonContext';
import { useNavigate } from "react-router-dom";
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../context/AuthContext";
const HomeSearchProfiles = () =>{
    const { searchTerm, setSearchTerm, setIsAIsearch, isAIsearch, showUpload, setShowUpload,aiSearchResult,setAiSearchResult } = useCommonContext();
    const [isOpen, setIsOpen] = useState('');
    const [ResumeDetails,setResumeDetails] = useState([]);
    const navigate = useNavigate()
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [processingMessage, setProcessingMessage] = useState('No resume data available.');
    const { token } = useContext(AuthContext);
    const [locationArr,setLocationArr] = useState([]);
    const[seeLocations,showMoreLocations]=useState(false)
    const [seeAllText, setSeeAllText] = useState({'location': 'view all'});
    const [checkedValue, setcheckedValue] = useState({});
    const [JDvalue,setJDvalue] = useState("");
    const showNotification = (message, type) => {   //For Notification
        setNotification({ message, type });
        setTimeout(() => {
          setNotification({ message: "", type: "" });
        }, 3000); // Auto-hide after 3 seconds
    };
      
        const toggleDropdownPipeline = (index) => {
          setIsOpen((prev) => prev===index?null:index);
          console.log(ResumeDetails['891fcc57bccb4fc3a21a2bc84a2f346b']['candidate']['name'])
        };
        const [isOpenMessage, setIsOpenMessage] = useState(false);
      
        const toggleDropdownMessage = (index) => {
          setIsOpenMessage((prev) =>  prev===index?null:index);
        };
        const [filterOpen, setfilter]=useState(false);
        const handleAllFilters = () =>{
          setfilter(true)
        }
  const sortTypes=[
    {id:'0',label:'Last viewed by me'},
    {id:'1',label:'Date posted: Newest to oldest'},
    {id:'2',label:'Date posted: oldest to Newest'}
  ]
  const dropdownRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown and the dropdown is open
    if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
    }
};
useEffect(() => {
    getResponse();
    //console.log(aiSearchResult)
    //setResumeDetails(aiSearchResult.resumedetail)   
    // Attach the event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        // Cleanup the event listener on component unmount
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [isOpen]);

useEffect(() => {
    setcheckedValue(prevText => ({
      ...prevText,
      locations: locationArr
    }));
}, [locationArr]);

useEffect(() => {
    filterData();
}, [checkedValue]);

useEffect(() => {
    setcheckedValue(prevText => ({
      ...prevText,
      jd_id: JDvalue
    }));
}, [JDvalue]);

const filterData = async () => {
  
  console.log(checkedValue,"checked");
  if(checkedValue.jd_id == ""){
    return;
  }
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(checkedValue), process.env.REACT_APP_SECRETKEY).toString();
    var searchData = { data:encryptedData}
    setIsLoading(true);
    try {
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(`${serverURL}/JD/filterResume`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              token: token
            },
            body: JSON.stringify(searchData), 
        });
        const result = await response.json();
        console.log("Server Response:", result);
        if (!response.ok) {
            throw new Error(result.error);
        }
        const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedData['message']){
            setProcessingMessage(decryptedData['message'])
            return;
        }
       console.log(decryptedData,"diccc");
        setAiSearchResult(decryptedData[JDvalue]);
        setResumeDetails(decryptedData[JDvalue]['resumedetail'])
        // console.log(decryptedData)
        showNotification(result.message, "success");
    } catch (error) {
        console.error("Error uploading file:", error);
        showNotification(error.message, "error");
    }finally {
        setIsLoading(false); // Always hide loader after the request
    }
  };

  const Locations=[
    "chennai",
    "kolkata",
    "Banglore",
    "kerala",
    "coimbatore",
    "Delhi",
    "kannur"
  ]
  const assessments=[
    "Project Management",
    "Testing",
    "Programming",
    "Code Development",
    "Web Development",
    "System Design",
  ]
  const Companies=[
    "Amazon",
    "Amazon Logistics",
    "TCS",
    "ZOHO",
    "WIPRO",
    "Atlassian",
    "Google"
  ]

  const Keywords=[
    "Ananya (17)",
    "Jhon Doe",
    "micheal",
    "antony",
    "Raj",
    "David"
  ]
  const Degrees =[
    "B.Tech",
    "Master Degree",
    "Post Graduate",
    "Diploma"
  ]
  const [projects, setProjects] = useState([
    {
      id: 1,
      createdBy: "Ananya",
      postedDate: "24/09/2024",
      workplace: "Alchemy",
      jobTitle: "Senior UX Designer",
      status: "Active",
      pipelineCount: 17,
      applicants: { total: 15, new: 5 },
    },
    {
      id: 2,
      createdBy: "Jhon Doe",
      postedDate: "20/09/2024",
      workplace: "NovaByte Solutions",
      jobTitle: "Frontend Developer",
      status: "Inactive",
      pipelineCount: 10,
      applicants: { total: 8, new: 3 },
    },
    {
      id: 3,
      createdBy: "Sarah Connor",
      postedDate: "18/09/2024",
      workplace: "ZenithSoft Labs",
      jobTitle: "Backend Engineer",
      status: "Active",
      pipelineCount: 12,
      applicants: { total: 6, new: 2 },
    },
  ]);

  const toggleState = () => {
  showMoreLocations(prevState => {
    const newShowMoreState = !prevState;
    setSeeAllText(prevText => ({
      ...prevText,
      location: newShowMoreState ? 'view less' : 'view all'
    }));
    return newShowMoreState;
  });
};
  const[seeAssessments,showMoreAssessments]=useState(false)
  const toggleAssesment = () => {
    showMoreAssessments((prevState) => !prevState);
    
  };
  const[seeCompanies,showMoreCompanies]=useState(false)
  const toggleCompany = () => {
    showMoreCompanies((prevState) => !prevState);
    
  };
  const[seeKeywords,showMoreKeywords]=useState(false)
  const toggleKeywords = () => {
    showMoreKeywords((prevState) => !prevState);
    
  };
  const[seeDegree,showMoreDegrees]=useState(false)
  const toggleDegree = () => {
    showMoreDegrees((prevState) => !prevState);
    
  };
  const goToSearch = () =>{
    navigate("/Dashboard")
  }
  const refreshJD = async() =>{
    var refreshData = {};
    if(aiSearchResult && aiSearchResult.basicdetail){
        refreshData = aiSearchResult.basicdetail;
    }
    console.log(aiSearchResult.basicdetail)

    if(!aiSearchResult || !aiSearchResult.basicdetail || !aiSearchResult.basicdetail.jd_id || !aiSearchResult.basicdetail.userId || !aiSearchResult.basicdetail.cid ){
        showNotification("Somedetails missing", "error");
        return
    }
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(refreshData), process.env.REACT_APP_SECRETKEY).toString();
            var searchData = { data:encryptedData}
            setIsLoading(true);
            try {
                const serverURL = process.env.REACT_APP_SERVER_URL;
                const response = await fetch(`${serverURL}/JD/refreshJd`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      token: token
                    },
                    body: JSON.stringify(searchData), 
                });
                const result = await response.json();
                console.log("Server Response:", result);
                if (!response.ok) {
                    throw new Error(result.error);
                }
                const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
                // console.log(decryptedData);
                setAiSearchResult(decryptedData);
                console.log(decryptedData)
                showNotification(result.message, "success");
            } catch (error) {
                console.error("Error uploading file:", error);
                showNotification(error.message, "error");
            }finally {
                setIsLoading(false); // Always hide loader after the request
            }
  }



  const getResponse = async() =>{
    var jd_id = ""
    const encryptedMetadata = localStorage.getItem('metadata');
    if (encryptedMetadata){
        const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedMetadata.jd_id){
            jd_id = decryptedMetadata.jd_id;
        }
    }
    setJDvalue(jd_id);
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({jd_id:jd_id}), process.env.REACT_APP_SECRETKEY).toString();
    var searchData = { data:encryptedData}
    setIsLoading(true);
    try {
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(`${serverURL}/JD/getJdDetail`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              token: token
            },
            body: JSON.stringify(searchData), 
        });
        const result = await response.json();
        console.log("Server Response:", result);
        if (!response.ok) {
            throw new Error(result.error);
        }
        const decryptedData = JSON.parse(CryptoJS.AES.decrypt(result.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
        if(decryptedData['message']){
            setProcessingMessage(decryptedData['message'])
            return;
        }
	     console.log(decryptedData,"diccc");
        setAiSearchResult(decryptedData[jd_id]);
        setResumeDetails(decryptedData[jd_id]['resumedetail'])
        // console.log(decryptedData)
        showNotification(result.message, "success");
    } catch (error) {
        console.error("Error uploading file:", error);
        showNotification(error.message, "error");
    }finally {
        setIsLoading(false); // Always hide loader after the request
    }
  }


  const locationCheckboxChange = (id, isChecked) => {
    console.log(id, isChecked);
    setLocationArr(prevItems => {
      if (isChecked) {
        return [...prevItems, Locations[id]];
      } else {
        return prevItems.filter(item => item !== Locations[id]);
      }
    });
  }

    const isResumeDetailsEmpty = !ResumeDetails || Object.keys(ResumeDetails).length < 1;
    var totalResumeCount = 0;
    if(!isResumeDetailsEmpty){
        totalResumeCount = Object.keys(ResumeDetails).length
    }

    return(<>
        <div>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="flex flex-col items-center">
                        <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        <span className="text-white mt-4">Fetching...</span>
                    </div>
                </div>
            )}
            <Notification
                message={notification.message}
                type={notification.type}
                onClose={() => setNotification({ message: "", type: "" })}
            />
            <div className="bg-white p-4 m-4 rounded-lg flex gap-2 items-center">
                <div className="mb-0 flex gap-2 w-9/12">
                    <p className="mb-0 w-1/12">Filter By :</p>
                    <p className="mb-0 w-11/12">{aiSearchResult && aiSearchResult.basicdetail && aiSearchResult.basicdetail.search?aiSearchResult.basicdetail.search:"No Filter"}</p>
                </div>
                <div className="flex justify-end w-3/12 gap-2 items-center">
                    <Button
                      type="button"
                      className="bg-green-700 px-4 py-2 text-white"
                      onClick={goToSearch}
                      children="Go To Search"
                    />
                    <Button
                      type="button"
                      className="bg-transparent border-[1px] border-green-700 text-green-700 px-4 py-2"
                      onClick={refreshJD}
                      children="Refresh"
                    />
                </div>
            </div>
        <div className="flex">
            <div className="w-[24%] bg-white p-2 ml-6">
                <div className="flex px-4"><p className="text-[21px] font-bold mt-4">Filters</p>
                <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={20}
                height={20}
                className="ml-auto mt-[30px]"
                fill="black"
                aria-hidden="true"
                >
                <circle cx="5" cy="12" r="2" />
                <circle cx="12" cy="12" r="2" />
                <circle cx="19" cy="12" r="2" />
                </svg></div>
                <Card CardFooterClass="px-6 py-2 bg-gray-100 justify-end gap-2 "  CardClass="min-w-md mx-auto  bg-white shadow rounded-lg overflow-hidden w-11/12  mt-2 !rounded-xl"
                    body={<>
                    <Textinput placeholder="Search for a Project" labelclass="hidden" className="!w-full border-2 pl-10 pr-4 py-2.5 !border border-gray-150 rounded-md focus:outline-none focus:ring-2 text-black mt-1 focus:ring-cyan-200"/>
                    <div className="absolute h-10 inset-y-[32%]  flex pt-1  pl-3">
                    <svg
                      className="w-5 h-5 text-blue-500"
                      fill="none" 
                      stroke="#9effef"  
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a7 7 0 105.197 12.197l4.386 4.386a1 1 0 001.414-1.414l-4.386-4.386A7 7 0 0011 4z"
                      />
                    </svg>
                    
                </div>
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Location</p>
                    {
                        Locations.slice(0,3).map((location,index) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={location}
                            postlabelclass="ml-2"
                            key={location}
                            onChange={e => locationCheckboxChange(index, e.target.checked)}
                            />
                        ))
                    }
                    
                    {seeLocations &&
                    Locations.slice(3).map((location,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={location}
                        postlabelclass="ml-2"
                        key={location}
                        onChange={e => locationCheckboxChange(index + 3, e.target.checked)}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleState}>{seeAllText['location']}...</p>
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Skills and assessments</p>
                    {
                        assessments.slice(0,4).map((assessments) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={assessments}
                            postlabelclass="ml-2"
                            key={assessments}
                            />
                        ))
                    }
                    
                    {seeAssessments &&
                    assessments.slice(4).map((assessments,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={assessments}
                        postlabelclass="ml-2"
                        key={assessments}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleAssesment}>view all...</p>
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Companies</p>
                    {
                        Companies.slice(0,2).map((company) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={company}
                            postlabelclass="ml-2"
                            key={company}
                            />
                        ))
                    }
                    
                    {seeCompanies &&
                    Companies.slice(2).map((company,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={company}
                        postlabelclass="ml-2"
                        key={company}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleCompany}>view all...</p>
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Keywords</p>
                    {
                        Keywords.slice(0,2).map((keyword) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={keyword}
                            postlabelclass="ml-2"
                            key={keyword}
                            />
                        ))
                    }
                    
                    {seeKeywords &&
                    Keywords.slice(2).map((keyword,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={keyword}
                        postlabelclass="ml-2"
                        key={keyword}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleKeywords}>view all...</p>
                    
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Degrees</p>
                    {
                        Degrees.slice(0,3).map((degree) => (
                            <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel={degree}
                            postlabelclass="ml-2"
                            key={degree}
                            />
                        ))
                    }
                    
                    {seeDegree &&
                    Degrees.slice(3).map((degree,index) => (
                        <Textinput
                        type="checkbox"
                        labelclass="hidden"
                        className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                        divclass="flex h-6 mt-2"
                        postlabel={degree}
                        postlabelclass="ml-2"
                        key={degree}
                        />
                    ))}

                    <p className="text-sm hover:cursor-pointer mt-2 text-[#059669]" onClick={toggleDegree}>view all...</p>
                    
                    </>}
                />
                <Card CardFooterClass="px-2 py-2 bg-gray-100 flex justify-end  "  CardClass="min-w-md mx-auto bg-white shadow rounded-lg overflow-hidden w-11/12  mt-7 !rounded-xl"
                    body={<>
                    <p className="mb-0 font-medium">Candidates</p>
                    <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel="All"
                            postlabelclass="ml-2"
                            />
                             <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel="Eeezo Profiles"
                            postlabelclass="ml-2"
                            />
                             <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-1/12 w-5 h-5 mb-0 mt-1"
                            divclass="flex h-6 mt-2"
                            postlabel="Resumebox"
                            postlabelclass="ml-2"
                            />
                    </>}
                />
                
            </div>
            <div className="w-[72%] bg-white rounded-xl bg-opacity-50 p-1 ml-4">
             <div className="mt-4 px-[56px]">
             <Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-4 !h-4 mb-0 mt-2 rounded-xl"
                            divclass="flex h-6 mt-2"
                            postlabel={`${totalResumeCount} Matching Profiles Found`}
                            postlabelclass="ml-4 mb-0 text-[20px] font-bold"
                            />
            </div>
            <div id="divid">
          {projects.map((project,index) => (
            <Card CardFooterClass="py-2 bg-gray-100 flex justify-end gap-2 " CardClass="min-w-md mx-auto px-2 bg-white shadow rounded-lg overflow-hidden mt-4 mb-4 !rounded-xl shadow-none border w-[850px] !hidden"
            body={<>
            <div className="flex">
            <div className="w-[60%]">    
            <div className="flex ml-2">
                <div className="flex"><Textinput
                            type="checkbox"
                            labelclass="hidden"
                            className="text-lg !w-4 !h-4 mb-0 mt-10 rounded-xl"
                            divclass="flex h-6 mt-2"
                            /><img src={Person} className="w-[86px] h-[86px] rounded-full object-cover ml-4 mt-2"></img>
                </div>
                

                <div className="ml-4">
                    <p className="text-xl font-medium text-black mt-3 mb-0">Sharad Bhat</p>
                    <p className="text-sm mb-0">UI/UX Designer</p>
                    <p className="text-xs mt-1">Bengaluru,Karnataka</p>
                </div>
            </div>
            <div className="mt-4 flex">
              <p className="ml-2 text-black text-[12px]">Experience</p>
              <div className="ml-14">
                <p className="text-[#000000] opacity-50 mb-0 text-[12px] font-medium">4+ years of experience in UX design</p>
                <p className="text-black text-[12px] flex mb-0"><span className="text-green-700 font-medium mr-1">Senior UX Designer</span> at NovaByte Solutions <li className="ml-2 mb-0 text-gray-700">Current</li></p>
                <p className="text-black text-[12px] flex mb-0">UX Designer CodeSphere Technologies <li className=" mb-0 text-gray-700 ml-2">2021-2022</li></p>
                <p className="text-black text-[12px] flex mb-0">UX Designer BlueShift Dynamics <li className=" mb-0 text-gray-700 ml-2">2019-2021</li></p>
                <p className="text-[10px] mt-1 flex opacity-60 hover:cursor-pointer">Show more ({8})<svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" className="mt-[5px] ml-[1px]" viewBox="0 0 8 5" fill="none">
                <path d="M4 4.5C3.85938 4.5 3.73438 4.45312 3.64062 4.35938L0.640625 1.35938C0.4375 1.17188 0.4375 0.84375 0.640625 0.65625C0.828125 0.453125 1.15625 0.453125 1.34375 0.65625L4 3.29688L6.64062 0.65625C6.82812 0.453125 7.15625 0.453125 7.34375 0.65625C7.54688 0.84375 7.54688 1.17188 7.34375 1.35938L4.34375 4.35938C4.25 4.45312 4.125 4.5 4 4.5Z" fill="#747474"/>
                </svg></p>
              </div>
              <div className="absolute ml-[560px] px-[16px] py-[16px] inset-28 mt-8  bg-green-50 shadow-lg shadow-none  w-[135px] h-[74px] pb-1  rounded-xl ">
                <div className="relative size-10 mt-1">
                <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">

                    <circle cx="18" cy="18" r="16" fill="none" className="stroke-current w-[42px] h-[42px] text-gray-200 dark:text-neutral-700" stroke-width="4"></circle>

                    <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-teal-400 w-[42px] h-[42px] dark:text-blue-500" stroke-width="4" stroke-dasharray="100" stroke-dashoffset="6" stroke-linecap="round"></circle>
                </svg>

                </div>
  
     
                <div className="absolute rounded-lg inset-2 ml-8  mr-2 flex   justify-center  text-gray-700 dark:text-white">
                    
                    <div className="h-14">
                      <p className="mb-0 text-black text-[24px] font-medium mt-1 ml-3">{94}%</p>
                      
                    </div>
                </div>
                <p className="text-[14px] mb-0 absolute inset-y-9 ml-12">Match</p>
                
                </div>
            </div>
            <div className="mt-2 flex">
              <p className="ml-2 text-black text-[12px]">Education</p>
              <div className="ml-14">
                
                <p className="text-black flex text-[12px] mb-0 pl-1">Bachelor of Design, Srishti Manipal Institute<li className=" mb-0 text-gray-700 ml-2">2014-2017</li></p>
                <p className="text-black flex text-[12px] mb-0 pl-1">Masters Strategic Design Management, NID<li className=" mb-0 text-gray-700 ml-2">2017-2019</li></p>
              </div>
            </div>
            <div className="mt-4 flex">
                <div className="w-[25%]">
              <p className="ml-2 text-black text-[12px]">Skills Match</p></div>
              <div className="whitespace-nowrap">
                
                <p className="flex text-[12px] text-green-700 mb-0 font-medium">User Research <li className="text-[12px] ml-2">User-Centered Design Thinking</li></p>
              </div>
            </div>
            <div className="mt-1 flex mb-4">
              <p className="ml-2 text-black text-[12px]">Activity</p>
              <div className="ml-16">
                
                <p className="text-black flex text-[12px] ml-2 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, Masters StrLorem ipsum dolor sit amet, consectetur adipiscing elit, ategic Design Management, NID</p>
                
              </div>
            </div>
            </div>
            <div className="w-[40%] mt-1 flex">
                <div className=" text-left">
  <div className="pl-8">
    <Button
      type="button"
      className="inline-flex w-[190px] justify-center gap-x-1.5 rounded-md bg-white px-3 border-green-500  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      id="menu-button"
      aria-expanded={isOpen}
      aria-haspopup="true"
      onClick={()=>toggleDropdownPipeline(index)}
    >
      <p className="mt-2 mb-0 text-[16px] font-medium text-green-600">Save to pipeline</p>
      <svg
        className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
        viewBox="0 0 20 20"
        fill="green"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
          clipRule="evenodd"
        />
      </svg>
    </Button>
  
  

  
  {isOpen===index && (
    <div
      className="absolute left-34  z-10 !w-[190px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div class="p-1 space-y-0.5">
  <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Uncontacted
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Contacted
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Replied
  </a>
  
</div>
    </div>
  )}
  {isOpenMessage===index && (
    <div 
      className="absolute left-[58%]  z-10 w-4/12 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div class="p-1 space-y-0.5">
  <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Archieve Candidate
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Add Note
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
  Share For Review
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
  Save To Another Project
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
  Save To Pdf
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
  Save To Another Project
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
  Find More People Like This
  </a>
  
</div>
    </div>
  )}
</div>

                </div>
                <Button type="button" className="!bg-gray-100 inline-flex ml-3 h-9 mt-1 justify-between px-2 py-2 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={()=>toggleDropdownMessage(index)}>
            <img src="/image/message.png" className='w-5' />
        </Button>
        <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-3 ml-2   text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
        <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={20}
                height={20}
                className="ml-auto mt-[30px]"
                fill="black"
                aria-hidden="true"
                >
                <circle cx="5" cy="12" r="2" />
                <circle cx="12" cy="12" r="2" />
                <circle cx="19" cy="12" r="2" />
                </svg>
</button>






            </div>
            </div>
            </>}
            />
          ))}
          {isResumeDetailsEmpty ? (
            <div>{processingMessage}</div>
          ) : (
            Object.entries(ResumeDetails).map(([project, index]) => (
          <Card CardFooterClass=" !bg-[#059669] !rounded-l-none w-1/5 ml-auto flex justify-end" CardClass="min-w-md mx-auto pl-2 bg-white shadow rounded-lg overflow-hidden mt-4 mb-4 !rounded-3xl shadow-none border w-[850px]"
           body={
            <>
            <div className="flex">
            <div className="w-[50%]">
            <p className="text-[24px] font-medium mb-0">{ResumeDetails[project] && ResumeDetails[project]['candidate'] && ResumeDetails[project]['candidate']['name']?ResumeDetails[project]['candidate']['name']:"Name Not Provided"}</p>
            <p className="text-[14px]">{ResumeDetails[project] && ResumeDetails[project]['candidate'] && ResumeDetails[project]['candidate']['objective']?ResumeDetails[project]['candidate']['objective']:"Objective Not Provided"}</p>
            <p className="text-[16px] font-semibold mb-0">Contact Details</p>
            
            <p className="flex text-[16px]"><div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" className="mt-2 mr-2" viewBox="0 0 15 11" fill="none">
<path d="M14 2V9C14 9.39782 13.842 9.77936 13.5607 10.0607C13.2794 10.342 12.8978 10.5 12.5 10.5H2.5C2.10218 10.5 1.72064 10.342 1.43934 10.0607C1.15804 9.77936 1 9.39782 1 9V2M14 2C14 1.60218 13.842 1.22064 13.5607 0.93934C13.2794 0.658035 12.8978 0.5 12.5 0.5H2.5C2.10218 0.5 1.72064 0.658035 1.43934 0.93934C1.15804 1.22064 1 1.60218 1 2M14 2V2.162C14 2.4181 13.9345 2.66994 13.8096 2.89353C13.6848 3.11712 13.5047 3.30502 13.2867 3.43933L8.28667 6.516C8.05014 6.66169 7.7778 6.73883 7.5 6.73883C7.2222 6.73883 6.94986 6.66169 6.71333 6.516L1.71333 3.44C1.49528 3.30569 1.31525 3.11779 1.19038 2.8942C1.06551 2.6706 0.999972 2.41876 1 2.16267V2" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              
              <p className="mt-[1px]">{ResumeDetails[project] && ResumeDetails[project]['candidate'] && ResumeDetails[project]['candidate']['email']?ResumeDetails[project]['candidate']['email']:"Email Not Provided"}</p></div>
<div className="flex ml-4">
<svg xmlns="http://www.w3.org/2000/svg" className="mt-1.5 mr-2" width="15" height="15" viewBox="0 0 15 15" fill="none">
<path d="M1 4C1 9.52267 5.47733 14 11 14H12.5C12.8978 14 13.2794 13.842 13.5607 13.5607C13.842 13.2794 14 12.8978 14 12.5V11.5853C14 11.2413 13.766 10.9413 13.432 10.858L10.4833 10.1207C10.19 10.0473 9.882 10.1573 9.70133 10.3987L9.05467 11.2607C8.86667 11.5113 8.542 11.622 8.248 11.514C7.15659 11.1128 6.16544 10.4791 5.34319 9.65681C4.52094 8.83457 3.88725 7.84341 3.486 6.752C3.378 6.458 3.48867 6.13333 3.73933 5.94533L4.60133 5.29867C4.84333 5.118 4.95267 4.80933 4.87933 4.51667L4.142 1.568C4.10143 1.4058 4.00781 1.2618 3.87604 1.15889C3.74426 1.05598 3.58187 1.00006 3.41467 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V4Z" stroke="#059669" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  <p>{ResumeDetails[project] && ResumeDetails[project]['candidate'] && ResumeDetails[project]['candidate']['phone_number']?ResumeDetails[project]['candidate']['phone_number']:"Phone No Not Provided"}</p>
  </div></p></div>
    <div className="ml-auto">
      <div className="flex">
    <div className="pl-8">
    <Button
      type="button"
      className="inline-flex w-[190px] justify-center gap-x-1.5 rounded-md bg-white px-3 border-green-500  text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      id="menu-button"
      aria-expanded={isOpen}
      aria-haspopup="true"
      onClick={()=>toggleDropdownPipeline(index)}
    >
      <p className="mt-2 mb-0 text-[16px] font-medium text-green-600">Save to pipeline</p>
      <svg
        className="-mr-2 size-6 ml-4 h-10 text-gray-400 border-l-[1px] border-green-600"
        viewBox="0 0 20 20"
        fill="green"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
          clipRule="evenodd"
        />
      </svg>
    </Button>
  
  

  
  {isOpen===index && (
    <div
      className="absolute left-34  z-10 !w-[190px] mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div class="p-1 space-y-0.5">
  <a class="flex items-center gap-x-3.5 border-b-2 border-gray-100 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Uncontacted
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3 border-b-2 border-gray-100  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Contacted
  </a>
  <a class="flex items-center gap-x-3.5 py-2 px-3  text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 no-underline" href="#">
    Replied
  </a>
  
</div>
    </div>
    )}
    </div>
    <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" className="inline-flex items-center p-1 h-3 ml-2   text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
        <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={20}
                height={20}
                className="ml-auto mt-[30px]"
                fill="black"
                aria-hidden="true"
                >
                <circle cx="5" cy="12" r="2" />
                <circle cx="12" cy="12" r="2" />
                <circle cx="19" cy="12" r="2" />
                </svg>
</button>
</div>

    </div>
  </div>
            </>
           }
           footer={
            <>
            <p className="mb-0 text-white">From Resumebox</p>
            </>
           }
          />
          )))}
        </div>
            </div>
            </div>
            </div>
            
        </>
    );
}
export default HomeSearchProfiles;
