// Basic imports
import React from 'react';
import Logo from '../components/Common/Logo';
import SignIn from '../components/SignIn/SignIn';

const Home = () => {
	return (
		<div className="radial-gradient-bg">
			<div className='ml-4 absolute'><Logo /></div>
			<div className="signup-page">
				<SignIn />
			</div>
		</div>
	);
};

export default Home;
