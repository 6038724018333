import React from 'react';


//Logo Component

const Logo= () => {
  return (
      <div className="font-sans">
        <h1 className="font-bold mb-0"> {process.env.REACT_APP_MAIN_TITLE}</h1>
        <p className='font-sans'>career forward</p>
        
      </div>
      );
};

export default Logo;


