import React, { useEffect,useContext } from "react";
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import CryptoJS from "crypto-js"; // For encryption
import './GmailSignIn.css';

const GoogleSignIn = ({ showNotification, setIsLoading }) => {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Use environment variable for security
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { setUserMetaData,setToken, setAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate()

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    };

    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: CLIENT_ID,
	callback: handleCredentialResponse,login_uri: "https://beta.eeezo.com/",
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signInButton"),
        { theme: "outline", size: "large" }
      );
    };

    const handleCredentialResponse = async (response) => {
      try {
        // console.log("Encoded JWT ID token:", response.credential);

        // Decode the JWT token to extract user info
        const decodedToken = jwtDecode(response.credential);
        // console.log("Decoded Token:", decodedToken);

        // Extract user details from the token
        const { name, email, picture } = decodedToken;

        // Prepare signIn data object
        const signInData = {
          email,
          googleAuth:true,
        };

        // Encrypt the signIn data
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(signInData),
          process.env.REACT_APP_SECRETKEY
        ).toString();

        const encryptjson = { data: encryptedData };
        console.log("Encrypted data to send:", encryptjson);

        // Send signIn request to backend
        setIsLoading(true);
        const signInResponse = await fetch(`${serverURL}/signIn`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(encryptjson),
        });

        const data = await signInResponse.json();
        console.log("SignIn Result:", data);

        if (!signInResponse.ok) {
          throw new Error(data.error || "SignIn failed");
        }

        // Success notification

        // const data = await signInResponse.json();
        // console.log(data,"data")
        if (data.token) {
         setToken(data.token) 
         localStorage.setItem('metadata',data.data);// JSON.stringify(data.data))
          localStorage.setItem('token',data.token)
          if(data.data){
              console.log("data.data")
              const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
              console.log(decryptedData,"dc");//.dbResponse);

          }
          //setUserMetaData(data);
          //setAuthenticated(true);
          navigate("/Dashboard");
        }else{
          var errorMessage = "Something Went Wrong";
          if(data.error)
            errorMessage = data.error;
            showNotification(errorMessage, "error");
      }
      } catch (error) {
        console.error("Error during Google signIn:", error.message);
        showNotification(error.message || "Google signIn failed", "error");
      } finally {
        setIsLoading(false); // Always stop loading
      }
    };

    loadGoogleScript();
  }, [CLIENT_ID, serverURL, showNotification, setIsLoading]);

  return (
    <div className="google-button flex" style={{ padding: '3px' }}>
      <div id="signInButton"></div>
    </div>
  );
};

export default GoogleSignIn;
