import React from 'react';
import { useState,useRef,useContext,useEffect } from 'react';
import ButtonSolid from '../elements/Button/ButtonSolid';
import ButtonHallow from '../elements/Button/ButtonHallow';
import './ProjectCreation.css';
import Button from '../elements/Button/Button';
import {validateEmail} from "../Validation/Validation"
import Notification from "../Notification/Notification";
import ClipLoader from "react-spinners/ClipLoader";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from 'crypto-js';

const ProjectCreation = ({ onClose }) => {
    const { token } = useContext(AuthContext);
    const [selectedSpecialties, setSelectedSpecialties] = useState([]);
    const [selectedCollaborators, setSelectedCollaborators] = useState([]);
    const [collabSuggestions, setcollabSuggestions] = useState([]);
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [isLoading, setIsLoading] = useState(false);
    const refs = {
    name: useRef(null),
    workspace: useRef(null),
    description: useRef(null),
    job_title: useRef(null),
    seniority_level: useRef(null),
    workplace_type: useRef(null),
    job_location: useRef(null),
    employment_type: useRef(null),
    skills: useRef(null),
    visibility: useRef(null),
    project_members: useRef(null)
  };

    // const refs = useRef({ "name": "projectName","workspace": "projectWorkspace","description": "projectDescription","job_title": "projectJobTitle","seniority_level": "projectSeniorityLevel","workplace_type": "projectWorkplaceType","job_location": "projectJobLocation","employment_type": "projectEmploymentType","skills": "projectSkills","visibility": "projectvisibilityisibility"});
    const [addProjectData, addProjectDataFun] = useState({ "name": "","workspace": "","description": "","job_title": "","seniority_level": "","workplace_type": "","job_location": "","employment_type": "","skills": [],"visibility": "","project_members": [],"company_id":"" });

    const showNotification = (message, type) => {   //For Notification
        setNotification({ message, type });
        setTimeout(() => {
          setNotification({ message: "", type: "" });
        }, 3000); // Auto-hide after 3 seconds
    };

    const addSkills = (skills1) => {
        if (!selectedSpecialties.includes(skills1)) {
            const updatedSpecialties = [...selectedSpecialties, skills1];
            setSelectedSpecialties(updatedSpecialties); 
            // setSelectedSpecialties([...selectedSpecialties, skills1]);
        }
    };

    const removeSkills = (skills1) => {
        setSelectedSpecialties(selectedSpecialties.filter(item => item !== skills1));
    };

    const suggestions = [
        "Figma",
        "CSS",
        "JAVA",
        "React",
        "HTML",
        "UX"
    ];

    const addCollab = async (skills, uid) => {
        console.log(uid)
            if (!selectedCollaborators.includes(uid)) {
                const updatedCollaborators = [...selectedCollaborators, Number(uid)];
                setSelectedCollaborators(updatedCollaborators); 
            }
    };

    const removeCollab = (skills) => {
        console.log(skills)
        setSelectedCollaborators(selectedCollaborators.filter(item => item !== skills));
    };

    useEffect(() => {
        loadProfile();
    }, []);

    const loadProfile = async () => {
      setIsLoading(true);
      try {
            const serverURL = process.env.REACT_APP_SERVER_URL;
            const response = await fetch(`${serverURL}/company/fetchCompanyUsers`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  token: token
                },
            });
            const data = await response.json();

            const decryptedData = JSON.parse(CryptoJS.AES.decrypt(data.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            console.log(decryptedData);
            if (!response.ok) {
                var errorMessage = "Something Went Wrong";
                if(data.error)
                    errorMessage = data.error;
                showNotification(errorMessage,"error");
                throw new Error(data.error);
            }
            else{
                console.log(decryptedData)
                const data = Array.isArray(decryptedData) ? decryptedData : [];
                var userdata = [];
                for(let i in data){
                    userdata[data[i]["uid"]] = data[i]['name'];
                }
                setcollabSuggestions(...collabSuggestions,userdata,);
                const encryptedMetadata = localStorage.getItem('metadata');
        if (encryptedMetadata){
            const decryptedMetadata = JSON.parse(CryptoJS.AES.decrypt(encryptedMetadata, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            if(decryptedMetadata.cid){
                addProjectDataFun({...addProjectData, ["company_id"]: decryptedMetadata.cid,});
            }
            else{
                showNotification(`Company ID Missing`, "error");
                return;
            }
        }
                console.log(userdata)
            }
        } catch (error) {
            console.log('Error:', error);
            let errorMessage = "Something went wrong.";
            if (error.response && error.response.data && error.response.data.msg) {
                errorMessage = error.response.data.msg;
            } 
            else if (error.message) {
                errorMessage = error.message;
            }
            showNotification(errorMessage, "error");
        } finally {
            setIsLoading(false); // Always hide loader after the request
        }
    }
    // const collabSuggestions = [
    //     "Ananya Bhat",
    //     "Jhon Doe 1",
    //     "Jhon Doe 3",
    //     "Michael",
    //     "Dravit"
    // ];

    useEffect(() => {
        addProjectDataFun({
            ...addProjectData,
            project_members: selectedCollaborators,
            skills: selectedSpecialties, // Use the state, not the setter
        });
    }, [selectedCollaborators, selectedSpecialties]);

    useEffect(() => {
        const fieldRef = refs['skills'];
        fieldRef.current.classList.remove("border-red-600");
    }, [selectedSpecialties]);

    useEffect(() => {
        const fieldRef = refs['project_members'];
        fieldRef.current.classList.remove("border-red-600");
    }, [selectedCollaborators]);

    const dataDetail = { "name": {"head":"Name"},"workspace": {"head":"Workspace"},"description": {"head":"Description"},"job_title": {"head":"Job Title"},"seniority_level": {"head":"Seniority Level"},"workplace_type": {"head":"Workplace Type"},"job_location": {"head":"Job Location"},"employment_type": {"head":"Employment Type"},"skills": {"head":"Skills"},"visibility": {"head":"Visibility"},"project_members":{"head":"collaborators"} }

    const validateName = (e) => {    //Name Validation only allow sting ,space and dot
      const input = e.target.value.replace(/[^a-zA-Z.\s]/g, '');
      const fieldRef = refs['name'];
      if(input)
      fieldRef.current.classList.remove("border-red-600");
      addProjectDataFun({...addProjectData, name: input,});
    };

    const validateValue = (e,field) => {    //Name Validation only allow sting ,space and dot
      const input = e.target.value
      console.log(input)
      console.log(field)
      const fieldRef = refs[field];
      if(input)
      fieldRef.current.classList.remove("border-red-600");
      if(field == "visibility"){
        addProjectDataFun({...addProjectData, [field]: Number(input),});
      }
      else
        addProjectDataFun({...addProjectData, [field]: input,});
    };

    const projectCreateFun = async () => {
        for(let key in addProjectData){
            if(key != "company_id"){
                if(!addProjectData[key] || addProjectData[key].length<1){
                    showNotification(`Enter the ${dataDetail[key]['head']}`, "error");
                    const fieldRef = refs[key]; // Access the ref dynamically
                    if (fieldRef && fieldRef.current) {
                      fieldRef.current.classList.add("border-red-600"); // Add the class
                    } 
                    return
                }
            }
        }
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(addProjectData), process.env.REACT_APP_SECRETKEY).toString();
        var postData={
          "data": encryptedData
        };
          let response;
        setIsLoading(true);
        
        try {
          response = await fetch(`${serverURL}/project/addProject`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              token:token
            },
            body: JSON.stringify(postData)
          });
          const data = await response.json();
            console.log(data,"data")
            if(!response.ok){
                var errorMessage = "Something Went Wrong";
                if(data.error)
                  errorMessage = data.error;
                  showNotification(errorMessage, "error");
            }
            else{
               showNotification("Project Added Successfully", "success");
               onClose();
             }

        } catch (error) {
          var errorMessage = "Something Went Wrong";
          if (error.response && error.response.data && error.response.data.msg)
              errorMessage = error.response.data.msg;
            showNotification(errorMessage, "error");
          console.error("Error sending request:", error);
          if (error.response && error.response.data && error.response.data.msg) {
            console.error("Error message:", error.response.data.msg);
          }
          throw error;
        }
        finally {
            setIsLoading(false); // Always hide loader after the request
        }
    }
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            {isLoading && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                      <div className="flex flex-col items-center">
                          <ClipLoader color="#3498db" loading={isLoading} size={50} />
                          <span className="text-white mt-4">Loading...</span>
                      </div>
                  </div>
              )}
              <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification({ message: "", type: "" })}
                />
            <div 
                className="bg-white rounded-[20px] shadow-[0px_4px_40px_0px_rgba(0,0,0,0.1)] w-3/4 max-w-5xl"
                style={{
                    background: '#FFFFFF',
                    maxHeight: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Modal Header */}
                <div className="flex justify-between items-center p-8 border-b border-gray-200">
                    <h2 className="text-2xl font-bold">Create a Project</h2>
                    <button 
                        onClick={onClose} 
                        className="text-gray-500 hover:text-gray-700"
                    >
                        ✕
                    </button>
                </div>

                {/* Modal Body */}
                <div className="modal-body-prcr flex-grow p-8 space-y-6">
                    {/* Project Details Section - Card 1 */}
                    <div className="modal-project-creation p-6 rounded-lg m-4 bg-gray-50">
                        <h3 className="text-xl font-semibold mb-4">Project Details</h3>
                        <div className="space-y-4">
                            <div className="flex flex-wrap gap-4">
                                <div className="flex-1">
                                    <p className="mb-2">Project Name</p>
                                    <input 
                                        type="text" 
                                        placeholder="Enter Project Name" 
                                        className="w-full p-3 border-1 border-gray-300 rounded-md focus:ring-2 focus:ring-cyan-500"
                                        onChange={validateName}
                                        value={addProjectData.name || ""}
                                        ref={refs.name}
                                    />
                                </div>
                                <div className="flex-1">
                                    <p className="mb-2">Workspace</p>
                                    <select ref={refs.workspace} onChange={(e) => validateValue(e, "workspace")}
                                        className="bg-white w-full p-3 border-[1px] border-gray-300 rounded-md focus:ring-2 focus:ring-cyan-500"
                                    >
                                        <option value="">Select Workspace</option>
                                        <option value="workspace1">Workspace 1</option>
                                        <option value="workspace2">Workspace 2</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <p className="mb-2">Project Description</p>
                                <textarea ref={refs.description} onChange={(e) => validateValue(e, "description")}
                                    placeholder="Enter Project Description" 
                                    className="w-full p-3 border-[1px] border-gray-300 rounded-md focus:ring-2 focus:ring-cyan-500"
                                    rows="4"
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    {/* Repeat similar cards for additional sections */}
                    <div className="modal-project-creation p-6 rounded-lg m-4 bg-gray-50">
                        <h3 className="text-xl font-semibold mb-4">Candidate Criteria</h3>
                        <div className="space-y-4">
                            <div className="flex flex-wrap gap-4">
                                <div className="flex-1">
                                    <p className="mb-2">Job Title</p>
                                    <input ref={refs.job_title} onChange={(e) => validateValue(e, "job_title")}
                                        type="text" 
                                        placeholder="Enter Job Title" 
                                        className="w-full p-3 border-[1px] border-gray-300 rounded-md focus:ring-2 focus:ring-cyan-500"
                                    />
                                </div>
                                <div className="flex-1">
                                    <p className="mb-2">Seniority Level</p>
                                    <select ref={refs.seniority_level} onChange={(e) => validateValue(e, "seniority_level")}
                                        className="bg-white w-full p-3 border-[1px] border-gray-300 rounded-md focus:ring-2 focus:ring-cyan-500"
                                    >
                                        <option value="">Seniority Level</option>
                                        <option value="workspace1">Seniority Level 1</option>
                                        <option value="workspace2">Seniority Level 2</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex flex-wrap gap-4">
                                <div className="flex-1">
                                    <p className="mb-2">Workplace Type</p>
                                    <select ref={refs.workplace_type} onChange={(e) => validateValue(e, "workplace_type")}
                                        className="bg-white w-full p-3 border-[1px] border-gray-300 rounded-md focus:ring-2 focus:ring-cyan-500"
                                    >
                                        <option value="">Workplace Type</option>
                                        <option value="workspace1">Workplace Type 1</option>
                                        <option value="workspace2">Workplace Type 2</option>
                                    </select>
                                </div>
                                <div className="flex-1">
                                    <p className="mb-2">Job Location</p>
                                    <input ref={refs.job_location} onChange={(e) => validateValue(e, "job_location")}
                                        type="text" 
                                        placeholder="Enter Job Location" 
                                        className="w-full p-3 border-[1px] border-gray-300 rounded-md focus:ring-2 focus:ring-cyan-500"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap gap-4">
                                <div className="flex-1">
                                    <p className="mb-2">Employement Type</p>
                                    <select ref={refs.employment_type} onChange={(e) => validateValue(e, "employment_type")}
                                        className="bg-white w-full p-3 border-[1px] border-gray-300 rounded-md focus:ring-2 focus:ring-cyan-500"
                                    >
                                        <option value="">Employement Type</option>
                                        <option value="workspace1">Employement Type 1</option>
                                        <option value="workspace2">Employement Type 2</option>
                                    </select>
                                </div>
                                <div className='flex-1'></div>
                            </div>
                        </div>
                    </div>
                    {/* Skills */}
                    <div className="modal-project-creation p-6 rounded-lg m-4 bg-gray-50">
                        <h3 className="text-xl font-semibold mb-4">Skills</h3>
                        <div className="space-y-4">
                        <div>
                            {/* Label for the specialties section */}
                            <p className="mb-0 mt-4 text-md">
                                Choose Skills
                            </p>

                            {/* Selected specialties list */}
                            <div ref={refs.skills} className="border-[1px] border-gray-200 w-full p-3 rounded-lg flex flex-wrap gap-4">
                                {selectedSpecialties.map((skills, index) => (
                                    <span
                                        key={index}
                                        className="flex items-center border border-gray-200 bg-teal-50 text-black px-3 py-1 rounded-lg"
                                    >
                                        {skills}
                                        <Button
                                            onClick={() => removeSkills(skills)}
                                            className="ml-2 text-black bg-teal-50 border-none font-bold hover:text-red-500"
                                        >
                                            ×
                                        </Button>
                                    </span>
                                ))}
                            </div>

                            {/* Suggestions section */}
                            <div className="pt-2 w-full">
                                <p className="text-gray-500 text-sm mb-2 mt-2">Suggestions:</p>
                                <div className="flex flex-wrap gap-1">
                                    {suggestions.map((suggestion, index) => (
                                        <Button
                                            key={index}
                                            onClick={() => addSkills(suggestion)}
                                            className={`px-3 py-2 mt-2 border-2 rounded-lg text-xs ${
                                                selectedSpecialties.includes(suggestion)
                                                    ? 'border-teal-300 bg-teal-100 text-teal-700'
                                                    : 'border-teal-100 bg-white text-gray-700'
                                            } hover:border-teal-400`}
                                        >
                                            {suggestion}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </div>

                        </div>
                    </div>
                    {/* Project Visibility */}

                    <div className="modal-project-creation p-6 rounded-lg m-4 bg-gray-50">
                        <h3 className="text-xl font-semibold mb-4">Project Visibility</h3>
                        <div className="space-y-4">
                        <div className="flex flex-col gap-2">
                            <label className="flex items-center gap-3">
                                <input ref={refs.visibility} checked={addProjectData.visibility === 1} value={1} onChange={(e) => validateValue(e, "visibility")}
                                    type="radio"
                                    name="visibility"
                                    className="hidden peer"
                                />
                                <span className="w-5 h-5 flex items-center justify-center border-2 border-gray-300 rounded-full peer-checked:bg-[#059669] peer-checked:border-[#059669]"></span>
                                <span className="text-sm font-medium">Private</span>
                            </label>
                            <label className="flex items-center gap-3">
                                <input ref={refs.visibility} checked={addProjectData.visibility === 2} value={2} onChange={(e) => validateValue(e, "visibility")}
                                    type="radio"
                                    name="visibility"
                                    className="hidden peer"
                                />
                                <span className="w-5 h-5 flex items-center justify-center border-2 border-gray-300 rounded-full peer-checked:bg-[#059669] peer-checked:border-[#059669]"></span>
                                <span className="text-sm font-medium">Public</span>
                            </label>
                        </div>

                        <div>
                            {/* Label for the specialties section */}
                            <p className="mb-0 mt-4 text-md">
                                Add Collaborators
                            </p>

                            {/* Selected specialties list */}
                            <div ref={refs.project_members} className="border-[1px] border-gray-200 w-full p-3 rounded-lg flex flex-wrap gap-4">
                                {selectedCollaborators.map((collabs, index) => (
                                    <span
                                        key={index}
                                        className="flex items-center border border-gray-200 bg-teal-50 text-black px-3 py-1 rounded-lg"
                                    >
                                        {collabSuggestions[collabs]}
                                        <Button
                                            onClick={() => removeCollab(collabs,index)}
                                            className="ml-2 text-black bg-teal-50 border-none font-bold hover:text-red-500"
                                        >
                                            ×
                                        </Button>
                                    </span>
                                ))}
                            </div>

                            {/* Suggestions section */}
                            <div className="pt-2 w-full">
                                <p className="text-gray-500 text-sm mb-2 mt-2">Suggestions:</p>
                                <div className="flex flex-wrap gap-1">
                                        {collabSuggestions && Object.keys(collabSuggestions).length > 0 &&
                                        Object.entries(collabSuggestions).map(([index, suggestion]) => (               
                                        <Button
                                            key={index}
                                            onClick={() => addCollab(suggestion,index)}
                                            className={`px-3 py-2 mt-2 border-2 rounded-lg text-xs ${
                                                selectedCollaborators.includes(Number(index))
                                                    ? 'border-teal-300 bg-teal-100 text-teal-700'
                                                    : 'border-teal-100 bg-white text-gray-700'
                                            } hover:border-teal-400`}
                                        >
                                            {suggestion}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </div>

                        </div>
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="modal-footer-prcr flex justify-end">
                    <ButtonHallow 
                        content="Cancel"
                        className="mx-4 bg-gray-200 px-6 py-3 rounded-lg mr-4 hover:bg-gray-300"
                        style={{ maxWidth: '120px' }}
                        id="button"
                        type="Button"
                        onClick={onClose}
                    />
                    <ButtonSolid
                        content="Save"
                        className="text-white bg-cyan-500 px-6 py-3 rounded-lg hover:bg-cyan-600"
                        style={{ maxWidth: '120px' }}
                        id="submit-button"
                        type="Submit"
                        onClick={projectCreateFun}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProjectCreation;
