import React from "react";
import Applicant from "../../components/Applicants/Applicants";
import Topbar from "../../components/Recruiter/Topbar/Topbar";
const JobApplicant = () =>{
    return(
        <>
            <div className="radial-gradient-bg">
                <Topbar/>
                <div className="w-full">
                <Applicant/>
                </div>
            </div>
        </>
    )
}
export default JobApplicant