import React from "react";
const List = (props) =>{
    return(
        <>
            <ul className={`${props.ulClass}`}>
                {props.lists}
            </ul>
        </>
    );
}
export default List