const UploadImageTemp = async (event) => {   //Upload Image Fun
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", signUpImage);
        // console.log(formData.get("file"));
        try {
            const serverURL = process.env.REACT_APP_SERVER_URL;
            const response = await fetch(`${serverURL}/signUpImageTemp`, {
                method: 'POST',
                body: formData, // Automatically sets the correct Content-Type
            });
            if (!response.ok) {
                throw new Error('Failed to upload file');
            }

            const result = await response.json();
            console.log("Server Response:", result);
            signUpImageFun(result.files.file[0]['newFilename'])
            signUpImageNameFun(result.files.file[0]['originalFilename'])
            // console.log(result.files.file[0]['newFilename'])
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };
    export default UploadImageTemp;