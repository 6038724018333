import React from "react";
import JobDashboard from "../../components/JobPost/JobDashboard";
import Topbar from "../../components/Recruiter/Topbar/Topbar";
const NewJobPost = () =>{
    return(
        <div className="radial-gradient-bg">
            <Topbar/>
            <JobDashboard/>
        </div>
    )
}
export default NewJobPost;