import React, { useContext, useState } from "react";
import { useCommonContext } from "../../../context/CommonContext";
import ai from '../../../assets/AI.png';
import search from '../../../assets/search.png';
import micIcon from '../../../assets/microphone.png'; // Replace with the actual path to the mic icon
import uploadIcon from '../../../assets/file.png'; // Replace with the actual path to the upload icon
import './SearchInput.css';
import arrow from '../../../assets/arrow.png';

const SearchInput = () => {
    const { searchTerm, setSearchTerm, setIsAIsearch, showUpload, setShowUpload } = useCommonContext();
    const [searchOption, setSearchOption] = useState("Eeezo AI");
    const [iconType, setIconType] = useState(ai);
    const [showOption, setShowOption] = useState(false);

    const changesearchterm = (value) => {
        setSearchTerm(value);
    };

    const changesearchtype = (value) => {
        if (value === 'eeezo_ai') {
            setSearchOption("Eeezo AI");
            setIconType(ai);
            setIsAIsearch(true);
        } else {
            setSearchOption("Search");
            setIconType(search);
            setIsAIsearch(false);
        }
        setShowOption(!showOption);
    };

    const toggleUploadView = () => {
        setShowUpload(true); // Show upload div
    };
    var [isUploaded, setIsUploaded] = useState(false);
    var [resumeFiles, setResumeFiles] = useState([]);

    // for change the page
    var handleUploadClick = () => {
        setIsUploaded(true);
    };

    // for handle drag and drop
    var handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    var handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            setResumeFiles(Array.from(droppedFiles));
        }
    };

    // save the file using state
    var handleFileInputChange = (e) => {
        var selectedFiles = e.target.files;
        if (selectedFiles.length > 0) {
            setResumeFiles(Array.from(selectedFiles));
        }
    };

    // trigger the input via click the div
    var handleDivClick = () => {
        document.getElementById("fileInput").click();
    };

    // save resume api calling
    var saveResume = () => {
        console.log(resumeFiles,"resumeFiles");
    };

    return (
        <>
            {!showUpload ? (
                <>
                    <div className="search-box"
                        style={{
                            maxWidth: '200px',
                            maxHeight: '75%',
                            height: '75%',
                            borderRight: '0',
                            borderRadius: '12px 0px 0px 12px',
                            backgroundColor: '#06583D',
                            padding: '15px'
                        }}>
                        <div className="dropdown-choose grid grid-cols-3"
                            style={{
                                backgroundColor: '#059669',
                                boxShadow: '0px 0.26px 11.61px 0px #059669 inset, 0px 1.05px 11.97px 0px #34D39933',
                                height: '100%',
                                width: '100%',
                                borderRadius: '5px'
                            }}>
                            <div style={{
                                textAlign: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                <img src={iconType} style={{ maxWidth: '22px' }} />
                            </div>
                            <div style={{
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                textAlign: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontWeight: '700'
                            }}>
                                {searchOption}
                            </div>
                            <div style={{ padding: '10px 15px' }}>
                                <div className="arrow"
                                    onClick={() => setShowOption(!showOption)}
                                    style={{
                                        borderRadius: '5px',
                                        width: '100%',
                                        height: '100%',
                                        background: 'linear-gradient(144.59deg, #FFFFFF -95.49%, rgba(5, 150, 105, 0) 139.15%)',
                                        position: 'relative',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}>
                                    <img src={arrow} style={{ maxWidth: '60%', height: 'auto', position: 'absolute' }} />
                                </div>
                            </div>
                        </div>
                        {showOption && <div className={`drop-down grid`} style={{ backgroundColor: '#059669', borderRadius: '0px 0px 10px 10px' }}>
                            <span className="option-search-type" onClick={() => changesearchtype("eeezo_ai")} value="eeezo_ai">Eeezo AI</span>
                            <span className="option-search-type" onClick={() => changesearchtype("search")} value="search">Search</span>
                        </div>}
                    </div>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', height: '75%', maxHeight: '75%' }}>
                        <input
                            type="text"
                            aria-label="Search"
                            className="search-box"
                            placeholder="Search"
                            value={searchTerm}
                            style={{
                                flex: 1,
                                paddingRight: '75px', // Leave space for icons
                                borderLeft: '0',
                                borderRadius: '0px 12px 12px 0px',
                                height: '100%'
                            }}
                            onChange={(e) => changesearchterm(e.target.value)}
                        />
                        <div className="mic-icon-div">
                            <img className="icon-search" src={micIcon} alt="Mic Icon" />
                        </div>
                        <div className="upload-icon-div" onClick={toggleUploadView}>
                            <img className="icon-search" src={uploadIcon} alt="Upload Icon" />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div 
                        onClick={handleDivClick}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop} 
                        className="cursor-pointer h-[200%] mt-[10%] border-2 border-dashed border-[#059669]  rounded-lg flex items-center justify-center flex-col"
                        style={{width:'65vw',backgroundColor:'white'}}
                    >
                        <svg
                            width="79"
                            height="79"
                            viewBox="0 0 79 79"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1599_7728)">
                                <path
                                    d="M46.0039 19.5781H52.5039V26.0781H68.7539C69.6159 26.0781 70.4425 26.4205 71.052 27.03C71.6615 27.6395 72.0039 28.4662 72.0039 29.3281V53.7031L52.5039 42.3281L52.6209 68.5296L59.8456 61.5421L65.6371 71.5781H29.7539C28.8919 71.5781 28.0653 71.2357 27.4558 70.6262C26.8463 70.0167 26.5039 69.1901 26.5039 68.3281V52.0781H20.0039V45.5781H26.5039V29.3281C26.5039 28.4662 26.8463 27.6395 27.4558 27.03C28.0653 26.4205 28.8919 26.0781 29.7539 26.0781H46.0039V19.5781ZM72.0039 56.4266V68.3281C72.0042 68.6668 71.9516 69.0034 71.8479 69.3259L65.4779 58.2954L72.0039 56.4266ZM13.5039 45.5781V52.0781H7.00391V45.5781H13.5039ZM13.5039 32.5781V39.0781H7.00391V32.5781H13.5039ZM13.5039 19.5781V26.0781H7.00391V19.5781H13.5039ZM13.5039 6.57812V13.0781H7.00391V6.57812H13.5039ZM26.5039 6.57812V13.0781H20.0039V6.57812H26.5039ZM39.5039 6.57812V13.0781H33.0039V6.57812H39.5039ZM52.5039 6.57812V13.0781H46.0039V6.57812H52.5039Z"
                                    fill="#059669"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1599_7728">
                                    <rect
                                        width="78"
                                        height="78"
                                        fill="white"
                                        transform="translate(0.503906 0.078125)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <p className="plusJakartaSansFont m-0 text-xl font-bold text-[#3D3D3D]">
                            Drag and Drop or choose files
                        </p>
                        <input
                            type="file"
                            id="fileInput"
                            onChange={handleFileInputChange}
                            className="hidden"
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default SearchInput;
