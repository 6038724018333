import React, { useState, useContext ,useEffect, useRef } from "react";
import axios from "axios";
import Topbar from "../../components/Recruiter/Topbar/Topbar";
import "./JobCreation.css";
import profileImg from "../../assets/user.png"; // Replace with your profile image path
import sendIcon from "../../assets/send.png"; // Replace with your send icon path
import Logo from "../../components/Common/Logo";
import boy from "../../assets/boy.png"
import AIJobPost from "../../components/JobPost/AiJobPost"
import ModalBox from "../../components/elements/ModalBox/ModalBox";
import { useCommonContext } from "../../context/CommonContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import CryptoJS from 'crypto-js';
import ButtonSolid from "../../components/elements/Button/ButtonSolid";
import ButtonHallow from "../../components/elements/Button/ButtonHallow";

const JobCreation = () => {
    const inputRef = useRef(null);
    const customRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Automatically focus the input field
        }
    },[]);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const { setJobCreationData, jobCreationData } = useCommonContext();
    const [messages, setMessages] = useState([
        { type: "bot", text: "Please enter the role family name of the Job (Eg: Software Engineering)" },
    ]);
    const [currentInput, setCurrentInput] = useState("");
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [options, setOptions] = useState(null);
    const [isFirstFetch, setIsFirstFetch] = useState(true);
    const [qnstatus, setQnStatus] = useState("");

    const [customFieldShow, setCustomFieldShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [jdID, setjdID] = useState("");
    const [selectedIndices, setSelectedIndices] = useState([]); // Track selected indices
    const [selectedValues, setSelectedValues] = useState([]);   // Track selected values
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [isCompleted, setIsCompleted] = useState(false);
    useEffect(() => {
        if (customFieldShow && customRef.current) {
            customRef.current.focus();
        }
    }, [customFieldShow])


    const selectedoption = (idx) => {
        console.log(idx)
        if (selectedIndices.includes(idx)) {
            // Deselect option
            setSelectedIndices((prev) => prev.filter((index) => index !== idx));
            setSelectedValues((prev) => prev.filter((_, i) => i !== idx));
        } else {
            // Select option
            setSelectedIndices((prev) => [...prev, idx]);
            setSelectedValues((prev) => [...prev, options[idx]]);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            if (qnstatus === "array" && selectedValues.length > 0) {
                handleOptionClick(selectedValues); // Call handleOptionClick with the array
                setSelectedIndices([]); // Reset selections
                setSelectedValues([]);
            }
        }
    };


    const fetchData = async (answer) => {
        setIsLoading(true);
        const JDDATA = {answer: answer};
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/aiChatBot`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  token: token
                },
                body: JSON.stringify(JDDATA),
            });
            const firstdata = await response.json();
            const JdData = JSON.parse(CryptoJS.AES.decrypt(firstdata.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            const data = JdData.next_prompt;
            setCurrentQuestion(data.question);
            setOptions(data.options);
            setQnStatus(data.expected_next_data_type);
            setjdID(JdData.jd_id);
            setMessages((prev) => [
                ...prev,
                { type: "bot", text: data.question },
            ]);
            // const status = response;
            // console.log(status);
        }  catch (error) {
            console.error("Failed to fetch the next question:", error);
        } finally {
            setIsLoading(false);
        }
    }    

    
    const addJD = async (JD_ID,JDTitle, JD) => {
        const JDDATA = {title: JDTitle,jd_id: JD_ID,jd: JD};
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(JDDATA), process.env.REACT_APP_SECRETKEY).toString();
        var encryptjson = { data:encryptedData}
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/JD/addJD`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  token: token
                },
                body: JSON.stringify(encryptjson),
            });
            const status = response;
            console.log(status);
        } catch(error){
            console.log("Error adding JD to db : ",error);
        }
    }    

    const fetchNextQuestion = async (answer) => {
        setIsLoading(true);
        qnstatus === 'array' ? [answer] : answer
        setSelectedIndices([]);
        setSelectedValues([]);
        const JDDATA = {answer: answer,jd_id: jdID};
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/aiChatNextQn`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  token: token
                },
                body: JSON.stringify(JDDATA),
            });
            const firstdata = await response.json();
            const JdData = JSON.parse(CryptoJS.AES.decrypt(firstdata.data, process.env.REACT_APP_SECRETKEY).toString(CryptoJS.enc.Utf8));
            const data = JdData.next_prompt;
            if(JdData.status != "COMPLETED"){
                setCurrentQuestion(data.question);
                setOptions(data.options);
                setQnStatus(data.expected_next_data_type);
                setMessages((prev) => [
                    ...prev,
                    { type: "bot", text: data.question },
                ]);
            }else {
                const jd = JdData.generated_jd;
                const jdHtml = `
                    <h3>Job Title:</h3>
                    <p>  ${(() => {
                        const match = jd.match(/(?:Title|Title:)\s*(.+)/i);
                        return match ? match[1].split("\n")[0].trim().replace(": ", "") : "Title not found";
                      })()}</p>
                    <h3>Responsibilities:</h3>
                    <ul>
                        ${jd
                            .split("\n\n")[1]
                            .split("\n- ")
                            .slice(1)
                            .map((item) => `<li>${item}</li>`)
                            .join("")}
                    </ul>
                    <h3>Skills:</h3>
                    <ul>
                        ${jd
                            .split("\n\n")[2]
                            .split("\n- ")
                            .slice(1)
                            .map((item) => `<li>${item}</li>`)
                            .join("")}
                    </ul>
                    <h3>Qualifications:</h3>
                    <ul>
                        ${jd
                            .split("\n\n")[3]
                            .split("\n- ")
                            .slice(1)
                            .map((item) => `<li>${item}</li>`)
                            .join("")}
                    </ul>
                `;
                const jdtitle = `${(() => {
                    const match = jd.match(/(?:Title|Title:)\s*(.+)/i);
                    return match ? match[1].split("\n")[0].trim().replace(": ", "") : "Title not found";
                  })()}`;
                addJD(jdID,`${jdtitle}`,jd);
                // setJobTitle(`${jdtitle}`);
                // setJobDescription(jd);
                setJobCreationData((prev) => ({
                    ...prev,
                    jobTitle: `${jdtitle}`,
                    description: jd
                  }));

                setModalContent(jdHtml);
                // setModalContent(response.data.generated_jd); 
                setIsModalVisible(true);
            }
        } catch (error) {
            console.error("Failed to fetch the next question:", error);
        } finally {
            setIsLoading(false);
            // console.log(qnstatus)
        }
    };
    const modalHTMLcontent = (<div dangerouslySetInnerHTML={{ __html: modalContent }} ></div>)
    
    const handleOptionClick = async(option) => {
        if(option) {
            setMessages((prev) => [
                ...prev,
                { type: "user", text: option },
            ]);
            setCurrentInput(""); // Clear input field
            if(isFirstFetch){
                await fetchData(option);
                setIsFirstFetch(false);
            } else {
                await fetchNextQuestion(option);
            }
        }
    }
    const handleSend = async () => {
        if (currentInput.trim() || selectedValues) {
            const userResponse = currentInput || selectedValues;
            // setMessages((prev) => [
            //     ...prev,
            //     { type: "user", text: userResponse },
            // ]);
            setMessages((prev) => [
                ...prev,
                {
                    type: "user",
                    text: userResponse
                },
            ]);
            setCurrentInput(""); // Clear input field
            if(isFirstFetch){
                await fetchData(userResponse);
                setIsFirstFetch(false);
            } else {
                await fetchNextQuestion(userResponse);
            }
        }
    };
    const Modal_on_Close = () => {
        // navigate('/NewJobPost')
        setIsModalVisible(false);
        setIsCompleted(true);
    }
    const navigateNext = () => {
        localStorage.setItem("jobCreationData", JSON.stringify(jobCreationData));
        setTimeout(() => {
            navigate('/NewJobPost');
        }, 0);
    };
    

    return (
        <div className="radial-gradient-bg" onKeyDown={handleKeyPress}>
        {isModalVisible && 
        <ModalBox cardClass="no-max-width" Modal_Body={modalHTMLcontent} Modal_Close_Func={Modal_on_Close} modal_Header_Class="" />}
            <Topbar />
            <section className={`JobCreation-body flex px-8 py-8 mt-8 mr-8`}>
                <div className={`JobID-left w-3/12`}>
                    <AIJobPost />
                </div>
                <div className={`JobCreationQuestions w-9/12 bg-white p-6 rounded-lg relative`}>
                    <div
                        className="chat-window overflow-y-auto"
                        style={{ maxHeight: "calc(100vh - 120px)" }}
                    >
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                className={`chat-message ${
                                    message.type === "bot" ? "bot-message" : "user-message"
                                }`}
                            >
                                {message.type === "bot" && (
                                    <div className="profile-circle flex-none w-14" style={{width: '5% !important'}}>
                                        <img
                                            src={profileImg}
                                            alt="Profile"
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    </div>
                                )}
                                <div className="flex-1 ...">
                                    {/* <div className="message-text">{message.text}</div> */}
                                    <div className={`message-text ${(Array.isArray(message.text)) ? 'flex justify-end': ''}`} style={{textWrap:(Array.isArray(message.text))? 'wrap':  'nowrap'}}>
                                        {Array.isArray(message.text) ? (
                                            <div className="options-container-send">
                                                {message.text.map((value, idx) => (
                                                <div key={idx}  className={`option-item-send`} > 
                                                    {value}
                                                </div>
                                                ))}
                                            </div>
                                        ) : (
                                            message.text
                                        )}
                                    </div>
                                    {message.type === "bot" && options && index === messages.length - 1 && (
                                        <div className="options-container">
                                            {options.map((option, idx) => (
                                                <div
                                                    key={idx}
                                                    className={`option-item ${selectedIndices.includes(idx) ? "active" : ""}`}
                                                    onClick={() => {
                                                        // setOptions(option);
                                                        // setCurrentInput(option)
                                                       (qnstatus === 'array') ? selectedoption(idx) : handleOptionClick(option);
                                                    }}
                                                > 
                                                    {option}
                                                </div>
                                            ))}
                                            {!customFieldShow && <div className="option-item">
                                                <span onClick={(e) => setCustomFieldShow(!customFieldShow)} style={{ cursor: "pointer" }}>
                                                    +  Add a Custom Field
                                                </span>
                                            </div>}
                                            {customFieldShow && (
                                                <div className="option-item custom-option-input">
                                                    <input
                                                        ref={customRef}
                                                        type="text"
                                                        style={{color:'black'}}
                                                        placeholder="Enter your custom option"
                                                        className="text-input p-2 rounded-lg border border-gray-300 shadow-custom"
                                                        // value={currentInput}
                                                        // onChange={(e) => setCurrentInput(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' && e.target.value.trim() !== '') {
                                                                // Add the custom input to the options list
                                                                const newOptions = [...options, e.target.value];
                                                                setOptions(newOptions); // Update the options array
                                                                setCurrentInput(''); // Clear the input field
                                                                setCustomFieldShow(!customFieldShow); // Optionally close the custom input field
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>  
                                    )} 
                                </div>
                                {message.type === "user" && (
                                    <div className="profile-circle user-profile">
                                        <img
                                            src={boy}
                                            alt="User Profile"
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                        {isLoading && (
                        <div className="chat-message bot-message">
                            <div className="profile-circle flex-none w-14">
                                <img
                                    src={profileImg}
                                    alt="Profile"
                                    style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                    }}
                                />
                            </div>
                            <div className="flex-1">
                                <div className="message-text text-gray-500">Eeezo is typing...</div>
                            </div>
                        </div>
                    )}
                    </div>

                    {/* Fixed Input Area */}
                    <div className="input-area fixed bottom-0 left-0 w-full p-4 bg-white ">
                        {isCompleted ? 
                        <div className="flex ml-auto" style={{justifyContent:'flex-end',alignItems:'flex-end'}}>
                            <ButtonHallow 
                            className="button-cancel"
                            content="Cancel"
                            style={{padding : '10px 30px',marginRight:'10px'}}
                            onClick={() => navigate('/JobCreation')}/>
                            <ButtonSolid 
                            className="button-next"
                            content="Proceed"
                            style={{padding : '10px 30px',marginRight:'10px'}}
                            onClick={() => navigateNext()}/>
                        </div>
                        : 
                        <div className="input-container relative flex items-center">
                            <input
                                ref={inputRef}
                                type="text"
                                className="text-input flex-grow p-2 rounded-lg border border-gray-300 shadow-custom pr-10"
                                placeholder="Message Eeezo"
                                value={currentInput}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSend(); 
                                    }
                                }}
                                readOnly={options} 
                                onChange={(e) => setCurrentInput(e.target.value)}
                            />
                            <button
                                className="send-icon absolute right-3 top-1/2 transform -translate-y-1/2 bg-transparent border-none"
                                onClick={handleSend}
                                style={{ cursor: "pointer" }}
                            >
                                <img src={sendIcon} alt="Send" style={{ width: "20px", cursor: "pointer" }} />
                            </button>
                        </div>}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default JobCreation;
