import React from "react";
import AddMember from "../../components/Team/UserSettings";
import Topbar from "../../components/Recruiter/Topbar/Topbar";
const AddTeam=()=>{
    return (
		<div className="radial-gradient-bg">
			<Topbar />
			<div className="">
				<AddMember />
			</div>
		</div>
	);
}
export default AddTeam