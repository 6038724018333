import React from "react";
const Toggle = (props) =>{
    return(
        <label className={`inline-flex  cursor-pointer ${props.label}`}>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{props.spanbefore}</span>
            <input type="checkbox" value="" checked={props.toggled} onChange={props.onChange} className={`sr-only peer ${props.inputclass}`}/>
            <div className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600 ${props.className}`}></div>
        </label>
    )
}
export default Toggle