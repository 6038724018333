// Basic imports
import React from 'react';

import Topbar from '../../components/Recruiter/Topbar/Topbar';
import Profile_Content from '../../components/Recruiter/Profile/Profile';

const Profile = () => {
	return (
		<div className="radial-gradient-bg">
				<Topbar />
				<Profile_Content />
		</div>
	);
};

export default Profile;
